/********************************************************************************
 *  Copyright 2021, Pierre-Alain Etique                                         *
 *                                                                              *
 *  This file is part of Coloriƨation.                                          *
 *                                                                              *
 *  Coloriƨation is free software: you can redistribute it and/or modify        *
 *  it under the terms of the GNU General Public License as published by        *
 *  the Free Software Foundation, either version 3 of the License, or           *
 *  (at your option) any later version.                                         *
 *                                                                              *
 *  Coloriƨation is distributed in the hope that it will be useful,             *
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of              *
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the               *
 *  GNU General Public License for more details.                                *
 *                                                                              *
 *  You should have received a copy of the GNU General Public License           *
 *  along with Coloriƨation.  If not, see <https://www.gnu.org/licenses/>.      *
 *                                                                              *
 ********************************************************************************/

import PhonInW from "./PhonInW";
import PhonWord from "./PhonWord";

/**
 * Cherche si les phonèmes de pw peuvent être trouvés grâce aux
 * exceptions. Si oui, complète pw et retourne true, sinon
 * retourne false
 * @param pw Le PhonWord à analyser et à compléter avec ses phonèmes.
 * @param _conf La Config à utiliser au cours de cette analyse.
 * @returns true: le mot a été trouvé dans les exceptions, les phonèmes ont été ajoutés à
 * pw. false: le mot n'est pas dans la liste. pw est inchangé.
 */
export default function FindExceptPhons(pw: PhonWord) {
  let toReturn = false;
  let sons: string | undefined = except.get(pw.lowWord);
  if (sons !== undefined) {
    toReturn = true;
    let l = 0; // indice dans le mot
    let i = 0; // indice dans son.
    while (i < sons.length) {
      let j = i;
      while (j < sons.length && sons[j] !== "-" && sons[j] !== ";") {
        j++;
      }
      let len = j - i; // j doit être > i, donc len > 0;
      let lettres = sons.substring(i, i + len);
      lettres = lettres.trim();
      len = lettres.length;
      let son: string;
      if (j < sons.length && sons[j] === "-") {
        i = j + 1;
        while (j < sons.length && sons[j] !== ";") {
          j++;
        }
        let strSon = sons.substring(i, j);
        strSon = strSon.trim();
        son = strSon[0]; // par définition un seul charactère.
        PhonInW.CreateAndPushPiWColSE(pw, l, l + len - 1, son, "exception");
        l += len;
      } else {
        for (let k = 0; k < len; k++) {
          son = lettres[k];
          PhonInW.CreateAndPushPiWColSE(pw, l, l, son, "exception");
          l++;
        }
      }
      i = j + 1;
    }
  }
  return toReturn;
}

// les mots suivants proviennent de la base de données Morphalou.
// Je me demande s'ils existent vraiment tous dans notre belle langue...
const except = new Map([
  ["abies", "a-a;b-b;i-j;e-E;s-s"],
  ["abigaïl", "a-a;b-b;i-i;g-g;a-a;ï-j;l-l"],
  ["abigaïls", "a-a;b-b;i-i;g-g;a-a;ï-j;l-l;s-#"],
  ["abrasax", "ab;r-R;asax"],
  ["acanthophœnix", "a;c-k;an-@;t;h-#;o;ph-f;œ-e;ni;x"],
  ["trachélobranches", "t;r-R;a;ch-k;é-e;lob;r-R;an-@;ch-S;e-°;s-#"],
  ["trachélobranche", "t;r-R;a;ch-k;é-e;lob;r-R;an-@;ch-S;e-°"],
  ["schnorchel", "sch-S;no;r-R;ch-k;e-E;l"],
  ["schnorchels", "sch-S;no;r-R;ch-k;e-E;l;s-#"],
  ["cholédochoclyse", "ch-k;ol;é-e;do;ch-S;o;c-k;l;y-i;s-z;e-°"],
  ["cholédochoclyses", "ch-k;ol;é-e;do;ch-S;o;c-k;l;y-i;s-z;e-°;s-#"],
  ["chénomychon", "cg-k;é-e;nom;y-i;ch-S;on-§"],
  ["chénomychons", "cg-k;é-e;nom;y-i;ch-S;on-§;s-#"],
  ["accelerando", "a;cc-x;e-e;l;e-e;r-R;an-@;do"],
  ["ace", "a-E;c-s;e-ç"],
  ["aces", "a-E;c-s;e-ç;s-#"],
  ["actaea", "a-a;c-k;t-t;ae-e;a-a"],
  ["actaeas", "a-a;c-k;t-t;ae-e;a-a;s-#"],
  ["agnus", "a-a;gn-N;u-u;s-s"],
  ["aérosol", "a-a;é-e;r-R;o-o;s-s;o-o;l-l"],
  ["aérosols", "a-a;é-e;r-R;o-o;s-s;o-o;l-l;s-#"],
  ["clef", "c-k;l;ef-e"], // évite un règle pour un seul mot
  ["clefs", "c-k;l;ef-e;s-#"],
  ["et", "et-e"], // évite un règle pour un seul mot
  ["est", "est-E"], // évite un règle pour un seul mot
  ["que", "qu-k;e-°"], // évite un règle pour un seul mot
  ["mars", "m-m;a-a;r-R;s-s"],
  ["encoigniez", "en-@;c-k;oi-o;gn-N;i-j;ez-e"],
  ["opisthocoelien", "o-o;p-p;i-i;s-s;t-t;h-#;o-o;c-s;oe-2;l-l;i-i;en-5"],
  ["opisthocoeliens", "o-o;p-p;i-i;s-s;t-t;h-#;o-o;c-s;oe-2;l-l;i-i;en-5;s-#"],
  [
    "opisthocoelienne",
    "o-o;p-p;i-i;s-s;t-t;h-#;o-o;c-s;oe-2;l-l;i-i;e-E;n-n;n-n;e-ç",
  ],
  [
    "opisthocoeliennes",
    "o-o;p-p;i-i;s-s;t-t;h-#;o-o;c-s;oe-2;l-l;i-i;e-E;n-n;n-n;e-ç;s-#",
  ],
  ["grizzly", "g-g;r-R;i-i;zz-z;l-l;y-i"], // évite un règle pour un seul mot
  ["grizzlys", "g-g;r-R;i-i;zz-z;l-l;y-i;s-#"], // évite un règle pour un seul mot
  ["monsieur", "m-m;on-°;s-s;i-j;eu-2;r-#"],
  ["messieurs", "m-m;e-e;ss-s;i-j;eu-2;r-#;s-#"],
  ["gars", "g-g;a-a;rs-#"],
  ["aeschne", "ae-e;s;ch-k;n-n;e-e"],
  ["aeschnes", "ae-e;s;ch-k;n-n;e-e;s-#"],
  ["elaeis", "el;ae-e;is"],
  ["élaeis", "el;ae-e;is"],
  ["maelstrom", "m-m;ae-a;l-l;s-s;t-t;r-R;om"],
  ["maelstroms", "m-m;ae-a;l-l;s-s;t-t;r-R;om;s-#"],
  ["maestoso", "m-m;a-a;e-E;s-s;t-t;o-o;s-z;o-o"],
  ["maestria", "m-m;a-a;e-E;s-s;t-t;r-R;i-/;a-a"],
  ["maestrias", "m-m;a-a;e-E;s-s;t-t;r-R;i-/;a-a;s-#"],
  ["maestro", "m-m;a-a;e-E;s-s;t-t;r-R;o-o"],
  ["maestros", "m-m;a-a;e-E;s-s;t-t;r-R;o-o;s-#"],
  ["paella", "p-p;a-a;e-E;ll-l;a-a"],
  ["paellas", "p-p;a-a;e-E;ll-l;a-a;s-#"],
  ["tael", "t-t;a-a;e-E;l-l"],
  ["taels", "t-t;a-a;e-E;l-l;s-#"],
  ["ægilopinée", "æ-E;g-Z;i-i;l-l;o-o;p-p;i-i;n-n;é-e;e-#"],
  ["ægilopinées", "æ-E;g-Z;i-i;l-l;o-o;p-p;i-i;n-n;é-e;e-#;s-#"],
  ["affettuoso", "a-a;ff-f;e-e;tt-t;u;o-o;s-z;o-o"],
  ["lignosité", "li;gn-N;o;s-z;it;é-e"],
  ["hargnosité", "h-#;a-a;r-R;gn-N;o-o;s-z;i-i;t-t;é-e"],
  ["ageratum", "a-a;g-Z;e;r-R;a-a;t-t;u-o;m-m"],
  ["ageratums", "a-a;g-Z;e;r-R;a-a;t-t;u-o;m-m;s-#"],
  ["lignomètre", "l-l;i-i;gn-N;o-o;m-m;è-E;t-t;r-R;e-ç"],
  ["lignomètres", "l-l;i-i;gn-N;o-o;m-m;è-E;t-t;r-R;e-ç;s-#"],
  ["magnan", "m-m;a-a;gn-N;an-@"], // Nom propre dans un poème de Victor Hugo
  ["bisexe", "b-b;i-i;s-s;e-E;x-x;e-ç"],
  ["bisexes", "b-b;i-i;s-s;e-E;x-x;e-ç;s-#"],
  ["bisexualité", "b-b;i-i;s-s;e-E;x-x;u-y;a-a;l-l;i-i;t-t;é-e"],
  ["bisexualités", "b-b;i-i;s-s;e-E;x-x;u-y;a-a;l-l;i-i;t-t;é-e;s-#"],
  ["bisexuel", "b-b;i-i;s-s;e-E;x-x;u-y;e-E;l-l"],
  ["bisexuels", "b-b;i-i;s-s;e-E;x-x;u-y;e-E;l-l;s-#"],
  ["bisexuelle", "b-b;i-i;s-s;e-E;x-x;u-y;e-E;ll-l;e-ç"],
  ["bisexuelles", "b-b;i-i;s-s;e-E;x-x;u-y;e-E;ll-l;e-ç;s-#"],
  ["bisexué", "b-b;i-i;s-s;e-E;x-x;u-y;é-e"],
  ["bisexués", "b-b;i-i;s-s;e-E;x-x;u-y;é-e;s-#"],
  ["bisexuée", "b-b;i-i;s-s;e-E;x-x;u-y;é-e;e-#"],
  ["bisexuées", "b-b;i-i;s-s;e-E;x-x;u-y;é-e;e-#;s-#"],
  ["trisoc", "t-t;r-R;i-i;s-s;o-o;c-k"],
  ["trisymptôme", "t-t;r-R;i-i;s-s;ym-5;p-p;t-t;ô-o;m-m;e-ç"],
  ["trisymptômes", "t-t;r-R;i-i;s-s;ym-5;p-p;t-t;ô-o;m-m;e-ç;s-#"],
  ["trisyndrome", "t-t;r-R;i-i;s-s;yn-5;d-d;r-R;o-o;m-m;e-ç"],
  ["trisyndromes", "t-t;r-R;i-i;s-s;yn-5;d-d;r-R;o-o;m-m;e-ç;s-#"],
  ["trisubstitué", "t-t;r-R;i-i;s-s;u-y;b-b;s-s;t-t;i-i;t-t;u-y;é-e"],
  ["trisubstituée", "t-t;r-R;i-i;s-s;u-y;b-b;s-s;t-t;i-i;t-t;u-y;é-e;e-#"],
  ["trisubstituées", "t-t;r-R;i-i;s-s;u-y;b-b;s-s;t-t;i-i;t-t;u-y;é-e;e-#;s-#"],
  ["trisubstitués", "t-t;r-R;i-i;s-s;u-y;b-b;s-s;t-t;i-i;t-t;u-y;é-e;s-#"],
  ["trisulfure", "t-t;r-R;i-i;s-s;u-y;l-l;f-f;u-y;r-R;e-ç"],
  ["trisulfures", "t-t;r-R;i-i;s-s;u-y;l-l;f-f;u-y;r-R;e-ç;s-#"],
  ["trisyllabes", "t-t;r-R;i-i;s-s;y-i;ll-l;a-a;b-b;e-ç;s-#"],
  ["trisyllabe", "t-t;r-R;i-i;s-s;y-i;ll-l;a-a;b-b;e-ç"],
  ["trisecteur", "t-t;r-R;i-i;s-s;e-E;c-k;t-t;eu-2;r-R"],
  ["trisecteurs", "t-t;r-R;i-i;s-s;e-E;c-k;t-t;eu-2;r-R;s-#"],
  ["trisection", "t-t;r-R;i-i;s-s;e-E;c-k;t-s;i-j;on-§"],
  ["trisections", "t-t;r-R;i-i;s-s;e-E;c-k;t-s;i-j;on-§;s-#"],
  ["trisectrice", "t-t;r-R;i-i;s-s;e-E;c-k;t-t;r-R;i-i;c-s;e-ç"],
  ["trisectrices", "t-t;r-R;i-i;s-s;e-E;c-k;t-t;r-R;i-i;c-s;e-ç;s-#"],
  ["trisyllabique", "t-t;r-R;i-i;s-s;y-i;ll-l;a-a;b-b;i-i;qu-k;e-ç"],
  ["trisyllabiques", "t-t;r-R;i-i;s-s;y-i;ll-l;a-a;b-b;i-i;qu-k;e-ç;s-#"],
  ["oignon", "oi-o;gn-N;on-§"],
  ["oignons", "oi-o;gn-N;on-§;s-#"],
  ["oignonet", "oi-o;gn-N;o-o;n-n;et-E"],
  ["oignonets", "oi-o;gn-N;o-o;n-n;e-E;t-#;s-#"],
  ["oignonière", "oi-o;gn-N;o-o;n-n;i-j;è-E;r-R;e-ç"],
  ["oignonières", "oi-o;gn-N;o-o;n-n;i-j;è-E;r-R;e-ç;s-#"],
  ["oignonnade", "oi-o;gn-N;o-o;n-n;n-n;a-a;d-d;e-ç"],
  ["oignonnades", "oi-o;gn-N;o-o;n-n;n-n;a-a;d-d;e-ç;s-#"],
  ["aiguë", "ai-E;g;u-y;ë-ç"],
  ["aiguës", "ai-E;g;u-y;ë-ç;s-#"],
  ["ailhaut", "a;il-j;h-#;au-o;t-#"],
  ["ailhauts", "a;il-j;h-#;au-o;t-#;s-#"],
  ["alastrim", "a-a;l-l;a-a;s-s;t-t;r-R;im"],
  ["alastrims", "a-a;l-l;a-a;s-s;t-t;r-R;im;s-#"],
  ["albedo", "a-a;l-l;b-b;e;d-d;o-o"],
  ["albedos", "a-a;l-l;b-b;e;d-d;o-o;s-#"],
  ["cocktails", "c-k;o-o;ck-k;t-t;ai-E;l;s-#"],
  ["cocktail", "c-k;o-o;ck-k;t-t;ai-E;l"],
  ["aguacate", "a-a;g;u-w;a-a;c-k;a-a;t-t;e"],
  ["aguacates", "a-a;g;u-w;a-a;c-k;a-a;t-t;e;s-#"],
  ["alguazil", "a-a;l-l;g;u-w;a-a;z-z;i-i;l-l"],
  ["alguazils", "a-a;l-l;g;u-w;a-a;z-z;i-i;l-l;s-#"],
  ["braguard", "b-b;r-R;a-a;g;u-w;a-a;r-R;d-#"],
  ["braguarde", "b-b;r-R;a-a;g;u-w;a-a;r-R;d-d;e-ç"],
  ["braguardes", "b-b;r-R;a-a;g;u-w;a-a;r-R;d-d;e-ç;s-#"],
  ["braguards", "b-b;r-R;a-a;g;u-w;a-a;r-R;d-#;s-#"],
  ["guanaco", "g;u-w;a-a;n-n;a-a;c-k;o-o"],
  ["guanacos", "g;u-w;a-a;n-n;a-a;c-k;o-o;s-#"],
  ["guaneux", "g;u-w;a-a;n-n;eu-2;x-#"],
  ["guanine", "g;u-w;a-a;n-n;i-i;n-n;e-ç"],
  ["guanines", "g;u-w;a-a;n-n;i-i;n-n;e-ç;s-#"],
  ["guano", "g;u-w;a-a;n-n;o-o"],
  ["guanos", "g;u-w;a-a;n-n;o-o;s-#"],
  ["guarana", "g;u-w;a-a;r-R;a-a;n-n;a-a"],
  ["guaranas", "g;u-w;a-a;r-R;a-a;n-n;a-a;s-#"],
  ["guarani", "g;u-w;a-a;r-R;a-a;n-n;i-i"],
  ["guaranis", "g;u-w;a-a;r-R;a-a;n-n;i-i;s-#"],
  ["iguane", "i-i;g;u-w;a-a;n-n;e-ç"],
  ["iguanes", "i-i;g;u-w;a-a;n-n;e-ç;s-#"],
  ["iguanodon", "i-i;g;u-w;a-a;n-n;o-o;d-d;on-§"],
  ["iguanodons", "i-i;g;u-w;a-a;n-n;o-o;d-d;on-§;s-#"],
  ["intralingual", "in-5;t-t;r-R;a-a;l-l;in-5;g;u-w;a-a;l-l"],
  // TODO rewrite the phonetic representation of ua words
  ["jaguar", "j-Z;a-a;g;u-w;a-a;r-R"],
  ["jaguars", "j-Z;a-a;g;u-w;a-a;r-R;s-#"],
  ["lingual", "l-l;in-5;g;u-w;a-a;l-l"],
  ["linguale", "l-l;in-5;g;u-w;a-a;l-l;e-ç"],
  ["linguales", "l-l;in-5;g;u-w;a-a;l-l;e-ç;s-#"],
  ["linguatule", "l-l;in-5;g;u-w;a-a;t-t;u-y;l-l;e-ç"],
  ["linguatules", "l-l;in-5;g;u-w;a-a;t-t;u-y;l-l;e-ç;s-#"],
  ["linguaux", "l-l;in-5;g;u-w;au-o;x-#"],
  ["nagualisme", "n-n;a-a;g;u-w;a-a;l-l;i-i;s-s;m-m;e-ç"],
  ["nagualismes", "n-n;a-a;g;u-w;a-a;l-l;i-i;s-s;m-m;e-ç;s-#"],
  ["paraguante", "p-p;a-a;r-R;a-a;g;u-w;an-@;t-t;e-ç"],
  ["paraguantes", "p-p;a-a;r-R;a-a;g;u-w;an-@;t-t;e-ç;s-#"],
  ["perlingual", "p-p;e-E;r-R;l-l;in-5;g;u-w;a-a;l-l"],
  ["perlinguale", "p-p;e-E;r-R;l-l;in-5;g;u-w;a-a;l-l;e-ç"],
  ["perlinguales", "p-p;e-E;r-R;l-l;in-5;g;u-w;a-a;l-l;e-ç;s-#"],
  ["perlinguaux", "p-p;e-E;r-R;l-l;in-5;g;u-w;au-o;x-#"],
  ["sublingual", "s-s;u-y;b-b;l-l;in-5;g;u-w;a-a;l-l"],
  ["sublinguale", "s-s;u-y;b-b;l-l;in-5;g;u-w;a-a;l-l;e-ç"],
  ["sublinguales", "s-s;u-y;b-b;l-l;in-5;g;u-w;a-a;l-l;e-ç;s-#"],
  ["sublinguaux", "s-s;u-y;b-b;l-l;in-5;g;u-w;au-o;x-#"],
  ["alderman", "a-a;l-l;d-d;e-E;r-R;m-m;an"],
  ["aldermans", "a-a;l-l;d-d;e-E;r-R;m-m;an;s-#"],
  ["ale", "a-E;l-l;e-ç"],
  // [ "ales", "a-E;l-l;e-ç;s-#" ], au profit de "ales" prononcé 'à la française" mais
  // dont j'ignore également le sens :-) Vive Morphalou!
  ["aleatico", "a-a;l-l;e;a-a;t-t;i-i;c-k;o-o"],
  ["aleaticos", "a-a;l-l;e;a-a;t-t;i-i;c-k;o-o;s-#"],
  ["algumim", "a-a;l-l;g-g;u-y;m-m;im"],
  ["algumims", "a-a;l-l;g-g;u-y;m-m;im;s-#"],
  ["alléluia", "a-a;ll-l;é-e;l-l;u;i-j;a-a"],
  ["alléluias", "a-a;ll-l;é-e;l-l;u;i-j;a-a;s-#"],
  ["almanach", "a-a;l-l;m-m;a-a;n-n;a-a;ch-#"], // il y a visiblement une grande discussion
  // sur la prononciation du ch. Nous suivons morphalou et la règle "ch se prononce k et
  // ne se fait sentir qu'en liaison avec une voyelle : un almanak ancien"
  // pour plus de détails voir https://cnrtl.fr/definition/almanach
  ["almanachs", "a-a;l-l;m-m;a-a;n-n;a-a;ch-#;s-#"],
  ["alpenstock", "a-a;l-l;p-p;e-E;n;s-s;t-t;o-o;c-k;k-k"],
  ["alpenstocks", "a-a;l-l;p-p;e-E;n;s-s;t-t;o-o;c-k;k-k;s-#"],
  ["alsacien", "a-a;l-l;s-z;a-a;c-s;i-j;en-5"],
  ["alsaciens", "a-a;l-l;s-z;a-a;c-s;i-j;en-5;s-#"],
  ["alsacienne", "a-a;l-l;s-z;a-a;c-s;i-j;e-E;n-n;n-n;e-ç"],
  ["alsaciennes", "a-a;l-l;s-z;a-a;c-s;i-j;e-E;n-n;n-n;e-ç;s-#"],
  ["alsace", "a-a;l-l;s-z;a-a;c-s;e-ç"],
  ["amabile", "a-a;m-m;a-a;b-b;i-i;l-l;e"],
  ["ambigu", "am-@;b-b;i-i;g;u-y"],
  ["ambiguë", "am-@;b-b;i-i;g;u-y;ë-ç"],
  ["ambiguës", "am-@;b-b;i-i;g;u-y;ë-ç;s-#"],
  ["bégu", "b-b;é-e;g;u-y"],
  ["béguë", "b-b;é-e;g;u-y;ë-ç"],
  ["béguës", "b-b;é-e;g;u-y;ë-ç;s-#"],
  ["besaiguë", "b-b;e-°;s-z;ai-E;g;u-y;ë-ç"],
  ["besaiguës", "b-b;e-°;s-z;ai-E;g;u-y;ë-ç;s-#"],
  ["bisaguë", "b-b;i-i;s-z;a-a;g;u-y;ë-ç"],
  ["bisaguës", "b-b;i-i;s-z;a-a;g;u-y;ë-ç;s-#"],
  ["bisaiguë", "b-b;i-i;s-z;ai-E;g;u-y;ë-ç"],
  ["bisaiguës", "b-b;i-i;s-z;ai-E;g;u-y;ë-ç;s-#"],
  ["ciguë", "c-s;i-i;g;u-y;ë-ç"],
  ["ciguës", "c-s;i-i;g;u-y;ë-ç;s-#"],
  ["contigu", "c-k;on-§;t-t;i-i;g;u-y"],
  ["contiguë", "c-k;on-§;t-t;i-i;g;u-y;ë-ç"],
  ["contiguës", "c-k;on-§;t-t;i-i;g;u-y;ë-ç;s-#"],
  ["exigu", "e-E;x-X;i-i;g;u-y"],
  ["exiguë", "e-E;x-X;i-i;g;u-y;ë-ç"],
  ["exiguës", "e-E;x-X;i-i;g;u-y;ë-ç;s-#"],
  ["gulden", "g-g;u-y;l-l;d-d;e-E;n"],
  ["guldens", "g-g;u-y;l-l;d-d;e-E;n;s-#"],
  ["subaiguë", "s-s;u-y;b-b;ai-E;g;u-y;ë-ç"],
  ["subaiguës", "s-s;u-y;b-b;ai-E;g;u-y;ë-ç;s-#"],
  ["suraiguë", "s-s;u-y;r-R;ai-E;g;u-y;ë-ç"],
  ["suraiguës", "s-s;u-y;r-R;ai-E;g;u-y;ë-ç;s-#"],
  ["ambiguïflore", "am-@;b-b;i-i;g;u-y;ï-i;f-f;l-l;o-o;r-R;e-ç"],
  ["ambiguïsme", "am-@;b-b;i-i;g;u-y;ï-i;s-s;m-m;e-ç"],
  ["ambiguïté", "am-@;b-b;i-i;g;u-y;ï-i;t-t;é-e"],
  ["ambiguïtés", "am-@;b-b;i-i;g;u-y;ï-i;t-t;é-e;s-#"],
  ["ambisexe", "am-@;b-b;i-i;s;e-E;x-x;e-ç"],
  ["ambosexuel", "am-@;b-b;o-o;s;e-E;x-x;u-y;e-E;l-l"],
  ["amenokal", "a-a;m-m;e;n-n;o-o;k-k;a-a;l-l"],
  ["amenokals", "a-a;m-m;e;n-n;o-o;k-k;a-a;l-l;s-#"],
  ["amict", "a-a;m-m;i-i;ct-#"], // Le caractère muet de -ct final est confirmé par Lab. 1881 (cnrtl)
  ["amicts", "a-a;m-m;i-i;ct-#;s-#"],
  ["amin", "a-a;m-m;in"],
  ["amins", "a-a;m-m;in;s-#"],
  ["amœbocytes", "a-a;m-m;œ-e;b-b;o-o;c-s;y-i;t-t;e-ç;s-#"],
  ["amschir", "am-@;sch-S;i-i;r-R"],
  ["amschirs", "am-@;sch-S;i-i;r-R;s-#"],
  ["champlevé", "ch-S;am-@;p-#;l-l;e-°;v-v;é-e"],
  ["champlevée", "ch-S;am-@;p-#;l-l;e-°;v-v;é-e;e-#"],
  ["champlevées", "ch-S;am-@;p-#;l-l;e-°;v-v;é-e;e-#;s-#"],
  ["champlevés", "ch-S;am-@;p-#;l-l;e-°;v-v;é-e;s-#"],
  ["clamp", "c-k;l-l;am-@;p-#"],
  ["hamburger", "h-#;am-@;b-b;u;r-R;g;e-2;r-R"],
  ["hamburgers", "h-#;am-@;b-b;u;r-R;g;e-2;r-R;s-#"],
  ["landamman", "l-l;an-@;d-d;a-a;mm-m;an"],
  ["landammans", "l-l;an-@;d-d;a-a;mm-m;an;s-#"],
  ["shampooina", "sh-S;am-@;p-p;oo-u;i-i;n-n;a-a"],
  ["shampooinai", "sh-S;am-@;p-p;oo-u;i-i;n-n;ai-e"],
  ["shampooinaient", "sh-S;am-@;p-p;oo-u;i-i;n-n;ai-E;ent-#"],
  ["shampooinais", "sh-S;am-@;p-p;oo-u;i-i;n-n;ai-E;s-#"],
  ["shampooinait", "sh-S;am-@;p-p;oo-u;i-i;n-n;ai-E;t-#"],
  ["shampooinâmes", "sh-S;am-@;p-p;oo-u;i-i;n-n;â-a;m-m;e-ç;s-#"],
  ["shampooinant", "sh-S;am-@;p-p;oo-u;i-i;n-n;an-@;t-#"],
  ["shampooinas", "sh-S;am-@;p-p;oo-u;i-i;n-n;a-a;s-#"],
  ["shampooinasse", "sh-S;am-@;p-p;oo-u;i-i;n-n;a-a;ss-s;e-ç"],
  ["shampooinassent", "sh-S;am-@;p-p;oo-u;i-i;n-n;a-a;ss-s;e-ç;nt-#"],
  ["shampooinasses", "sh-S;am-@;p-p;oo-u;i-i;n-n;a-a;ss-s;e-ç;s-#"],
  ["shampooinassiez", "sh-S;am-@;p-p;oo-u;i-i;n-n;a-a;ss-s;i-j;ez-e"],
  ["shampooinassions", "sh-S;am-@;p-p;oo-u;i-i;n-n;a-a;ss-s;i-j;on-§;s-#"],
  ["shampooinât", "sh-S;am-@;p-p;oo-u;i-i;n-n;â-a;t-#"],
  ["shampooinâtes", "sh-S;am-@;p-p;oo-u;i-i;n-n;â-a;t-t;e-ç;s-#"],
  ["shampooine", "sh-S;am-@;p-p;oo-u;i-i;n-n;e-ç"],
  ["shampooiné", "sh-S;am-@;p-p;oo-u;i-i;n-n;é-e"],
  ["shampooinée", "sh-S;am-@;p-p;oo-u;i-i;n-n;é-e;e-#"],
  ["shampooinées", "sh-S;am-@;p-p;oo-u;i-i;n-n;é-e;e-#;s-#"],
  ["shampooinent", "sh-S;am-@;p-p;oo-u;i-i;n-n;e-ç;nt-#"],
  ["shampooiner", "sh-S;am-@;p-p;oo-u;i-i;n-n;er-e"],
  ["shampooinera", "sh-S;am-@;p-p;oo-u;i-i;n-n;e-°;r-R;a-a"],
  ["shampooinerai", "sh-S;am-@;p-p;oo-u;i-i;n-n;e-°;r-R;ai-e"],
  ["shampooineraient", "sh-S;am-@;p-p;oo-u;i-i;n-n;e-°;r-R;ai-E;ent-#"],
  ["shampooinerais", "sh-S;am-@;p-p;oo-u;i-i;n-n;e-°;r-R;ai-E;s-#"],
  ["shampooinerait", "sh-S;am-@;p-p;oo-u;i-i;n-n;e-°;r-R;ai-E;t-#"],
  ["shampooineras", "sh-S;am-@;p-p;oo-u;i-i;n-n;e-°;r-R;a-a;s-#"],
  ["shampooinèrent", "sh-S;am-@;p-p;oo-u;i-i;n-n;è-E;r-R;e-ç;nt-#"],
  ["shampooinerez", "sh-S;am-@;p-p;oo-u;i-i;n-n;e-°;r-R;ez-e"],
  ["shampooineriez", "sh-S;am-@;p-p;oo-u;i-i;n-n;e-°;r-R;i-j;ez-e"],
  ["shampooinerions", "sh-S;am-@;p-p;oo-u;i-i;n-n;e-°;r-R;i-j;on-§;s-#"],
  ["shampooinerons", "sh-S;am-@;p-p;oo-u;i-i;n-n;e-°;r-R;on-§;s-#"],
  ["shampooineront", "sh-S;am-@;p-p;oo-u;i-i;n-n;e-°;r-R;on-§;t-#"],
  ["shampooines", "sh-S;am-@;p-p;oo-u;i-i;n-n;e-ç;s-#"],
  ["shampooinés", "sh-S;am-@;p-p;oo-u;i-i;n-n;é-e;s-#"],
  ["shampooineur", "sh-S;am-@;p-p;oo-u;i-i;n-n;eu-2;r-R"],
  ["shampooineurs", "sh-S;am-@;p-p;oo-u;i-i;n-n;eu-2;r-R;s-#"],
  ["shampooineuse", "sh-S;am-@;p-p;oo-u;i-i;n-n;eu-2;s-z;e-ç"],
  ["shampooineuses", "sh-S;am-@;p-p;oo-u;i-i;n-n;eu-2;s-z;e-ç;s-#"],
  ["shampooinez", "sh-S;am-@;p-p;oo-u;i-i;n-n;ez-e"],
  ["shampooing", "sh-S;am-@;p-p;oo-u;in-5;g-#"],
  ["shampooings", "sh-S;am-@;p-p;oo-u;in-5;g-#;s-#"],
  ["shampooiniez", "sh-S;am-@;p-p;oo-u;i-i;n-n;i-j;ez-e"],
  ["shampooinions", "sh-S;am-@;p-p;oo-u;i-i;n-n;i-j;on-§;s-#"],
  ["shampooinons", "sh-S;am-@;p-p;oo-u;i-i;n-n;on-§;s-#"],
  ["anet", "a-a;n-n;e-E;t"],
  ["anets", "a-a;n-n;e-E;t;s-#"],
  ["angustisepté", "an-@;g-g;u-y;s-s;t-t;i-i;s;e-E;p-#;t-t;é-e"],
  ["angustiseptée", "an-@;g-g;u-y;s-s;t-t;i-i;s;e-E;p-#;t-t;é-e;e-ç"],
  ["angustiseptées", "an-@;g-g;u-y;s-s;t-t;i-i;s;e-E;p-#;t-t;é-e;e-ç;s-#"],
  ["angustiseptés", "an-@;g-g;u-y;s-s;t-t;i-i;s;e-E;p-#;t-t;é-e;s-#"],
  ["anomye", "a-a;n-n;o-o;m-m;y-i;e-ç"],
  ["anomyes", "a-a;n-n;o-o;m-m;y-i;e-ç;s-#"],
  ["anschluss", "an-@;sch-S;l-l;u;ss-s"],
  ["anspect", "an-@;s-s;p-p;e-E;c-k;t-#"],
  ["anspects", "an-@;s-s;p-p;e-E;c-k;t-#;s-#"],
  ["antienzyme", "an-@;t-t;i;en-@;z-z;y-i;m-m;e-ç"],
  ["antienzymes", "an-@;t-t;i;en-@;z-z;y-i;m-m;e-ç;s-#"],
  ["ascien", "a-a;sc-s;i-j;en-5"],
  ["asciens", "a-a;sc-s;i-j;en-5;s-#"],
  ["aout", "aou-u;t-t"],
  ["aouts", "aou-u;t-t;s-#"],
  ["apoenzyme", "a-a;p-p;o;en-@;z-z;y-i;m-m;e-ç"],
  ["apoenzymes", "a-a;p-p;o;en-@;z-z;y-i;m-m;e-ç;s-#"],
  ["appoggiatura", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;a-a"],
  ["appoggiaturai", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;ai-e"],
  ["appoggiaturaient", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;ai-E;ent-#"],
  ["appoggiaturais", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;ai-E;s-#"],
  ["appoggiaturait", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;ai-E;t-#"],
  ["appoggiaturâmes", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;â-a;m-m;e-ç;s-#"],
  ["appoggiaturant", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;an-@;t-#"],
  ["appoggiaturas", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;a-a;s-#"],
  ["appoggiaturasse", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;a-a;ss-s;e-ç"],
  [
    "appoggiaturassent",
    "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;a-a;ss-s;e-ç;nt-#",
  ],
  [
    "appoggiaturasses",
    "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;a-a;ss-s;e-ç;s-#",
  ],
  [
    "appoggiaturassiez",
    "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;a-a;ss-s;i-j;ez-e",
  ],
  [
    "appoggiaturassions",
    "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;a-a;ss-s;i-j;on-§;s-#",
  ],
  ["appoggiaturât", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;â-a;t-#"],
  ["appoggiaturâtes", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;â-a;t-t;e-ç;s-#"],
  ["appoggiature", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;e-ç"],
  ["appoggiaturé", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;é-e"],
  ["appoggiaturée", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;é-e;e-#"],
  ["appoggiaturées", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;é-e;e-#;s-#"],
  ["appoggiaturent", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;e-ç;nt-#"],
  ["appoggiaturer", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;er-e"],
  ["appoggiaturera", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;e-°;r-R;a-a"],
  ["appoggiaturerai", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;e-°;r-R;ai-e"],
  [
    "appoggiatureraient",
    "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;e-°;r-R;ai-E;ent-#",
  ],
  [
    "appoggiaturerais",
    "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;e-°;r-R;ai-E;s-#",
  ],
  [
    "appoggiaturerait",
    "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;e-°;r-R;ai-E;t-#",
  ],
  ["appoggiatureras", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;e-°;r-R;a-a;s-#"],
  [
    "appoggiaturèrent",
    "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;è-E;r-R;e-ç;nt-#",
  ],
  ["appoggiaturerez", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;e-°;r-R;ez-e"],
  [
    "appoggiatureriez",
    "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;e-°;r-R;i-j;ez-e",
  ],
  [
    "appoggiaturerions",
    "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;e-°;r-R;i-j;on-§;s-#",
  ],
  [
    "appoggiaturerons",
    "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;e-°;r-R;on-§;s-#",
  ],
  [
    "appoggiatureront",
    "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;e-°;r-R;on-§;t-#",
  ],
  ["appoggiatures", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;e-ç;s-#"],
  ["appoggiaturés", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;é-e;s-#"],
  ["appoggiaturez", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;ez-e"],
  ["appoggiaturiez", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;i-j;ez-e"],
  ["appoggiaturions", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;i-j;on-§;s-#"],
  ["appoggiaturons", "a-a;pp-p;o-o;gg-Z;i-j;a-a;t-t;u-y;r-R;on-§;s-#"],
  // Il y a une intéressante discussion sur https://www.cnrtl.fr/definition/appoggiature
  // concernant la prononciation de appoggiature (qu'on peut aussi écrire apogiature :-)
  // paraît-il). Bref, je ne suis pas d'accord avec le choix de Morphalou.

  ["quetsche", "q-k;u-w;e-E;t-t;sch-S;e-ç"],
  ["quetsches", "q-k;u-w;e-E;t-t;sch-S;e-ç;s-#"],
  ["quetschier", "q-k;u-w;e-E;t-t;sch-S;i-j;er-e"],
  ["quetschiers", "q-k;u-w;e-E;t-t;sch-S;i-j;er-e;s-#"],
  ["aplétions", "a-a;p-p;l-l;é-e;t;i-j;on-§;s-#"],
  ["arboretum", "a-a;r-R;b-b;o-o;r-R;e;t-t;u-o;m-m"],
  ["arboretums", "a-a;r-R;b-b;o-o;r-R;e;t-t;u-o;m-m;s-#"],
  ["area", "a-a;r-R;e;a-a"],
  ["areas", "a-a;r-R;e;a-a;s-#"],
  ["ascolichens", "a-a;s-s;c-k;o-o;l-l;i-i;ch-k;e-E;n;s-#"],
  ["ascolichen", "a-a;s-s;c-k;o-o;l-l;i-i;ch-k;e-E;n"],
  ["aséité", "a-a;s;é-e;i-i;t-t;é-e"],
  ["aséités", "a-a;s;é-e;i-i;t-t;é-e;s-#"],
  ["assener", "a-a;ss-s;e;n-n;er-e"],
  ["asthmatique", "a-a;s-s;t-#;h-#;m-m;a-a;t-t;i-i;qu-k;e-ç"],
  ["asthmatiques", "a-a;s-s;t-#;h-#;m-m;a-a;t-t;i-i;qu-k;e-ç;s-#"],
  ["asthme", "a-a;s-s;t-#;h-#;m-m;e-ç"],
  ["asthmé", "a-a;s-s;t-#;h-#;m-m;é-e"],
  ["asthmée", "a-a;s-s;t-#;h-#;m-m;é-e;e-#"],
  ["asthmées", "a-a;s-s;t-#;h-#;m-m;é-e;e-#;s-#"],
  ["asthmes", "a-a;s-s;t-#;h-#;m-m;e-ç;s-#"],
  ["asthmés", "a-a;s-s;t-#;h-#;m-m;é-e;s-#"],
  ["astrantia", "a-a;s-s;t-t;r-R;an-@;t-s;i-j;a-a"],
  ["astrantias", "a-a;s-s;t-t;r-R;an-@;t-s;i-j;a-a;s-#"],
  ["atabeg", "a-a;t-t;a-a;b-b;e-E;g-k"],
  ["atabegs", "a-a;t-t;a-a;b-b;e-E;g-k;s-#"],
  ["atâbeg", "a-a;t-t;â-a;b-b;e-E;g-k"],
  ["atâbegs", "a-a;t-t;â-a;b-b;e-E;g-k;s-#"],
  ["atabek", "a-a;t-t;a-a;b-b;e-E;k-k"],
  ["atman", "a-a;t-t;m-m;an"],
  ["atmans", "a-a;t-t;m-m;an;s-#"],
  ["auburn", "au-o;b-b;u-2;r-R;n-n"],
  ["aulnage", "au-o;l-#;n-n;a-a;g-Z;e-ç"],
  ["aulnages", "au-o;l-#;n-n;a-a;g-Z;e-ç;s-#"],
  ["aulnaie", "au-o;l-#;n-n;ai-E;e-#"],
  ["aulnaies", "au-o;l-#;n-n;ai-E;e-#;s-#"],
  ["aulne", "au-o;l-#;n-n;e-ç"],
  ["aulnes", "au-o;l-#;n-n;e-ç;s-#"],
  ["aulnette", "au-o;l-#;n-n;e-E;tt-t;e-ç"],
  ["aulnettes", "au-o;l-#;n-n;e-E;tt-t;e-ç;s-#"],
  ["aulx", "au-o;l-#;x-#"],
  ["outrigger", "o-a;u-w;t;r-R;i;gg-g;e-°;r-R"],
  ["outriggers", "o-a;u-w;t;r-R;i;gg-g;e-°;r-R;s"],
  ["auxquels", "au-o;x-#;qu-k;e-E;l-l;s-#"],
  ["auxquelles", "au-o;x-#;qu-k;e-E;ll-l;e-ç;s-#"],
  ["aymara", "ay-E;m-m;a-a;r-R;a-a"],
  ["aymaras", "ay-E;m-m;a-a;r-R;a-a;s-#"],
  ["aztèque", "a-a;z-s;t-t;è-E;qu-k;e-ç"],
  ["aztèques", "a-a;z-s;t-t;è-E;qu-k;e-ç;s-#"],
  ["azulejo", "azule;j-R;o"],
  ["azulejos", "azule;j-R;o;s-#"],
  ["autosome", "au-o;t-t;o-o;s-z;o-o;m-m;e-ç"],
  ["autosomes", "au-o;t-t;o-o;s-z;o-o;m-m;e-ç;s-#"],
  ["canters", "c-k;an-@;t-t;e-E;r-R;s-#"],
  ["springer", "s-s;p-p;r-R;i;ng-G;e-E;r-R"],
  ["springers", "s-s;p-p;r-R;i;ng-G;e-E;r-R;s-#"],
  ["yard", "y-j;a-a;r-R;d"],
  ["yards", "y-j;a-a;r-R;d;s-#"],
  ["yawl", "y-j;aw-o;l-l"],
  ["yawls", "y-j;aw-o;l-l;s-#"],
  ["yearling", "y-j;ea-2;r-R;l-l;i-i;ng-G"],
  ["yearlings", "y-j;ea-2;r-R;l-l;i-i;ng-G;s-#"],
  ["yeoman", "y-j;e-#;oman"],
  ["yeomans", "y-j;e-#;oman;s-#"],
  ["yeti", "y-j;e;t-t;i-i"],
  ["yetis", "y-j;e;t-t;i-i;s-#"],
  ["yin", "y-j;in"],
  ["yins", "y-j;in;s-#"],
  ["ylang", "y-i;l-l;an-@;g-#"],
  ["yod", "y-j;od"],
  ["yods", "y-j;od;s-#"],
  ["yogi", "y-j;o-o;g;i-i"],
  ["yoginî", "y-j;o-o;g;i-i;n-n;î-i"],
  ["yogis", "y-j;o-o;g;i-i;s-#"],
  ["yohimbehe", "y-j;o-o;h-#;im;b-b;e;h-#;e-ç"],
  ["yohimbehes", "y-j;o-o;h-#;im;b-b;e;h-#;e-ç;s-#"],
  ["yohimbine", "y-j;o-o;h-#;im;b-b;i-i;n-n;e-ç"],
  ["youd", "y-j;ou-u;d-t"],
  ["yucca", "y-j;u;cc-k;a-a"],
  ["yuccas", "y-j;u;cc-k;a-a;s-#"],
  ["zapateado", "z-s;a-a;p-p;a-a;t-t;e;a-a;d-d;o-o"],
  ["zapateados", "z-s;a-a;p-p;a-a;t-t;e;a-a;d-d;o-o;s-#"],
  ["zarzuela", "z-s;a-a;r-R;z-s;u-w;e-e;l-l;a-a"],
  ["zarzuelas", "z-s;a-a;r-R;z-s;u-w;e-e;l-l;a-a;s-#"],
  ["zerumbet", "z-z;e;r-R;um-§;b-b;et-E"],
  ["zerumbets", "z-z;e;r-R;um-§;b-b;et-E;s-#"],
  ["zinc", "z-z;in-5;c-g"],
  ["zincs", "z-z;in-5;c-g;s-#"],
  // [ "zoom", "z-z;oo-u;m-m" ],
  // [ "zooms", "z-z;oo-u;m-m;s-#" ],
  ["zygopteris", "z-z;y-i;g-g;o-o;p-p;t-t;e;r-R;i-i;s-s"],
  ["yacht", "y-j;a-o;ch-#;t-t"],
  ["yachting", "y-j;a-o;ch-#;t-t;i-i;ng-G"],
  ["yachtings", "y-j;a-o;ch-#;t-t;i-i;ng-G;s-#"],
  ["yachtman", "y-j;a-o;ch-#;t-t;m-m;an"],
  ["yachtmans", "y-j;a-o;ch-#;t-t;m-m;an;s-#"],
  ["yachts", "y-j;a-o;ch-#;t-t;s-#"],
  ["yachtsman", "y-j;a-o;ch-#;t-t;s-s;m-m;an"],
  ["yachtsmans", "y-j;a-o;ch-#;t-t;s-s;m-m;an;s-#"],
  ["yankee", "y-j;an-@;k-k;ee-i"],
  ["yankees", "y-j;an-@;k-k;ee-i;s-#"],
  ["xiphosures", "x-x;i-i;ph-f;o-o;s;u-y;r-R;e-ç;s-#"],
  ["xiphosure", "x-x;i-i;ph-f;o-o;s;u-y;r-R;e-ç"],
  ["xiphosuridés", "x-x;i-i;ph-f;o-o;s;u-y;r-R;i-i;d-d;é-e;s-#"],
  ["xiphosuridé", "x-x;i-i;ph-f;o-o;s;u-y;r-R;i-i;d-d;é-e"],
  ["xérique", "x-x;é-e;r-R;i-i;qu-k;e-ç"],
  ["xériques", "x-x;é-e;r-R;i-i;qu-k;e-ç;s-#"],
  ["goth", "g-g;o-o;th-#"],
  ["ostrogoth", "o-o;s-s;t-t;r-R;o-o;g-g;o-o;th-#"],
  ["visigoth", "v-v;i-i;s-z;i-i;g-g;o-o;th-#"],
  ["wisigoth", "w-v;i-i;s-z;i-i;g-g;o-o;th-#"],
  ["goths", "g-g;o-o;th-#;s-#"],
  ["ostrogoths", "o-o;s-s;t-t;r-R;o-o;g-g;o-o;th-#;s-#"],
  ["visigoths", "v-v;i-i;s-z;i-i;g-g;o-o;th-#;s-#"],
  ["wisigoths", "w-v;i-i;s-z;i-i;g-g;o-o;th-#;s-#"],
  ["winch", "w-w;in;ch-S"],
  ["winchester", "w-w;in;ch-S;e-E;s-s;t-t;e-E;r-R"],
  ["winchesters", "w-w;in;ch-S;e-E;s-s;t-t;e-E;r-R;s-#"],
  ["winchs", "w-w;in;ch-S;s-#"],
  ["wintergreen", "w-w;in;t-t;e-2;r-R;g-g;r-R;ee-i;n"],
  ["wintergreens", "w-w;in;t-t;e-2;r-R;g-g;r-R;ee-i;n;s-#"],
  ["wading", "w-w;a-E;d-d;i-i;ng-G"],
  ["wadings", "w-w;a-E;d-d;i-i;ng-G;s-#"],
  ["wad", "w-w;a-a;d"],
  ["wads", "w-w;a-a;d;s-#"],
  ["wagnerolâtre", "w-v;a-a;g-g;n-n;e-E;r-R;o-o;l-l;â-a;t-t;r-R;e-ç"],
  ["washingtonia", "w-w;a-a;sh-S;i;ng-G;t-t;o-o;n-n;i-j;a-a"],
  ["washingtonias", "w-w;a-a;sh-S;i;ng-G;t-t;o-o;n-n;i-j;a-a;s-#"],
  ["waterproof", "w-w;a-a;t-t;e-E;r-R;p-p;r-R;oo-u;f-f"],
  ["waterproofs", "w-w;a-a;t-t;e-E;r-R;p-p;r-R;oo-u;f-f;s-#"],
  ["wattman", "w-w;a-a;tt-t;m-m;an"],
  ["wattmans", "w-w;a-a;tt-t;m-m;an;s-#"],
  ["weber", "w-v;e;b-b;e-E;r-R"],
  ["webers", "w-v;e;b-b;e-E;r-R;s-#"],
  ["wellingtonia", "w-w;e-E;ll-l;in;g-g;t-t;o-o;n-n;i-j;a-a"],
  ["wellingtonias", "w-w;e-E;ll-l;in;g-g;t-t;o-o;n-n;i-j;a-a;s-#"],
  ["weltanschauung", "w-v;e-E;l-l;t-t;an;sch-S;a;u-w;u;ng-G"],
  ["weltanschauungs", "w-v;e-E;l-l;t-t;an;sch-S;a;u-w;u;ng-G;s-#"],
  ["wergeld", "w-v;e-E;r-R;g;e-E;l-l;d"],
  ["wergelds", "w-v;e-E;r-R;g;e-E;l-l;d;s-#"],
  ["whipcord", "w-w;h-#;i-i;p-p;c-k;o-o;r-R;d"],
  ["whipcords", "w-w;h-#;i-i;p-p;c-k;o-o;r-R;d;s-#"],
  ["whiskey", "w-w;h-#;i-i;s-s;k-k;ey-i"],
  ["whiskeys", "w-w;h-#;i-i;s-s;k-k;ey-i;s-#"],
  ["willemite", "w-v;i-i;ll-l;e;m-m;i-i;t-t;e-ç"],
  ["willemites", "w-v;i-i;ll-l;e;m-m;i-i;t-t;e-ç;s-#"],
  ["pays", "p-p;a-E;y-i;s-#"],
  ["voult", "v-v;ou-u;l-#;t-#"],
  ["voults", "v-v;ou-u;l-#;t-#;s-#"],
  ["vosgien", "v-v;o-o;s-#;g-Z;i-j;en-5"],
  ["vosgiens", "v-v;o-o;s-#;g-Z;i-j;en-5;s-#"],
  ["vosgienne", "v-v;o-o;s-#;g-Z;i-j;e-E;n-n;n-n;e-ç"],
  ["vosgiennes", "v-v;o-o;s-#;g-Z;i-j;e-E;n-n;n-n;e-ç;s-#"],
  ["verumontanum", "v-v;e;r-R;u-y;m-m;on-§;t-t;a-a;n-n;u-o;m-m"],
  ["veto", "v-v;e;t-t;o-o"],
  ["vetos", "v-v;e;t-t;o-o;s-#"],
  ["vilayet", "v-v;i-i;l-l;a;y-j;et-E"],
  ["vilayets", "v-v;i-i;l-l;a;y-j;e-E;t-#;s-#"],
  ["vilebrequin", "v-v;i-i;l-l;e-°;b-b;r-R;e-°;qu-k;in-5"],
  ["vilebrequins", "v-v;i-i;l-l;e-°;b-b;r-R;e-°;qu-k;in-5;s-#"],
  ["voceratrice", "v-v;o-o;c-s;e;r-R;a-a;t-t;r-R;i-i;c-s;e-ç"],
  ["voceratrices", "v-v;o-o;c-s;e;r-R;a-a;t-t;r-R;i-i;c-s;e-ç;s-#"],
  ["vocero", "v-v;o-o;c-s;e;r-R;o-o"],
  ["voceros", "v-v;o-o;c-s;e;r-R;o-o;s-#"],
  ["requiem", "r-R;e;q-k;u-y;i-j;e-E;m-m"],
  ["requiems", "r-R;e;q-k;u-y;i-j;e-E;m-m;s-#"],
  ["dessus", "d-d;e-°;ss-s;u-y;s-#"],
  ["dessous", "d-d;e-°;ss-s;ou-u;s-#"],
  ["cet", "c-s;e-E;t-t"],
  ["ventrebleu", "v-v;en-@;t-t;r-R;e-°;b-b;l-l;eu-2"],
  ["sacrebleu", "s-s;a-a;c-k;r-R;e-°;b-b;l-l;eu-2"],
  ["sacreblotte", "s-s;a-a;c-k;r-R;e-°;b-b;l-l;o-o;tt-t;e-ç"],
  ["entre", "en-@;t-t;r-R;e-ç"],
  ["contre", "c-k;on-§;t-t;r-R;e-ç"],
  ["contres", "c-k;on-§;t-t;r-R;e-ç;s-#"],
  ["contrescarpe", "c-k;on-§;t-t;r-R;e-E;s-s;c-k;a-a;r-R;p-p;e-ç"],
  ["contrescarpes", "c-k;on-§;t-t;r-R;e-E;s-s;c-k;a-a;r-R;p-p;e-ç;s-#"],
  ["inretrouvable", "in-5;r-R;e-°;t-t;r-R;ou-u;v-v;a-a;b-b;l-l;e-ç"],
  ["inretrouvables", "in-5;r-R;e-°;t-t;r-R;ou-u;v-v;a-a;b-b;l-l;e-ç;s-#"],
  ["irreprésentable", "i-i;rr-R;e-°;p-p;r-R;é-e;s-z;en-@;t-t;a-a;b-b;l-l;e-ç"],
  [
    "irreprésentables",
    "i-i;rr-R;e-°;p-p;r-R;é-e;s-z;en-@;t-t;a-a;b-b;l-l;e-ç;s-#",
  ],
  ["irretrouvable", "i-i;rr-R;e-°;t-t;r-R;ou-u;v-v;a-a;b-b;l-l;e-ç"],
  ["irretrouvables", "i-i;rr-R;e-°;t-t;r-R;ou-u;v-v;a-a;b-b;l-l;e-ç;s-#"],
  ["pardessus", "p-p;a-a;r-R;d-d;e-°;ss-s;u-y;s-#"],
  ["crebleu", "c-k;r-R;e-°;b-b;l-l;eu-2"],
  ["derechef", "d-d;e-°;r-R;e-°;ch-S;e-E;f-f"],
  ["baby", "b-b;a-e;b-b;y-i"],
  ["babys", "b-b;a-e;b-b;y-i;s-#"],
  ["bacchich", "b-b;a-a;c-k;ch-S;i-i;ch-S"],
  ["bacchichs", "b-b;a-a;c-k;ch-S;i-i;ch-S;s-#"],
  ["bacon", "b-b;a-e;c-k;on"],
  ["bacons", "b-b;a-e;c-k;on;s-#"],
  ["baconianisme", "b-b;a-e;c-k;o-o;n-n;i-j;a-a;n-n;i-i;s-s;m-m;e-ç"],
  ["baconianismes", "b-b;a-e;c-k;o-o;n-n;i-j;a-a;n-n;i-i;s-s;m-m;e-ç;s-#"],
  ["baconien", "b-b;a-e;c-k;o-o;n-n;i-j;en-5"],
  ["baconiens", "b-b;a-e;c-k;o-o;n-n;i-j;en-5;s-#"],
  ["baconienne", "b-b;a-e;c-k;o-o;n-n;i-j;e-E;nn-n;e-ç"],
  ["baconiennes", "b-b;a-e;c-k;o-o;n-n;i-j;e-E;nn-n;e-ç;s-#"],
  ["bacul", "b-b;a-a;c-k;u-y;l-#"],
  ["baculs", "b-b;a-a;c-k;u-y;l-#;s-#"],
  ["cucul", "c-k;u-y;c-k;u-y;l-#"],
  ["cuculs", "c-k;u-y;c-k;u-y;l-#;s-#"],
  ["cul", "c-k;u-y;l-#"],
  ["culs", "c-k;u-y;l-#;s-#"],
  ["tapecul", "t-t;a-a;p-p;e-°;c-k;u-y;l-#"],
  ["tapeculs", "t-t;a-a;p-p;e-°;c-k;u-y;l-#;s-#"],
  ["badminton", "b-b;a-a;d-d;m-m;in;t-t;on"],
  ["badmintons", "b-b;a-a;d-d;m-m;in;t-t;on;s-#"],
  ["baedeker", "b-b;ae-e;d-d;e;k-k;e-E;r-R"],
  ["baedekers", "b-b;ae-e;d-d;e;k-k;e-E;r-R;s-#"],
  ["balbutiions", "b-b;a-a;l-l;b-b;u-y;t-s;i-i;i-j;on-§;s-#"],
  ["balbutiiez", "b-b;a-a;l-l;b-b;u-y;t-s;i-i;i-j;ez-e"],
  ["initiions", "i-i;n-n;i-i;t-s;i-i;i-j;on-§;s-#"],
  ["initiiez", "i-i;n-n;i-i;t-s;i-i;i-j;ez-e"],
  ["différentiions", "d-d;i-i;ff-f;é-e;r-R;en-@;t-s;i-i;i-j;on-§;s-#"],
  ["différentiiez", "d-d;i-i;ff-f;é-e;r-R;en-@;t-s;i-i;i-j;ez-e"],
  ["banderillero", "b-b;an-@;d-d;e-°;r-R;ill-/;e-E;r-R;o-o"],
  ["banderilleros", "b-b;an-@;d-d;e-°;r-R;ill-/;e-E;r-R;o-o;s-#"],
  ["barbecue", "b-b;a-a;r-R;b-b;e-°;c-k;u;e-#"],
  ["barbecues", "b-b;a-a;r-R;b-b;e-°;c-k;u;e-#;s-#"],
  ["barman", "b-b;a-a;r-R;m-m;an"],
  ["barmans", "b-b;a-a;r-R;m-m;an;s-#"],
  ["basedowien", "b-b;a-a;s-z;e-°;d-d;o;w-v;i-j;en-5"],
  ["basedowiens", "b-b;a-a;s-z;e-°;d-d;o;w-v;i-j;en-5;s-#"],
  ["basedowienne", "b-b;a-a;s-z;e-°;d-d;o;w-v;i-j;e-E;nn-n;e-ç"],
  ["basedowiennes", "b-b;a-a;s-z;e-°;d-d;o;w-v;i-j;e-E;nn-n;e-ç;s-#"],
  [
    "batrachosioplastie",
    "b-b;a-a;t-t;r-R;a-a;ch-k;o-o;s-z;i-j;o-o;p-p;l-l;a-a;s-s;t-t;i-i;e-ç",
  ],
  [
    "batrachosioplasties",
    "b-b;a-a;t-t;r-R;a-a;ch-k;o-o;s-z;i-j;o-o;p-p;l-l;a-a;s-s;t-t;i-i;e-ç;s-#",
  ],
  ["batz", "b-b;a-a;t-t;z-s"],
  ["bayle", "b-b;ay-E;l-l;e-°"],
  ["bayles", "b-b;ay-E;l-l;e-°;s-#"],
  ["bayreuthien", "b-b;a-E;y-j;r-R;eu-2;t-t;h-#;i-j;en-5"],
  ["bayreuthiens", "b-b;a-E;y-j;r-R;eu-2;t-t;h-#;i-j;en-5;s-#"],
  ["bayreuthienne", "b-b;a-E;y-j;r-R;eu-2;t-t;h-#;i-j;e-E;nn-n;e-ç"],
  ["bayreuthiennes", "b-b;a-E;y-j;r-R;eu-2;t-t;h-#;i-j;e-E;nn-n;e-ç;s-#"],
  ["bazooka", "b-b;a-a;z-z;oo-u;k-k;a-a"],
  ["bazookas", "b-b;a-a;z-z;oo-u;k-k;a-a;s-#"],
  ["beatnik", "b-b;ea-i;t-t;n-n;i-i;k-k"],
  ["beatniks", "b-b;ea-i;t-t;n-n;i-i;k-k;s-#"],
  ["béchen", "b-b;é-e;ch-S;e-E;n"],
  ["beethovenien", "b-b;ee-e;t-t;h-#;o-o;v-v;e-°;n-n;i-j;en-5"],
  ["beethoveniens", "b-b;ee-e;t-t;h-#;o-o;v-v;e-°;n-n;i-j;en-5;s-#"],
  ["beethovenienne", "b-b;ee-e;t-t;h-#;o-o;v-v;e-°;n-n;i-j;e-E;nn-n;e-ç"],
  ["beethoveniennes", "b-b;ee-e;t-t;h-#;o-o;v-v;e-°;n-n;i-j;e-E;nn-n;e-ç;s-#"],
  ["beethoven", "b-b;ee-et-t;h-#;o-o;v-v;en-@"],
  ["behaviourisme", "b-b;e-i;h-#;a-a;v-v;i-j;ou-o;r-R;i-i;s-s;m-m;e-ç"],
  ["behaviourismes", "b-b;e-i;h-#;a-a;v-v;i-j;ou-o;r-R;i-i;s-s;m-m;e-ç;s-#"],
  //[ "bélemnite", "b-b;é-e;l-l;e-E;mn;i-i;t-t;e-ç" ],
  //[ "bélemnites", "b-b;é-e;l-l;e-E;mn;i-i;t-t;e-ç;s-#" ],
  //[ "bélemnitique", "b-b;é-e;l-l;e-E;mn;i-i;t-t;i-i;qu-k;e-ç" ],
  //[ "bélemnitiques", "b-b;é-e;l-l;e-E;mn;i-i;t-t;i-i;qu-k;e-ç;s-#" ],
  //[ "bélemnitologie", "b-b;é-e;l-l;e-E;mn;i-i;t-t;o-o;l-l;o-o;g-Z;i-i;e-#" ],
  //[ "bélemnitologies", "b-b;é-e;l-l;e-E;mn;i-i;t-t;o-o;l-l;o-o;g-Z;i-i;e-#;s-#" ],
  ["béluga", "b-b;é-e;l-l;u;g-g;a-a"],
  ["bélugas", "b-b;é-e;l-l;u;g-g;a-a;s-#"],
  ["benedictus", "benedi;c-k;t-t;u-y;s-s"],
  [
    "benzènesulfonyle",
    "b-b;en-5;z-z;è-E;n-n;e-°;s;u-y;l-l;f-f;o-o;n-n;y-i;l-l;e-ç",
  ],
  ["benzosulfate", "b-b;en-5;z-z;o-o;s;u-y;l-l;f-f;a-a;t-t;e-ç"],
  ["benzosulfates", "b-b;en-@;z-z;o-o;s;u-y;l-l;f-f;a-a;t-t;e-ç;s-#"],
  ["benzosulfurique", "b-b;en-5;z-z;o-o;s;u-y;l-l;f-f;u-y;r-R;i-i;qu-k;e-ç"],
  [
    "benzosulfuriques",
    "b-b;en-@;z-z;o-o;s;u-y;l-l;f-f;u-y;r-R;i-i;qu-k;e-ç;s-#",
  ],
  ["besef", "b-b;e;s-z;e-E;f-f"],
  ["besicles", "b-b;e;s-z;i-i;c-k;l-l;e-ç;s-#"],
  ["bezestan", "b-b;e;z-z;e-E;s-s;t-t;an-@"],
  ["bezestans", "b-b;e;z-z;e-E;s-s;t-t;an-@;s-#"],
  ["bezet", "b-b;e;z-z;et-E"],
  ["bezets", "b-b;e;z-z;e-E;t-#;s-#"],
  ["bezoard", "b-b;e;z-z;o-o;a-a;r-R;d-#"],
  ["bezoards", "b-b;e;z-z;o-o;a-a;r-R;d-#;s-#"],
  ["béroe", "b-b;é-e;r-R;oe"],
  ["béroes", "b-b;é-e;r-R;oe;s-#"],
  ["bimbachi", "b-b;im;b-b;a-a;ch-S;i-i"],
  ["bimbachis", "b-b;im;b-b;a-a;ch-S;i-i;s-#"],
  ["bisulfite", "b-b;i-i;s;u-y;l-l;f-f;i-i;t-t;e-ç"],
  ["bisulfites", "b-b;i-i;s;u-y;l-l;f-f;i-i;t-t;e-ç;s-#"],
  ["blackball", "b-b;l-l;a-a;ck-k;b-b;a-o;ll-l"],
  ["dracœna", "d-d;r-R;a-a;c-s;œ-e;n-n;a-a"],
  ["dracœnas", "d-d;r-R;a-a;c-s;œ-e;n-n;a-a;s-#"],
  ["dracoena", "d-d;r-R;a-a;c-s;oe-o;n-n;a-a"],
  ["dracoenas", "d-d;r-R;a-a;c-s;oe-o;n-n;a-a;s-#"],
  ["blazer", "b-b;l-l;a-E;z-z;e-2;r-R"],
  ["blazers", "b-b;l-l;a-E;z-z;e-2;r-R;s-#"],
  ["zaïmph", "z-z;a-a;ï-i;m;ph-f"],
  ["zaïmphs", "z-z;a-a;ï-i;m;ph-f;s-#"],
  ["cerf", "c-s;e-E;r-R;f-#"],
  ["cerfs", "c-s;e-E;r-R;fs-#"],
  ["serf", "s-s;e-E;r-R;f-#"],
  ["serfs", "s-s;e-E;r-R;fs-#"],
  ["hircocerf", "h-#;i-i;r-R;c-k;o-o;c-s;e-E;r-R;f-#"],
  ["hircocerfs", "h-#;i-i;r-R;c-k;o-o;c-s;e-E;r-R;fs-#"],
  ["nerf", "n-n;e-E;r-R;f-#"],
  ["nerfs", "n-n;e-E;r-R;fs-#"],
  [
    "blépharosynéchie",
    "b-b;l-l;é-e;ph-f;a-a;r-R;o-o;s;y-i;n-n;é-e;ch-S;i-i;e-#",
  ],
  [
    "blépharosynéchies",
    "b-b;l-l;é-e;ph-f;a-a;r-R;o-o;s;y-i;n-n;é-e;ch-S;i-i;e-#;s-#",
  ],
  ["blizzard", "b-b;l-l;i-i;zz-z;a-a;r-R;d-#"],
  ["blizzards", "b-b;l-l;i-i;zz-z;a-a;r-R;d-#;s-#"],
  ["boolâtre", "b-b;o-o;o-o;l-l;â-a;t-t;r-R;e-ç"],
  ["boolâtres", "b-b;o-o;o-o;l-l;â-a;t-t;r-R;e-ç;s-#"],
  ["coolie", "c-k;oo-u;l-l;i-i;e-#"],
  ["coolies", "c-k;oo-u;l-l;i-i;e-#;s-#"],
  ["looping", "l-l;oo-u;p-p;i-i;ng-G"],
  ["loopings", "l-l;oo-u;p-p;i-i;ng-G;s-#"],
  ["pool", "p-p;oo-u;l-l"],
  ["pools", "p-p;oo-u;l-l;s-#"],
  ["schooner", "s;c-k;h-#;oo-u;n-n;e-2;r-R"],
  ["schooners", "s;c-k;h-#;oo-u;n-n;e-2;r-R;s-#"],
  ["looch", "l-l;oo-o;ch-k"],
  ["loochs", "l-l;oo-o;ch-k;s-#"],
  ["moos", "m-m;oo-o;s-s"],
  ["blues", "b-b;l-l;u;e-#;s-z"],
  ["bochiman", "b-b;o-o;ch-S;i-i;m-m;an"],
  ["boschiman", "b-b;o-o;sch-S;i-i;m-m;an"],
  ["bochimans", "b-b;o-o;ch-S;i-i;m-m;an;s-#"],
  ["boschimans", "b-b;o-o;sch-S;i-i;m-m;an;s-#"],
  ["bosseman", "b-b;o-o;ss-s;e-°;m-m;an"],
  ["bossemans", "b-b;o-o;ss-s;e-°;m-m;an;s-#"],
  ["brahman", "b-b;r-R;a-a;h-#;m-m;an"],
  ["brahmans", "b-b;r-R;a-a;h-#;m-m;an;s-#"],
  ["bruman", "b-b;r-R;u-y;m-m;an"],
  ["businessman", "b-b;u-i;s-z;i-#;n-n;e-E;ss-s;m-m;an"],
  ["caméraman", "c-k;a-a;m-m;é-e;r-R;a-a;m-m;an"],
  ["caméramans", "c-k;a-a;m-m;é-e;r-R;a-a;m-m;an;s-#"],
  ["chairman", "ch-S;ai-E;r-R;m-m;an"],
  ["chaman", "ch-S;a-a;m-m;an"],
  ["clergyman", "c-k;l-l;e-E;r-R;g-Z;y-i;m-m;an"],
  ["clergymans", "c-k;l-l;e-E;r-R;g-Z;y-i;m-m;an;s-#"],
  ["clubman", "c-k;l-l;u-2;b-b;m-m;an"],
  ["dan", "d-d;an"],
  ["drogman", "d-d;r-R;o-o;g-g;m-m;an"],
  ["drogmans", "d-d;r-R;o-o;g-g;m-m;an;s-#"],
  ["fan", "f-f;an"],
  ["fans", "f-f;an;s-#"],
  ["gentleman", "g-Z;en-@;t-t;l-l;e-°;m-m;an"],
  ["jean", "j-Z;ea-i;n"],
  ["jeans", "j-Z;ea-i;n;s-#"],
  ["jerrican", "j-Z;e-E;rr-R;i-i;c-k;an"],
  ["jerrycan", "j-Z;e-E;rr-R;y-i;c-k;an"],
  ["jerricans", "j-Z;e-E;rr-R;i-i;c-k;an;s-#"],
  ["jerrycans", "j-Z;e-E;rr-R;y-i;c-k;an;s-#"],
  ["karman", "k-k;a-a;r-R;m-m;an"],
  ["midshipman", "m-m;i-i;d-d;sh-S;i-i;p-p;m-m;an"],
  ["mufleman", "m-m;u-y;f-f;l-l;e-°;m-m;an"],
  ["policeman", "p-p;o-o;l-l;i-i;c-s;e-#;m-m;an"],
  ["pullman", "p-p;u;ll-l;m-m;an"],
  ["recordman", "r-R;e-°;c-k;o-o;r-R;d-d;m-m;an"],
  ["recordwoman", "r-R;e-°;c-k;o-o;r-R;d-d;w-w;o-o;m-m;an"],
  ["rugbyman", "r-R;u-y;g-g;b-b;y-i;m-m;an"],
  ["sportman", "s-s;p-p;o-o;r-R;t-t;m-m;an"],
  ["sportsman", "s-s;p-p;o-o;r-R;t-t;s-s;m-m;an"],
  ["sportwoman", "s-s;p-p;o-o;r-R;t-t;w-w;o-o;m-m;an"],
  ["boëte", "b-b;o-w;ë-a;t-t;e-ç"],
  ["boëtes", "b-b;o-w;ë-a;t-t;e-ç;s-#"],
  ["boëtte", "b-b;o-w;ë-a;tt-t;e-ç"],
  ["boëttes", "b-b;o-w;ë-a;tt-t;e-ç;s-#"],
  ["boggie", "b-b;o-o;gg-Z;i-i;e-#"],
  ["boggies", "b-b;o-o;gg-Z;i-i;e-#;s-#"],
  ["bonneterie", "b-b;o-o;nn-n;e-E;t-t;e-°;r-R;i-i;e-#"],
  ["bonneteries", "b-b;o-o;nn-n;e-E;t-t;e-°;r-R;i-i;e-#;s-#"],
  ["briqueterie", "b-b;r-R;i-i;qu-k;e-E;t-t;e-°;r-R;i-i;e-#"],
  ["briqueteries", "b-b;r-R;i-i;qu-k;e-E;t-t;e-°;r-R;i-i;e-#;s-#"],
  ["buffleterie", "b-b;u-y;ff-f;l-l;e-E;t-t;e-°;r-R;i-i;e-#"],
  ["buffleteries", "b-b;u-y;ff-f;l-l;e-E;t-t;e-°;r-R;i-i;e-#;s-#"],
  ["canzonetta", "c-k;an;z-z;o-o;n-n;e-E;tt-t;a-a"],
  ["canzonettas", "c-k;an;z-z;o-o;n-n;e-E;tt-t;a-a;s-#"],
  ["canzonette", "c-k;an;z-z;o-o;n-n;e-E;tt-t;e-ç"],
  ["canzonettes", "c-k;an;z-z;o-o;n-n;e-E;tt-t;e-ç;s-#"],
  ["caqueterie", "c-k;a-a;qu-k;e-E;t-t;e-°;r-R;i-i;e-#"],
  ["caqueteries", "c-k;a-a;qu-k;e-E;t-t;e-°;r-R;i-i;e-#;s-#"],
  ["celebret", "c-s;eleb;r-R;e-E;t"],
  ["celebrets", "c-s;eleb;r-R;e-E;t-t;s-#"],
  ["chausseterie", "ch-S;au-o;ss-s;e-E;t-t;e-°;r-R;i-i;e-#"],
  ["chausseteries", "ch-S;au-o;ss-s;e-E;t-t;e-°;r-R;i-i;e-#;s-#"],
  ["coffreterie", "c-k;o-o;ff-f;r-R;e-E;t-t;e-°;r-R;i-i;e-#"],
  ["coffreteries", "c-k;o-o;ff-f;r-R;e-E;t-t;e-°;r-R;i-i;e-#;s-#"],
  ["cricket", "c-k;r-R;i-i;ck-k;e-E;t"],
  ["crickets", "c-k;r-R;i-i;ck-k;e-E;t;s-#"],
  ["exocet", "e-E;x-X;o-o;c-s;e-E;t"],
  ["fret", "f-f;r-R;e-E;t"],
  ["gadget", "g-g;a-a;d-d;g-Z;e-E;t"],
  ["gobeleterie", "g-g;o-o;b-b;e-°;l-l;e-E;t-t;e-°;r-R;i-i;e-#"],
  ["gobeleteries", "g-g;o-o;b-b;e-°;l-l;e-E;t-t;e-°;r-R;i-i;e-#;s-#"],
  ["gruppetto", "g-g;r-R;u;pp-p;e-E;tt-t;o-o"],
  ["gruppettos", "g-g;r-R;u;pp-p;e-E;tt-t;o-o;s-#"],
  ["immettable", "im-5;m;e-E;tt-t;a-a;b-b;l-l;e-ç"],
  ["immettables", "im-5;m-m;e-E;tt-t;a-a;b-b;l-l;e-ç;s-#"],
  ["jettatore", "j-Z;e-E;tt-t;a-a;t-t;o-o;r-R;e"],
  ["jettatores", "j-Z;e-E;tt-t;a-a;t-t;o-o;r-R;e;s-#"],
  ["jettatura", "j-Z;e-E;tt-t;a-a;t-t;u;r-R;a-a"],
  ["jettaturas", "j-Z;e-E;tt-t;a-a;t-t;u;r-R;a-a;s-#"],
  ["ketchup", "k-k;e-E;t-t;ch-S;u-2;p-p"],
  ["ketchups", "k-k;e-E;t-t;ch-S;u-2;p-p;s-#"],
  ["marqueterie", "m-m;a-a;r-R;qu-k;e-E;t-t;e-°;r-R;i-i;e-#"],
  ["marqueteries", "m-m;a-a;r-R;qu-k;e-E;t-t;e-°;r-R;i-i;e-#;s-#"],
  ["mousqueterie", "m-m;ou-u;s-s;qu-k;e-E;t-t;e-°;r-R;i-i;e-#"],
  ["mousqueteries", "m-m;ou-u;s-s;qu-k;e-E;t-t;e-°;r-R;i-i;e-#;s-#"],
  ["net", "n-n;e-E;t"],
  ["netsuké", "n-n;e-E;t-t;s-s;u;k-k;é-e"],
  ["netsukés", "n-n;e-E;t-t;s-s;u;k-k;é-e;s-#"],
  ["offset", "o-o;ff-f;s-s;e-E;t"],
  ["pailleteries", "p-p;a-a;ill-j;e-E;t-t;e-°;r-R;i-i;e-#;s-#"],
  ["paneterie", "p-p;a-a;n-n;e-E;t-t;e-°;r-R;i-i;e-#"],
  ["paneteries", "p-p;a-a;n-n;e-E;t-t;e-°;r-R;i-i;e-#;s-#"],
  ["papeterie", "p-p;a-a;p-p;e-E;t-t;e-°;r-R;i-i;e-#"],
  ["papeteries", "p-p;a-a;p-p;e-E;t-t;e-°;r-R;i-i;e-#;s-#"],
  ["parqueterie", "p-p;a-a;r-R;qu-k;e-E;t-t;e-°;r-R;i-i;e-#"],
  ["parqueteries", "p-p;a-a;r-R;qu-k;e-E;t-t;e-°;r-R;i-i;e-#;s-#"],
  ["pelleterie", "p-p;e-E;ll-l;e-E;t-t;e-°;r-R;i-i;e-#"],
  ["pelleteries", "p-p;e-E;ll-l;e-E;t-t;e-°;r-R;i-i;e-#;s-#"],
  ["pickpocket", "p-p;i-i;ck-k;p-p;o-o;ck-k;e-E;t"],
  ["robinetier", "r-R;o-o;b-b;i-i;n-n;e-E;t-t;i-j;er-e"],
  ["robinetiers", "r-R;o-o;b-b;i-i;n-n;e-E;t-t;i-j;er-e;s-#"],
  ["set", "s-s;e-E;t"],
  ["socket", "s-s;o-o;ck-k;e-E;t"],
  ["soviet", "s-s;o-o;v-v;i-j;e-E;t"],
  ["teenette", "t-t;ee-i;n-n;e-E;tt-t;e-ç"],
  ["tetrasomia", "t-t;e-E;t-t;r-R;a-a;s;o-o;m-m;i-j;a-a"],
  ["trompeta", "t-t;r-R;om-§;p-p;e-E;t-t;a-a"],
  ["trompetai", "t-t;r-R;om-§;p-p;e-E;t-t;ai-e"],
  ["trompetaient", "t-t;r-R;om-§;p-p;e-E;t-t;ai-E;ent-#"],
  ["trompetais", "t-t;r-R;om-§;p-p;e-E;t-t;ai-E;s-#"],
  ["trompetait", "t-t;r-R;om-§;p-p;e-E;t-t;ai-E;t-#"],
  ["trompetâmes", "t-t;r-R;om-§;p-p;e-E;t-t;â-a;m-m;e-ç;s-#"],
  ["trompetant", "t-t;r-R;om-§;p-p;e-E;t-t;an-@;t-#"],
  ["trompetas", "t-t;r-R;om-§;p-p;e-E;t-t;a-a;s-#"],
  ["trompetasse", "t-t;r-R;om-§;p-p;e-E;t-t;a-a;ss-s;e-ç"],
  ["trompetassent", "t-t;r-R;om-§;p-p;e-E;t-t;a-a;ss-s;e-ç;nt-#"],
  ["trompetasses", "t-t;r-R;om-§;p-p;e-E;t-t;a-a;ss-s;e-ç;s-#"],
  ["trompetassiez", "t-t;r-R;om-§;p-p;e-E;t-t;a-a;ss-s;i-j;ez-e"],
  ["trompetassions", "t-t;r-R;om-§;p-p;e-E;t-t;a-a;ss-s;i-j;on-§;s-#"],
  ["trompetât", "t-t;r-R;om-§;p-p;e-E;t-t;â-a;t-#"],
  ["trompetâtes", "t-t;r-R;om-§;p-p;e-E;t-t;â-a;t-t;e-ç;s-#"],
  ["trompeté", "t-t;r-R;om-§;p-p;e-E;t-t;é-e"],
  ["trompetée", "t-t;r-R;om-§;p-p;e-E;t-t;é-e;e-#"],
  ["trompetées", "t-t;r-R;om-§;p-p;e-E;t-t;é-e;e-#;s-#"],
  ["trompeter", "t-t;r-R;om-§;p-p;e-E;t-t;er-e"],
  ["trompetèrent", "t-t;r-R;om-§;p-p;e-E;t-t;è-E;r-R;e-ç;nt-#"],
  ["trompetés", "t-t;r-R;om-§;p-p;e-E;t-t;é-e;s-#"],
  ["trompeteur", "t-t;r-R;om-§;p-p;e-E;t-t;eu-2;r-R"],
  ["trompeteurs", "t-t;r-R;om-§;p-p;e-E;t-t;eu-2;r-R;s-#"],
  ["trompetez", "t-t;r-R;om-§;p-p;e-E;t-t;ez-e"],
  ["trompetiez", "t-t;r-R;om-§;p-p;e-E;t-t;i-j;ez-e"],
  ["trompetions", "t-t;r-R;om-§;p-p;e-E;t-t;i-j;on-§;s-#"],
  ["trompetons", "t-t;r-R;om-§;p-p;e-E;t-t;on-§;s-#"],
  ["femme", "f-f;e-a;mm-m;e-ç"],
  ["femmelette", "f-f;e-a;mm-m;e-°;l-l;e-E;tt-t;e-ç"],
  ["femmelettes", "f-f;e-a;mm-m;e-°;l-l;e-E;tt-t;e-ç;s-#"],
  ["femmelin", "f-f;e-a;mm-m;e-°;l-l;in-5"],
  ["femmeline", "f-f;e-a;mm-m;e-°;l-l;i-i;n-n;e-ç"],
  ["femmelines", "f-f;e-a;mm-m;e-°;l-l;i-i;n-n;e-ç;s-#"],
  ["femmelins", "f-f;e-a;mm-m;e-°;l-l;in-5;s-#"],
  ["femmes", "f-f;e-a;mm-m;e-ç;s-#"],
  ["bookmaker", "b-b;oo-u;k-k;m-m;a-E;k-k;e-2;r-R"],
  ["bookmakers", "b-b;oo-u;k-k;m-m;a-E;k-k;e-2;r-R;s-#"],
  ["bootlegger", "b-b;oo-u;t-t;l-l;e-E;gg-g;e-2;r-R"],
  ["bootleggers", "b-b;oo-u;t-t;l-l;e-E;gg-g;e-2;r-R;s-#"],
  ["botticellien", "b-b;o-o;tt-t;i-i;c-S;e-E;ll-l;i-j;en-5"],
  ["botticelliens", "b-b;o-o;tt-t;i-i;c-S;e-E;ll-l;i-j;en-5;s-#"],
  ["botticellienne", "b-b;o-o;tt-t;i-i;c-S;e-E;ll-l;i-j;e-E;nn-n;e-ç"],
  ["botticelliennes", "b-b;o-o;tt-t;i-i;c-S;e-E;ll-l;i-j;e-E;nn-n;e-ç;s-#"],
  ["bourgmestre", "b-b;ou-u;r-R;g;m-m;e-E;s-s;t-t;r-R;e-ç"],
  ["bourgmestres", "b-b;ou-u;r-R;g;m-m;e-E;s-s;t-t;r-R;e-ç;s-#"],
  ["boxers", "b-b;o-o;x-x;e-E;r-R;s-#"],
  ["cancoillotte", "c-k;an-@;c-k;o;ill-/;o-o;tt-t;e-ç"],
  ["cancoillottes", "c-k;an-@;c-k;o;ill-/;o-o;tt-t;e-ç;s-#"],
  ["caudillo", "c-k;au;d-d;ill-/;o-o"],
  ["caudillos", "c-k;au;d-d;ill-/;o-o;s-#"],
  ["degré", "d-d;e-°;g-g;r-R;é-e"],
  ["degrés", "d-d;e-°;g-g;r-R;é-e;s-#"],
  ["brasero", "b-b;r-R;a-a;s-z;e-E;r-R;o-o"],
  ["braseros", "b-b;r-R;a-a;s-z;e-E;r-R;o-o;s-#"],
  ["break", "b-b;r-R;ea-E;k-k"],
  ["breakfast", "b-b;r-R;ea-E;k-k;f-f;a-2;s-s;t-t"],
  ["breakfasts", "b-b;r-R;ea-E;k-k;f-f;a-2;s-s;t-t;s-#"],
  ["breaks", "b-b;r-R;ea-E;k-k;s-#"],
  ["bregin", "b-b;r-R;e;g-Z;in-5"],
  ["bregins", "b-b;r-R;e;g-Z;in-5;s-#"],
  ["brenns", "b-b;r-R;en-@;n-n;s-#"],
  ["breteché", "b-b;r-R;e-°;t-t;e-°;ch-S;é-e"],
  ["dishley", "d-d;i-i;sh-S;l-l;ey-E"],
  ["dishleys", "d-d;i-i;sh-S;l-l;ey-E;s-#"],
  ["brewstérite", "b-b;r-R;e-u;w-w;s-s;t-t;é-e;r-R;i-i;t-t;e-ç"],
  ["brewstérites", "b-b;r-R;e-u;w-w;s-s;t-t;é-e;r-R;i-i;t-t;e-ç;s-#"],
  ["interview", "in-5;t-t;e-E;r-R;v-v;i-j;ew-u"],
  ["interviewa", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;a-a"],
  ["interviewage", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;a-a;g-Z;e-ç"],
  ["interviewai", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;ai-e"],
  ["interviewaient", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;ai-E;ent-#"],
  ["interviewais", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;ai-E;s-#"],
  ["interviewait", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;ai-E;t-#"],
  ["interviewâmes", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;â-a;m-m;e-ç;s-#"],
  ["interviewant", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;an-@;t-#"],
  ["interviewas", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;a-a;s-#"],
  ["interviewasse", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;a-a;ss-s;e-ç"],
  ["interviewassent", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;a-a;ss-s;e-ç;nt-#"],
  ["interviewasses", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;a-a;ss-s;e-ç;s-#"],
  ["interviewassiez", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;a-a;ss-s;i-j;ez-e"],
  [
    "interviewassions",
    "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;a-a;ss-s;i-j;on-§;s-#",
  ],
  ["interviewât", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;â-a;t-#"],
  ["interviewâtes", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;â-a;t-t;e-ç;s-#"],
  ["interviewé", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;é-e"],
  ["interviewée", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;é-e;e-#"],
  ["interviewées", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;é-e;e-#;s-#"],
  ["interviewer", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;er-e"],
  ["interviewèrent", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;è-E;r-R;e-ç;nt-#"],
  ["interviewers", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;e-2;r-R;s-#"],
  ["interviewés", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;é-e;s-#"],
  ["interviewez", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;ez-e"],
  ["interviewiez", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;i-j;ez-e"],
  ["interviewions", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;i-j;on-§;s-#"],
  ["interviewons", "in-5;t-t;e-E;r-R;v-v;i-j;e-u;w-v;on-§;s-#"],
  ["interviews", "in-5;t-t;e-E;r-R;v-v;i-j;ew-u;s-#"],
  ["newton", "n-n;e-j;w-u;t-t;on"],
  ["newtonianisme", "n-n;e-j;w-u;t-t;o-o;n-n;i-j;a-a;n-n;i-i;s-s;m-m;e-ç"],
  ["newtonianismes", "n-n;e-j;w-u;t-t;o-o;n-n;i-j;a-a;n-n;i-i;s-s;m-m;e-ç;s-#"],
  ["newtonien", "n-n;e-j;w-u;t-t;o-o;n-n;i-j;en-5"],
  ["newtonienne", "n-n;e-j;w-u;t-t;o-o;n-n;i-j;e-E;nn-n;e-ç"],
  ["newtoniennes", "n-n;e-j;w-u;t-t;o-o;n-n;i-j;e-E;nn-n;e-ç;s-#"],
  ["newtoniens", "n-n;e-j;w-u;t-t;o-o;n-n;i-j;en-5;s-#"],
  ["newtonisme", "n-n;e-j;w-u;t-t;o-o;n-n;i-i;s-s;m-m;e-ç"],
  ["newtonismes", "n-n;e-j;w-u;t-t;o-o;n-n;i-i;s-s;m-m;e-ç;s-#"],
  ["newtons", "n-n;e-j;w-u;t-t;on;s-#"],
  ["rewrita", "r-R;e-i;w-w;r-R;i-a;t-t;a-a"],
  ["rewritai", "r-R;e-i;w-w;r-R;i-a;t-t;ai-e"],
  ["rewritaient", "r-R;e-i;w-w;r-R;i-a;t-t;ai-E;ent-#"],
  ["rewritais", "r-R;e-i;w-w;r-R;i-a;t-t;ai-E;s-#"],
  ["rewritait", "r-R;e-i;w-w;r-R;i-a;t-t;ai-E;t-#"],
  ["rewritâmes", "r-R;e-i;w-w;r-R;i-a;t-t;â-a;m-m;e-ç;s-#"],
  ["rewritant", "r-R;e-i;w-w;r-R;i-a;t-t;an-@;t-#"],
  ["rewritas", "r-R;e-i;w-w;r-R;i-a;t-t;a-a;s-#"],
  ["rewritasse", "r-R;e-i;w-w;r-R;i-a;t-t;a-a;ss-s;e-ç"],
  ["rewritassent", "r-R;e-i;w-w;r-R;i-a;t-t;a-a;ss-s;e-ç;nt-#"],
  ["rewritasses", "r-R;e-i;w-w;r-R;i-a;t-t;a-a;ss-s;e-ç;s-#"],
  ["rewritassiez", "r-R;e-i;w-w;r-R;i-a;t-t;a-a;ss-s;i-j;ez-e"],
  ["rewritassions", "r-R;e-i;w-w;r-R;i-a;t-t;a-a;ss-s;i-j;on-§;s-#"],
  ["rewritât", "r-R;e-i;w-w;r-R;i-a;t-t;â-a;t-#"],
  ["rewritâtes", "r-R;e-i;w-w;r-R;i-a;t-t;â-a;t-t;e-ç;s-#"],
  ["rewrite", "r-R;e-i;w-w;r-R;i-a;t-t;e-ç"],
  ["rewrité", "r-R;e-i;w-w;r-R;i-a;t-t;é-e"],
  ["rewritée", "r-R;e-i;w-w;r-R;i-a;t-t;é-e;e-#"],
  ["rewritées", "r-R;e-i;w-w;r-R;i-a;t-t;é-e;e-#;s-#"],
  ["rewritent", "r-R;e-i;w-w;r-R;i-a;t-t;e-ç;nt-#"],
  ["rewriter", "r-R;e-i;w-w;r-R;i-a;t-t;er-e"],
  ["rewritera", "r-R;e-i;w-w;r-R;i-a;t-t;e-°;r-R;a-a"],
  ["rewriterai", "r-R;e-i;w-w;r-R;i-a;t-t;e-°;r-R;ai-e"],
  ["rewriteraient", "r-R;e-i;w-w;r-R;i-a;t-t;e-°;r-R;ai-E;ent-#"],
  ["rewriterais", "r-R;e-i;w-w;r-R;i-a;t-t;e-°;r-R;ai-E;s-#"],
  ["rewriterait", "r-R;e-i;w-w;r-R;i-a;t-t;e-°;r-R;ai-E;t-#"],
  ["rewriteras", "r-R;e-i;w-w;r-R;i-a;t-t;e-°;r-R;a-a;s-#"],
  ["rewritèrent", "r-R;e-i;w-w;r-R;i-a;t-t;è-E;r-R;e-ç;nt-#"],
  ["rewriterez", "r-R;e-i;w-w;r-R;i-a;t-t;e-°;r-R;ez-e"],
  ["rewriteriez", "r-R;e-i;w-w;r-R;i-a;t-t;e-°;r-R;i-j;ez-e"],
  ["rewriterions", "r-R;e-i;w-w;r-R;i-a;t-t;e-°;r-R;i-j;on-§;s-#"],
  ["rewriterons", "r-R;e-i;w-w;r-R;i-a;t-t;e-°;r-R;on-§;s-#"],
  ["rewriteront", "r-R;e-i;w-w;r-R;i-a;t-t;e-°;r-R;on-§;t-#"],
  ["rewriters", "r-R;e-i;w-w;r-R;i-a;t-t;er-e;s-#"],
  ["rewrites", "r-R;e-i;w-w;r-R;i-a;t-t;e-ç;s-#"],
  ["rewrités", "r-R;e-i;w-w;r-R;i-a;t-t;é-e;s-#"],
  ["rewritez", "r-R;e-i;w-w;r-R;i-a;t-t;ez-e"],
  ["rewritiez", "r-R;e-i;w-w;r-R;i-a;t-t;i-j;ez-e"],
  ["rewriting", "r-R;e-i;w-w;r-R;i-a;t-t;i-i;ng-G"],
  ["rewritings", "r-R;e-i;w-w;r-R;i-a;t-t;i-i;ng-G;s-#"],
  ["rewritions", "r-R;e-i;w-w;r-R;i-a;t-t;i-j;on-§;s-#"],
  ["rewritons", "r-R;e-i;w-w;r-R;i-a;t-t;on-§;s-#"],
  ["steward", "s-s;t-t;e-u;w-w;a-a;r-R;d-d"],
  ["stewardess", "s-s;t-t;e-u;w-w;a-a;r-R;d-d;e-E;ss-s"],
  ["stewards", "s-s;t-t;e-u;w-w;a-a;r-R;d-d;s-#"],
  ["brightique", "b-b;r-R;i-a;gh-j;t-t;i-i;qu-k;e-ç"],
  ["brightiques", "b-b;r-R;i-a;gh-j;t-t;i-i;qu-k;e-ç;s-#"],
  ["brownien", "b-b;r-R;ow-o;n-n;i-j;en-5"],
  ["brownienne", "b-b;r-R;ow-o;n-n;i-j;e-E;nn-n;e-ç"],
  ["browniennes", "b-b;r-R;ow-o;n-n;i-j;e-E;nn-n;e-ç;s-#"],
  ["browniens", "b-b;r-R;ow-o;n-n;i-j;en-5;s-#"],
  ["browning", "b-b;r-R;ow-o;n-n;i-i;ng-G"],
  ["brownings", "b-b;r-R;ow-o;n-n;i-i;ng-G;s-#"],
  ["bruccio", "b-b;r-R;u;c-t;c-S;i-j;o-o"],
  ["bruccios", "b-b;r-R;u;c-t;c-S;i-j;o-o;s-#"],
  ["brumans", "b-b;r-R;u-y;m-m;an;s-#"],
  ["bruxelles", "b-b;r-R;u-y;x-s;e-E;ll-l;e-ç;s-#"],
  ["bruxellois", "b-b;r-R;u-y;x-s;e-E;ll-l;oi-6;s-#"],
  ["bruxelloise", "b-b;r-R;u-y;x-s;e-E;ll-l;oi-6;s-z;e-ç"],
  ["bruxelloises", "b-b;r-R;u-y;x-s;e-E;ll-l;oi-6;s-z;e-ç;s-#"],
  ["buddleia", "b-b;u-y;dd-d;l-l;e-E;i-j;a-a"],
  ["buddleias", "b-b;u-y;dd-d;l-l;e-E;i-j;a-a;s-#"],
  ["building", "b-b;u-#;i-i;l-l;d-d;i-i;ng-G"],
  ["buildings", "b-b;u-#;i-i;l-l;d-d;i-i;ng-G;s-#"],
  ["bungalow", "b-b;un-1;g-g;a-a;l-l;o;w-#"],
  ["bungalows", "b-b;un-1;g-g;a-a;l-l;o;ws-#"],
  ["bunker", "b-b;u-2;n;k-k;e-2;r-R"],
  ["bunkers", "b-b;u-2;n;k-k;e-2;r-R;s-#"],
  ["burg", "b-b;u;r-R;g-g"],
  ["burgs", "b-b;u;r-R;g-g;s-#"],
  ["burnous", "b-b;u-y;r-R;n-n;ou-u;s-#"],
  ["business", "b-b;u-i;s-z;i-#;n-n;e-E;ss-s"],
  ["businessmans", "b-b;u-i;s-z;i-#;n-n;e-E;ss-s;m-m;an;s-#"],
  ["byronien", "b-b;y-a;r-R;o-o;n-n;i-j;en-5"],
  ["byronienne", "b-b;y-a;r-R;o-o;n-n;i-j;e-E;nn-n;e-ç"],
  ["byroniennes", "b-b;y-a;r-R;o-o;n-n;i-j;e-E;nn-n;e-ç;s-#"],
  ["byroniens", "b-b;y-a;r-R;o-o;n-n;i-j;en-5;s-#"],
  ["caballero", "c-k;a-a;b-b;a-a;ll-l;e-E;r-R;o-o"],
  ["caballeros", "c-k;a-a;b-b;a-a;ll-l;e-E;r-R;o-o;s-#"],
  ["cabecilla", "c-k;a-a;b-b;e;c-s;i-i;ll-l;a-a"],
  ["cabecillas", "c-k;a-a;b-b;e;c-s;i-i;ll-l;a-a;s-#"],
  ["cacahuète", "c-k;a-a;c-k;a-a;h-#;u-w;è-E;t-t;e-ç"],
  ["cacahuètes", "c-k;a-a;c-k;a-a;h-#;u-w;è-E;t-t;e-ç;s-#"],
  ["cachucha", "c-k;a-a;ch-S;u;ch-S;a-a"],
  ["cachuchas", "c-k;a-a;ch-S;u;ch-S;a-a;s-#"],
  ["cafedji", "c-k;a-a;f-f;e-°;d-d;j-Z;i-i"],
  ["cafedjis", "c-k;a-a;f-f;e-°;d-d;j-Z;i-i;s-#"],
  ["cake", "c-k;a-E;k-k;e-ç"],
  ["cakes", "c-k;a-E;k-k;e-ç;s-#"],
  ["caldera", "c-k;a-a;l-l;d-d;e-E;r-R;a-a"],
  ["calderas", "c-k;a-a;l-l;d-d;e-E;r-R;a-a;s-#"],
  ["calen", "c-k;a-a;l-l;e-E;n"],
  ["calens", "c-k;a-a;l-l;e-E;n;s-#"],
  ["calender", "c-k;a-a;l-l;e-a;n;d-d;e-E;r-R"],
  ["calenders", "c-k;a-a;l-l;e-a;n;d-d;e-E;r-R;s-#"],
  ["kilowatt", "k-k;i-i;l-l;ow;a-a;tt-t"],
  ["kilowatts", "k-k;i-i;l-l;ow;a-a;tt-t;s-#"],
  ["monowatt", "m-m;o-o;n-n;ow;a-a;tt-t"],
  ["ouwarowite", "ou-u;w-v;a-a;r-R;o;w-v;i-i;t-t;e-ç"],
  ["ouwarowites", "ou-u;w-v;a-a;r-R;o;w-v;i-i;t-t;e-ç;s-#"],
  ["paulownia", "p-p;au-o;l-l;o;w-#;n-n;i-j;a-a"],
  ["paulownias", "p-p;au-o;l-l;o;w-#;n-n;i-j;a-a;s-#"],
  ["perowskite", "p-p;e-°;r-R;ow;s-s;k-k;i-i;t-t;e-ç"],
  ["perowskites", "p-p;e-°;r-R;ow;s-s;k-k;i-i;t-t;e-ç;s-#"],
  ["redowa", "r-R;e;d-d;o;w-v;a-a"],
  ["redowas", "r-R;e;d-d;o;w-v;a-a;s-#"],
  ["rowing", "r-R;o;w-#;i-i;ng-G"],
  ["rowings", "r-R;o;w-#;i-i;ng-G;s-#"],
  ["sandow", "s-s;an-@;d-d;o;w-#"],
  ["sandows", "s-s;an-@;d-d;o;w-#;s-#"],
  ["show", "sh-S;o;w-#"],
  ["shows", "sh-S;o;w-#;s-#"],
  ["slow", "s-s;l-l;o;w-#"],
  ["slows", "s-s;l-l;o;w-#;s-#"],
  ["sloup", "s-s;l-l;ou-u;p"],
  ["sloups", "s-s;l-l;ou-u;p;s-#"],
  ["sept", "s-s;e-E;p-#;t-t"],
  ["septs", "s-s;e-E;p-#;t-t;s-#"],
  ["tramp", "t-t;r-R;am-@;p"],
  ["tramps", "t-t;r-R;am-@;p-p;s-#"],
  ["vamp", "v-v;am-@;p"],
  ["vamps", "v-v;am-@;p-p;s-#"],
  ["calesino", "c-k;a-a;l-l;e-°;s;i-i;n-n;o-o"],
  ["calesinos", "c-k;a-a;l-l;e-°;s;i-i;n-n;o-o;s-#"],
  ["cantabile", "c-k;an-@;t-t;a-a;b-b;i-i;l-l;e"],
  ["cantabiles", "c-k;an-@;t-t;a-a;b-b;i-i;l-l;e;s-#"],
  ["canyon", "c-k;a-a;n-n;y-j;on"],
  ["canyons", "c-k;a-a;n-n;y-j;on;s-#"],
  ["canzona", "c-k;an;z-s;o-o;n-n;a-a"],
  ["canzonas", "c-k;an;z-s;o-o;n-n;a-a;s-#"],
  ["canzone", "c-k;an;z-s;o-o;n-n;e"],
  ["canzones", "c-k;an;z-s;o-o;n-n;e;s-#"],
  ["capharnaüm", "c-k;a-a;ph-f;a-a;r-R;n-n;a-a;ü-o;m-m"],
  ["capharnaüms", "c-k;a-a;ph-f;a-a;r-R;n-n;a-a;ü-o;m-m; s-#"],
  ["capischol", "c-k;a-a;p-p;i-i;s;ch-k;o-o;l-l"],
  ["capischols", "c-k;a-a;p-p;i-i;s;ch-k;o-o;l-l;s-#"],
  ["capriccio", "c-k;a-a;p-p;r-R;i-i;c-t;c-S;i-j;o-o"],
  ["capriccios", "c-k;a-a;p-p;r-R;i-i;c-t;c-S;i-j;o-o;s-#"],
  ["cawas", "c-k;a-a;w-w;a-a;s-s"],
  ["cawcher", "c-k;a-a;w-#;ch-S;e-E;r-R"],
  ["cawchère", "c-k;a-a;w-#;ch-S;è-E;r-R;e-ç"],
  ["cawchères", "c-k;a-a;w-#;ch-S;è-E;r-R;e-ç;s-#"],
  ["cawchers", "c-k;a-a;w-#;ch-S;e-E;r-R;s-#"],
  ["cayenne", "c-k;a;y-j;e-E;nn-n;e-ç"],
  ["cayennes", "c-k;a;y-j;e-E;nn-n;e-ç;s-#"],
  ["cayeu", "c-k;a;y-j;eu-2"],
  ["cayeux", "c-k;a;y-j;eu-2;x-#"],
  ["complétions", "c-k;om-§;p-p;l-l;é-e;t;i-j;on-§;s-#"],
  ["daya", "d-d;a;y-j;a-a"],
  ["dayas", "d-d;a;y-j;a-a;s-#"],
  ["fedayin", "f-f;e;d-d;a;y-j;in"],
  ["fedayins", "f-f;e;d-d;a;y-j;in;s-#"],
  ["fidayin", "f-f;i-i;d-d;a;y-j;in"],
  ["fidayins", "f-f;i-i;d-d;a;y-j;in;s-#"],
  ["maye", "m-m;a-E;y-j;e-ç"],
  ["mayes", "m-m;a-E;y-j;e-ç;s-#"],
  ["papaye", "p-p;a-a;p-p;a-a;y-j;e-ç"],
  ["papayer", "p-p;a-a;p-p;a-a;y-j;er-e"],
  ["papayers", "p-p;a-a;p-p;a-a;y-j;er-e;s-#"],
  ["papayes", "p-p;a-a;p-p;a-a;y-j;e-ç;s-#"],
  ["sabayon", "s-s;a-a;b-b;a-a;y-j;on-§"],
  ["sabayons", "s-s;a-a;b-b;a-a;y-j;on-§;s-#"],
  ["saynète", "s-s;ay-E;n-n;è-E;t-t;e-ç"],
  ["saynètes", "s-s;ay-E;n-n;è-E;t-t;e-ç;s-#"],
  ["stayers", "s-s;t-t;a-E;y-j;e-E;r-R;s-#"],
  ["tokay", "t-t;o-o;k-k;a;y-j"],
  ["tokays", "t-t;o-o;k-k;a;y-j;s-#"],
  ["cécidomyie", "c-s;é-e;c-s;i-i;d-d;o-o;m-m;y-#;i-i;e-#"],
  ["cécidomyies", "c-s;é-e;c-s;i-i;d-d;o-o;m-m;y-#;i-i;e-#;s-#"],
  //[ "centumvir", "c-s;en-@;t-t;u-o;m-m;v-v;i-i;r-R" ],
  //[ "centumvirs", "c-s;en-@;t-t;u-o;m-m;v-v;i-i;r-R;s-#" ],
  //[ "centumvirat", "c-s;en-@;t-t;u-o;m-m;v-v;i-i;r-R;a-a;t-#" ],
  //[ "centumvirats", "c-s;en-@;t-t;u-o;m-m;v-v;i-i;r-R;a-a;t-#;s-#" ],
  ["cep", "c-s;e-E;p-p"],
  ["cers", "c-s;e-E;r-R;s-s"],
  ["chadburn", "ch-S;a-a;d-d;b-b;u-2;r-R;n-n"],
  ["chadburns", "ch-S;a-a;d-d;b-b;u-2;r-R;n-n;s-#"],
  ["challenger", "ch-S;a-a;ll-l;en;g-Z;e-2;r-R"],
  ["challengers", "ch-S;a-a;ll-l;en;g-Z;e-2;r-R;s-#"],
  ["chamans", "ch-S;aman;s-#"],
  ["chantepleure", "ch-S;an-@;t-t;e-°;p-p;l-l;eu-2;r-R;e-ç"],
  ["chantepleures", "ch-S;an-@;t-t;e-°;p-p;l-l;eu-2;r-R;e-ç;s-#"],
  ["chantoung", "ch-S;an-@;t-t;ou-u;ng-G"],
  ["chantoungs", "ch-S;an-@;t-t;ou-u;ng-G;s-#"],
  ["charleston", "ch-S;a-a;r-R;l-l;e-E;s-s;t-t;on"],
  ["charlestons", "ch-S;a-a;r-R;l-l;e-E;s-s;t-t;on;s-#"],
  ["chatterton", "ch-S;a-a;tt-t;e-E;r-R;t-t;on"],
  ["chattertons", "ch-S;a-a;tt-t;e-E;r-R;t-t;on;s-#"],
  ["chebec", "ch-S;e;b-b;e-E;c-k"],
  ["chebecs", "ch-S;e;b-b;e-E;c-k;s-#"],
  ["chebek", "ch-S;e;b-b;e-E;k-k"],
  ["chebeks", "ch-S;e;b-b;e-E;k-k;s-#"],
  ["cheiline", "ch-S;ei-E;l-l;i-i;n-n;e-ç"],
  ["cheilines", "ch-S;ei-E;l-l;i-i;n-n;e-ç;s-#"],
  ["cheilodactylus", "ch-S;ei-E;l;o-o;d-d;a-a;c-k;t-t;y-i;l-l;u-y;s"],
  ["longtemps", "l-l;on-§;g-#;t-t;em-@;p-#;s-#"],
  ["chesnaie", "ch-S;e-E;s-#;n-n;ai-e;e-#"],
  ["chesnaies", "ch-S;e-E;s-#;n-n;ai-e;e-#;s-#"],
  ["chétognathes", "ch-k;é-e;t-t;o-o;gn-N;a-a;t-t;h-#;e-ç;s-#"],
  ["chétognathe", "ch-k;é-e;t-t;o-o;gn-N;a-a;t-t;h-#;e-ç"],
  ["chevesne", "ch-S;e-°;v-v;e-E;s-#;n-n;e-ç"],
  ["chevesnes", "ch-S;e-°;v-v;e-E;s-#;n-n;e-ç;s-#"],
  ["chienlit", "ch-S;i-j;en-@;l-l;i-i;t-#"],
  ["chienlits", "ch-S;i-j;en-@;l-l;i-i;t-#;s-#"],
  ["chintz", "ch-S;in;t-t;z-s"],
  ["chistera", "ch-S;i-i;s-s;t-t;e;r-R;a-a"],
  ["chisteras", "ch-S;i-i;s-s;t-t;e;r-R;a-a;s-#"],
  ["christmas", "ch-k;r-R;i-i;s-s;t-#;m-m;a-a;s-s"],
  ["churrigueresque", "ch-S;u;rr-R;i-i;gu-g;e;r-R;e-E;s-s;qu-k;e-ç"],
  ["churrigueresques", "ch-S;u;rr-R;i-i;gu-g;e;r-R;e-E;s-s;qu-k;e-ç;s-#"],
  ["ciao", "c-S;i-#;a-a;o-w"],
  ["cicéros", "c-s;i-i;c-s;é-e;r-R;o-o;s"],
  ["claquedent", "c-k;l-l;a-a;qu-k;e-°;d-d;en-@;t-#"],
  ["confident", "c-k;on-§;f-f;i-i;d-d;en-@;t-#"],
  ["évident", "é-e;v-v;i-i;d-d;en-@;t-#"],
  ["exhérèdent", "e-E;x-X;h-#;é-e;r-R;è-E;d-d;e-ç;nt-#"],
  ["jurisprudent", "j-Z;u-y;r-R;i-i;s-s;p-p;r-R;u-y;d-d;en-@;t-#"],
  ["président", "p-p;r-R;é-e;s-z;i-i;d-d;en-@;t-#"],
  ["provident", "p-p;r-R;o-o;v-v;i-i;d-d;en-@;t-#"],
  ["rectident", "r-R;e-E;c-k;t-t;i-i;d-d;en-@;t-#"],
  ["redent", "r-R;e-°;d-d;en-@;t-#"],
  ["clearing", "c-k;l-l;ea-i;r-R;i-i;ng-G"],
  ["clearings", "c-k;l-l;ea-i;r-R;i-i;ng-G;s-#"],
  ["clipper", "c-k;l-l;i-i;pp-p;e-2;r-R"],
  ["clippers", "c-k;l-l;i-i;pp-p;e-2;r-R;s-#"],
  ["club", "c-k;l-l;u-2;b-b"],
  ["clubs", "c-k;l-l;u-2;b-b;s-#"],
  ["clubiste", "c-k;l-l;u-2;b-b;i-i;s-s;t-t;e-ç"],
  ["clubistes", "c-k;l-l;u-2;b-b;i-i;s-s;t-t;e-ç;s-#"],
  ["clubmans", "c-k;l-l;u-2;b-b;m-m;an;s-#"],
  ["coccyx", "c-k;o-o;c-k;c-s;y-i;x-s"],
  ["coachman", "c-k;o-o;a-#;ch-S;m-m;an"],
  ["coachmans", "c-k;o-o;a-#;ch-S;m-m;an;s-#"],
  ["compound", "c-k;om-§;p-p;ou-u;n-n;d"],
  ["compounds", "c-k;om-§;p-p;ou-u;n-n;d;s-#"],
  ["condottiere", "c-k;on-§;d-d;o-o;tt-t;i-j;e-E;r-R;e-ç"],
  ["condottieres", "c-k;on-§;d-d;o-o;tt-t;i-j;e-E;r-R;e-ç;s-#"],
  ["confiteor", "c-k;on-§;f-f;i-i;t-t;e;o-o;r-R"],
  ["confiteors", "c-k;on-§;f-f;i-i;t-t;e;o-o;r-R;s-#"],
  ["coleta", "c-k;o-o;l-l;e;t-t;a-a"],
  ["coletas", "c-k;o-o;l-l;e;t-t;a-a;s-#"],
  ["conquiers", "c-k;on-§;qu-k;i-j;e-E;r-R;s-#"],
  ["rectiuscule", "r-R;e-E;c-k;t;i-j;u-y;s-s;c-k;u-y;l-l;e-ç"],
  ["rectiuscules", "r-R;e-E;c-k;t;i-j;u-y;s-s;c-k;u-y;l-l;e-ç;s-#"],
  ["contiguïté", "c-k;on-§;t-t;i-i;g-g;u-y;ï-i;t-t;é-e"],
  ["contiguïtés", "c-k;on-§;t-t;i-i;g-g;u-y;ï-i;t-t;é-e;s-#"],
  ["exiguïté", "e-E;x-X;i-i;g-g;u-y;ï-i;t-t;é-e"],
  ["exiguïtés", "e-E;x-X;i-i;g-g;u-y;ï-i;t-t;é-e;s-#"],
  ["maïzena", "m-m;a-a;ï-i;z-z;e;n-n;a-a"],
  ["maïzenas", "m-m;a-a;ï-i;z-z;e;n-n;a-a;s-#"],
  ["reïs", "r-R;e-E;ï-i;s-s"],
  ["shintoïsme", "sh-S;in;t-t;o-o;ï-i;s-s;m-m;e-ç"],
  ["shintoïsmes", "sh-S;in;t-t;o-o;ï-i;s-s;m-m;e-ç;s-#"],
  ["shintoïste", "sh-S;in;t-t;o-o;ï-i;s-s;t-t;e-ç"],
  ["shintoïstes", "sh-S;in;t-t;o-o;ï-i;s-s;t-t;e-ç;s-#"],
  ["supporters", "s-s;u-y;pp-p;o-o;r-R;t-t;e-E;r-R;s-#"],
  ["reporters", "r-R;e-°;p-p;o-o;r-R;t-t;e-E;r-R;s-#"],
  ["herschions", "h-#;e-E;r-R;sch-S;i-j;on-§;s-#"],
  ["orthose", "o-o;r-R;t-t;h-#;o-o;s-z;e-ç"],
  ["orthoses", "o-o;r-R;t-t;h-#;o-o;s-z;e-ç;s-#"],
  ["copyright", "c-k;o-o;p-p;y-i;r-R;i-a;g-j;h-#;t-t"],
  ["copyrights", "c-k;o-o;p-p;y-i;r-R;i-a;g-j;h-#;t-t;s-#"],
  ["coroner", "c-k;o-o;r-R;o-o;n-n;e-2;r-R"],
  ["coroners", "c-k;o-o;r-R;o-o;n-n;e-2;r-R;s-#"],
  ["corregidor", "c-k;o-o;rr-R;e-E;g-Z;i-i;d-d;o-o;r-R"],
  ["corregidors", "c-k;o-o;rr-R;e-E;g-Z;i-i;d-d;o-o;r-R;s-#"],
  ["cortile", "c-k;o-o;r-R;t-t;i-i;l-l;e"],
  ["cortiles", "c-k;o-o;r-R;t-t;i-i;l-l;e;s-#"],
  ["cosy", "c-k;o-o;s-z;y-i"],
  ["cosys", "c-k;o-o;s-z;y-i;s-#"],
  ["cottage", "c-k;o-o;tt-t;a-E;g-Z;e-ç"],
  ["cottages", "c-k;o-o;tt-t;a-E;g-Z;e-ç;s-#"],
  ["coufieh", "c-k;ou-u;f-f;i-j;e;h-#"],
  ["covercoat", "c-k;o-o;v-v;e-E;r-R;c-k;o-o;a-#;t-t"],
  ["covercoats", "c-k;o-o;v-v;e-E;r-R;c-k;o-o;a-#;t-t;s-#"],
  ["craniotabes", "c-k;r-R;a-a;n-n;i-j;o-o;t-t;a-a;b-b;e-E;s-s"],
  ["crawl", "c-k;r-R;a-o;w-#;l-l"],
  ["crawla", "c-k;r-R;a-o;w-#;l-l;a-a"],
  ["crawlai", "c-k;r-R;a-o;w-#;l-l;ai-e"],
  ["crawlaient", "c-k;r-R;a-o;w-#;l-l;ai-E;ent-#"],
  ["crawlais", "c-k;r-R;a-o;w-#;l-l;ai-E;s-#"],
  ["crawlait", "c-k;r-R;a-o;w-#;l-l;ai-E;t-#"],
  ["crawlâmes", "c-k;r-R;a-o;w-#;l-l;â-a;m-m;e-ç;s-#"],
  ["crawlant", "c-k;r-R;a-o;w-#;l-l;an-@;t-#"],
  ["crawlas", "c-k;r-R;a-o;w-#;l-l;a-a;s-#"],
  ["crawlasse", "c-k;r-R;a-o;w-#;l-l;a-a;ss-s;e-ç"],
  ["crawlassent", "c-k;r-R;a-o;w-#;l-l;a-a;ss-s;e-ç;nt-#"],
  ["crawlasses", "c-k;r-R;a-o;w-#;l-l;a-a;ss-s;e-ç;s-#"],
  ["crawlassiez", "c-k;r-R;a-o;w-#;l-l;a-a;ss-s;i-j;ez-e"],
  ["crawlassions", "c-k;r-R;a-o;w-#;l-l;a-a;ss-s;i-j;on-§;s-#"],
  ["crawlât", "c-k;r-R;a-o;w-#;l-l;â-a;t-#"],
  ["crawlâtes", "c-k;r-R;a-o;w-#;l-l;â-a;t-t;e-ç;s-#"],
  ["crawle", "c-k;r-R;a-o;w-#;l-l;e-ç"],
  ["crawlé", "c-k;r-R;a-o;w-#;l-l;é-e"],
  ["crawlée", "c-k;r-R;a-o;w-#;l-l;é-e;e-#"],
  ["crawlées", "c-k;r-R;a-o;w-#;l-l;é-e;e-#;s-#"],
  ["crawlent", "c-k;r-R;a-o;w-#;l-l;e-ç;nt-#"],
  ["crawler", "c-k;r-R;a-o;w-#;l-l;er-e"],
  ["crawlera", "c-k;r-R;a-o;w-#;l-l;e-°;r-R;a-a"],
  ["crawlerai", "c-k;r-R;a-o;w-#;l-l;e-°;r-R;ai-e"],
  ["crawleraient", "c-k;r-R;a-o;w-#;l-l;e-°;r-R;ai-E;ent-#"],
  ["crawlerais", "c-k;r-R;a-o;w-#;l-l;e-°;r-R;ai-E;s-#"],
  ["crawlerait", "c-k;r-R;a-o;w-#;l-l;e-°;r-R;ai-E;t-#"],
  ["crawleras", "c-k;r-R;a-o;w-#;l-l;e-°;r-R;a-a;s-#"],
  ["crawlèrent", "c-k;r-R;a-o;w-#;l-l;è-E;r-R;e-ç;nt-#"],
  ["crawlerez", "c-k;r-R;a-o;w-#;l-l;e-°;r-R;ez-e"],
  ["crawleriez", "c-k;r-R;a-o;w-#;l-l;e-°;r-R;i-j;ez-e"],
  ["crawlerions", "c-k;r-R;a-o;w-#;l-l;e-°;r-R;i-j;on-§;s-#"],
  ["crawlerons", "c-k;r-R;a-o;w-#;l-l;e-°;r-R;on-§;s-#"],
  ["crawleront", "c-k;r-R;a-o;w-#;l-l;e-°;r-R;on-§;t-#"],
  ["crawles", "c-k;r-R;a-o;w-#;l-l;e-ç;s-#"],
  ["crawlés", "c-k;r-R;a-o;w-#;l-l;é-e;s-#"],
  ["crawleur", "c-k;r-R;a-o;w-#;l-l;eu-2;r-R"],
  ["crawleurs", "c-k;r-R;a-o;w-#;l-l;eu-2;r-R;s-#"],
  ["crawleuse", "c-k;r-R;a-o;w-#;l-l;eu-2;s-z;e-ç"],
  ["crawleuses", "c-k;r-R;a-o;w-#;l-l;eu-2;s-z;e-ç;s-#"],
  ["crawlez", "c-k;r-R;a-o;w-#;l-l;ez-e"],
  ["crawliez", "c-k;r-R;a-o;w-#;l-l;i-j;ez-e"],
  ["crawlions", "c-k;r-R;a-o;w-#;l-l;i-j;on-§;s-#"],
  ["crawlons", "c-k;r-R;a-o;w-#;l-l;on-§;s-#"],
  ["crawls", "c-k;r-R;a-o;w-#;l-l;s-#"],
  ["credo", "c-k;r-R;e;d-d;o-o"],
  ["credos", "c-k;r-R;e;d-d;o-o;s-#"],
  ["crescendo", "c-k;r-R;e-E;sc-S;e-E;n;d-d;o-o"],
  ["crescendos", "c-k;r-R;e-E;sc-S;e-E;n;d-d;o-o;s-#"],
  ["aujourd", "au-o;j-Z;ou-u;r-R;d-d"],
  ["landtag", "l-l;an-@;d;t-t;a-a;g-g"],
  ["landtags", "l-l;an-@;d;t-t;a-a;g-g;s-#"],
  ["crosne", "c-k;r-R;o-o;s-#;n-n;e-ç"],
  ["crosnes", "c-k;r-R;o-o;s-#;n-n;e-ç;s-#"],
  ["crucifix", "c-k;r-R;u-y;c-s;i-i;f-f;i-i;x-#"],
  ["cryptomeria", "c-k;r-R;y-i;p-p;t-t;o-o;m-m;e;r-R;i-j;a-a"],
  ["cryptomerias", "c-k;r-R;y-i;p-p;t-t;o-o;m-m;e;r-R;i-j;a-a;s-#"],
  ["csardas", "c-g;s-z;a-a;r-R;d-d;a-a;s-s"],
  ["curiethérapie", "c-k;u-y;r-R;i;e-#;t-t;h-#;é-e;r-R;a-a;p-p;i-i;e-#"],
  ["curiethérapies", "c-k;u-y;r-R;i;e-#;t-t;h-#;é-e;r-R;a-a;p-p;i-i;e-#;s-#"],
  ["cutter", "c-k;u-2;tt-t;e-2;r-R"],
  ["cutters", "c-k;u-2;tt-t;e-2;r-R;s-#"],
  ["cymrique", "c-k;y-i;m-m;r-R;i-i;qu-k;e-ç"],
  ["cymriques", "c-k;y-i;m-m;r-R;i-i;qu-k;e-ç;s-#"],
  ["cypripedium", "c-s;y-i;p-p;r-R;i-i;p-p;e;d-d;i-j;u-o;m-m"],
  ["cypripediums", "c-s;y-i;p-p;r-R;i-i;p-p;e;d-d;i-j;u-o;m-m;s-#"],
  ["czardas", "c-g;z-z;a-a;r-R;d-d;a-a;s"],
  ["czarien", "c-t;z-s;a-a;r-R;i-j;en-5"],
  ["czariens", "c-t;z-s;a-a;r-R;i-j;en-5;s-#"],
  ["czarienne", "c-t;z-s;a-a;r-R;i-j;e-E;nn-n;e-ç"],
  ["czariennes", "c-t;z-s;a-a;r-R;i-j;e-E;nn-n;e-ç;s-#"],
  ["czarisme", "c-t;z-s;a-a;r-R;i-i;s-s;m-m;e-ç"],
  ["czarismes", "c-t;z-s;a-a;r-R;i-i;s-s;m-m;e-ç;s-#"],
  ["czariste", "c-t;z-s;a-a;r-R;i-i;s-s;t-t;e-ç"],
  ["czaristes", "c-t;z-s;a-a;r-R;i-i;s-s;t-t;e-ç;s-#"],
  ["czar", "c-t;z-s;a-a;r-R"],
  ["czars", "c-t;z-s;a-a;r-R;s-#"],
  ["dahabieh", "d-d;a-a;h-#;a-a;b-b;i-j;e-E;h-#"],
  ["décemvir", "d-d;é-e;c-s;e-E;m;v-v;i-i;r-R"],
  ["décemviral", "d-d;é-e;c-s;e-E;m;v-v;i-i;r-R;a-a;l-l"],
  ["décemvirale", "d-d;é-e;c-s;e-E;m;v-v;i-i;r-R;a-a;l-l;e-ç"],
  ["décemvirales", "d-d;é-e;c-s;e-E;m;v-v;i-i;r-R;a-a;l-l;e-ç;s-#"],
  ["décemvirat", "d-d;é-e;c-s;e-E;m;v-v;i-i;r-R;a-a;t-#"],
  ["décemvirats", "d-d;é-e;c-s;e-E;m;v-v;i-i;r-R;a-a;t-#;s-#"],
  ["décemviraux", "d-d;é-e;c-s;e-E;m;v-v;i-i;r-R;au-o;x-#"],
  ["décemvirs", "d-d;é-e;c-s;e-E;m;v-v;i-i;r-R;s-#"],
  ["quindécemvir", "q-k;u-y;in-5;d-d;é-e;c-s;e-E;m;v-v;i-i;r-R"],
  ["quindécemvirs", "q-k;u-y;in-5;d-d;é-e;c-s;e-E;m;v-v;i-i;r-R;s-#"],
  ["quindécimvir", "q-k;u-y;in-5;d-d;é-e;c-s;i;m;v-v;i-i;r-R"],
  ["quindécimvirs", "q-k;u-y;in-5;d-d;é-e;c-s;i;m;v-v;i-i;r-R;s-#"],
  ["decrescendo", "d-d;e;c-k;r-R;e-E;sc-S;e-E;n-n;d-d;o-o"],
  ["decrescendos", "d-d;e;c-k;r-R;e-E;sc-S;e-E;n-n;d-d;o-o;s-#"],
  ["deleatur", "d-d;e-e;l-l;e-e;a-a;t-t;u-y;r-R"],
  ["deleaturs", "d-d;e-e;l-l;e-e;a-a;t-t;u-y;r-R;s-#"],
  ["delirium", "d-d;e-e;l-l;i-i;r-R;i-j;u-o;m-m"],
  ["deliriums", "d-d;e-e;l-l;i-i;r-R;i-j;u-o;m-m;s-#"],
  ["démixtion", "d-d;é-e;m-m;i-i;x-x;t-t;i-j;on-§"],
  ["demodex", "d-d;e-e;m-m;o-o;d-d;e-E;x-x"],
  ["décomplétions", "d-d;é-e;c-k;om-§;p-p;l-l;é-e;t-t;i-j;on-§;s-#"],
  ["décrétions", "d-d;é-e;c-k;r-R;é-e;t-t;i-j;on-§;s-#"],
  ["ennemi", "e-E;nn-n;e-°;m-m;i-i"],
  ["ennemies", "e-E;nn-n;e-°;m-m;i-i;e-#;s-#"],
  ["ennemis", "e-E;nn-n;e-°;m-m;i-i;s-#"],
  ["ennemie", "e-E;nn-n;e-°;m-m;i-i;e-#"],
  ["desiderata", "d-d;e-e;s-z;i-i;d-d;e-e;r-R;a-a;t-t;a-a"],
  ["encoigna", "en-@;c-k;o-o;i-#;gn-N;a-a"],
  ["encoignai", "en-@;c-k;o-o;i-#;gn-N;ai-e"],
  ["encoignaient", "en-@;c-k;o-o;i-#;gn-N;ai-E;ent-#"],
  ["encoignais", "en-@;c-k;o-o;i-#;gn-N;ai-E;s-#"],
  ["encoignait", "en-@;c-k;o-o;i-#;gn-N;ai-E;t-#"],
  ["encoignâmes", "en-@;c-k;o-o;i-#;gn-N;â-a;m-m;e-ç;s-#"],
  ["encoignant", "en-@;c-k;o-o;i-#;gn-N;an-@;t-#"],
  ["encoignas", "en-@;c-k;o-o;i-#;gn-N;a-a;s-#"],
  ["encoignasse", "en-@;c-k;o-o;i-#;gn-N;a-a;ss-s;e-ç"],
  ["encoignassent", "en-@;c-k;o-o;i-#;gn-N;a-a;ss-s;e-ç;nt-#"],
  ["encoignasses", "en-@;c-k;o-o;i-#;gn-N;a-a;ss-s;e-ç;s-#"],
  ["encoignassiez", "en-@;c-k;o-o;i-#;gn-N;a-a;ss-s;i-j;ez-e"],
  ["encoignassions", "en-@;c-k;o-o;i-#;gn-N;a-a;ss-s;i-j;on-§;s-#"],
  ["encoignât", "en-@;c-k;o-o;i-#;gn-N;â-a;t-#"],
  ["encoignâtes", "en-@;c-k;o-o;i-#;gn-N;â-a;t-t;e-ç;s-#"],
  ["encoigne", "en-@;c-k;o-o;i-#;gn-N;e-ç"],
  ["encoigné", "en-@;c-k;o-o;i-#;gn-N;é-e"],
  ["encoignée", "en-@;c-k;o-o;i-#;gn-N;é-e;e-#"],
  ["encoignées", "en-@;c-k;o-o;i-#;gn-N;é-e;e-#;s-#"],
  ["encoignent", "en-@;c-k;o-o;i-#;gn-N;e-ç;nt-#"],
  ["encoignera", "en-@;c-k;o-o;i-#;gn-N;e-°;r-R;a-a"],
  ["encoignerai", "en-@;c-k;o-o;i-#;gn-N;e-°;r-R;ai-e"],
  ["encoigneraient", "en-@;c-k;o-o;i-#;gn-N;e-°;r-R;ai-E;ent-#"],
  ["encoignerais", "en-@;c-k;o-o;i-#;gn-N;e-°;r-R;ai-E;s-#"],
  ["encoignerait", "en-@;c-k;o-o;i-#;gn-N;e-°;r-R;ai-E;t-#"],
  ["encoigneras", "en-@;c-k;o-o;i-#;gn-N;e-°;r-R;a-a;s-#"],
  ["encoignèrent", "en-@;c-k;o-o;i-#;gn-N;è-E;r-R;e-ç;nt-#"],
  ["encoignerez", "en-@;c-k;o-o;i-#;gn-N;e-°;r-R;ez-e"],
  ["encoigneriez", "en-@;c-k;o-o;i-#;gn-N;e-°;r-R;i-j;ez-e"],
  ["encoignerions", "en-@;c-k;o-o;i-#;gn-N;e-°;r-R;i-j;on-§;s-#"],
  ["encoignerons", "en-@;c-k;o-o;i-#;gn-N;e-°;r-R;on-§;s-#"],
  ["encoigneront", "en-@;c-k;o-o;i-#;gn-N;e-°;r-R;on-§;t-#"],
  ["encoignes", "en-@;c-k;o-o;i-#;gn-N;e-ç;s-#"],
  ["encoignés", "en-@;c-k;o-o;i-#;gn-N;é-e;s-#"],
  ["encoignez", "en-@;c-k;o-o;i-#;gn-N;ez-e"],
  ["encoignions", "en-@;c-k;o-o;i-#;gn-N;i-j;on-§;s-#"],
  ["encoignons", "en-@;c-k;o-o;i-#;gn-N;on-§;s-#"],
  ["cosaque", "c-k;o-o;s-z;a-a;qu-k;e-ç"],
  ["cosaques", "c-k;o-o;s-z;a-a;qu-k;e-ç;s-#"],
  ["cryptosepalum", "c-k;r-R;y-i;p-p;t-t;o-o;s-s;e-e;p-p;a-a;l-l;u-o;m-m"],
  ["cuprose", "c-k;u-y;p-p;r-R;o-o;s-z;e-ç"],
  ["desquels", "d-d;es-e;qu-k;e-E;l-l;s-#"],
  ["desquelles", "d-d;es-e;qu-k;e-E;ll-l;e-ç;s-#"],
  ["lesquels", "l-l;es-e;qu-k;e-E;l-l;s-#"],
  ["lesquelles", "l-l;es-e;qu-k;e-E;ll-l;e-ç;s-#"],
  ["deuxième", "d-d;eu-2;x-z;i-j;è-E;m-m;e-ç"],
  ["deuxièmes", "d-d;eu-2;x-z;i-j;è-E;m-m;e-ç;s-#"],
  ["deuxièmement", "d-d;eu-2;x-z;i-j;è-E;m-m;e-°;m-m;en-@;t-#"],
  ["diencéphale", "d-d;i-i;en-@;c-s;é-e;ph-f;a-a;l-l;e-ç"],
  ["diencéphales", "d-d;i-i;en-@;c-s;é-e;ph-f;a-a;l-l;e-ç;s-#"],
  ["diesel", "d-d;i-j;e-e;s-z;e-E;l-l"],
  ["diesels", "d-d;i-j;e-e;s-z;e-E;l-l;s-#"],
  ["diminuendo", "d-d;i-i;m-m;i-i;n-n;u-y;e-E;n-n;d-d;o-o"],
  ["diminuendos", "d-d;i-i;m-m;i-i;n-n;u-y;e-E;n-n;d-d;o-o;s-#"],
  ["dinghie", "d-d;in;g-g;h-#;i-i;e-#"],
  ["dinghies", "d-d;in;g-g;h-#;i-i;e-#;s-#"],
  ["dinghy", "d-d;in;g-g;h-#;y-i"],
  ["dinghys", "d-d;in;g-g;h-#;y-i;s-#"],
  ["différentient", "d-d;i-i;ff-f;é-e;r-R;en-@;t-s;i-i;ent-#"],
  ["montien", "m-m;on-§;t-s;i-j;en-5"],
  ["montiens", "m-m;on-§;t-s;i-j;en-5;s-#"],
  ["montiennne", "m-m;on-§;t-s;i-j;e-E;nn-n;n-n;e-ç"],
  ["montiennnes", "m-m;on-§;t-s;i-j;e-E;nn-n;n-n;e-ç;s-#"],
  ["djebel", "d-d;j-Z;e-e;b-b;e-E;l-l"],
  ["djebels", "d-d;j-Z;e-e;b-b;e-E;l-l;s-#"],
  ["dolce", "d-d;o-o;l-l;c-S;e-E"],
  ["dracaena", "d-d;r-R;a-a;c-s;ae-e;n-n;a-a"],
  ["dracaenas", "d-d;r-R;a-a;c-s;ae-e;n-n;a-a;s-#"],
  ["drawback", "d-d;r-R;aw-o;b-b;a-a;ck-k"],
  ["drawbacks", "d-d;r-R;aw-o;b-b;a-a;ck-k;s-#"],
  ["dreadnought", "d-d;r-R;ea-E;d-d;n-n;ou-o;gh-#;t-t"],
  ["dreadnoughts", "d-d;r-R;ea-E;d-d;n-n;ou-o;gh-#;t-t;s-#"],
  ["drifter", "d-d;r-R;i-i;f-f;t-t;e-2;r-R"],
  ["drifters", "d-d;r-R;i-i;f-f;t-t;e-2;r-R;s-#"],
  ["drink", "d-d;r-R;in;k-k"],
  ["drinks", "d-d;r-R;in;k-k;s-#"],
  ["drosera", "d-d;r-R;o-o;s-z;e-e;r-R;a-a"],
  ["droseras", "d-d;r-R;o-o;s-z;e-e;r-R;a-a;s-#"],
  ["drugstore", "d-d;r-R;u-2;g-g;s-s;t-t;o-o;r-R;e-ç"],
  ["drugstores", "d-d;r-R;u-2;g-g;s-s;t-t;o-o;r-R;e-ç;s-#"],
  ["drummer", "d-d;r-R;u-2;mm-m;e-2;r-R"],
  ["drummers", "d-d;r-R;u-2;mm-m;e-2;r-R;s-#"],
  ["duce", "d-d;u;c-S;e-E"],
  ["duces", "d-d;u;c-S;e-E;s-#"],
  ["duffelcoat", "d-d;u-2;ff-f;e-2;l-l;c-k;oa-o;t-t"],
  ["duffelcoats", "d-d;u-2;ff-f;e-2;l-l;c-k;oa-o;t-t;s-#"],
  ["dundee", "d-d;u-2;n;d-d;ee-i"],
  ["dundees", "d-d;u-2;n;d-d;ee-i;s-#"],
  ["duumvir", "d-d;u-y;u-o;m-m;v-v;i-i;r-R"],
  ["duumvirs", "d-d;u-y;u-o;m-m;v-v;i-i;r-R;s-#"],
  ["duumvirat", "d-d;u-y;u-o;m-m;v-v;i-i;r-R;a-a;t-#"],
  ["duumvirats", "d-d;u-y;u-o;m-m;v-v;i-i;r-R;a-a;t-#;s-#"],
  ["dyschromatopsie", "d-d;y-i;s;ch-k;r-R;o-o;m-m;a-a;t-t;o-o;p-p;s-s;i-i;e-#"],
  [
    "dyschromatopsies",
    "d-d;y-i;s;ch-k;r-R;o-o;m-m;a-a;t-t;o-o;p-p;s-s;i-i;e-#;s-#",
  ],
  ["dyschronie", "d-d;y-i;s;ch-k;r-R;o-o;n-n;i-i;e-#"],
  ["dyschronies", "d-d;y-i;s;ch-k-S;r-R;o-o;n-n;i-i;e-#;s-#"],
  ["dyschronisme", "d-d;y-i;s;ch-k;r-R;o-o;n-n;i-i;sm;e-ç"],
  ["dyschronismes", "d-d;y-i;s;ch-k;r-R;o-o;n-n;i-i;sm;e-ç;s-3"],
  ["dysentérique", "d-d;y-i;s-s;en-@;t-t;é-e;r-R;i-i;qu-k;e-ç"],
  ["dysentériques", "d-d;y-i;s-s;en-@;t-t;é-e;r-R;i-i;qu-k;e-ç;s-#"],
  ["dysharmonie", "d-d;y-i;s-s;h-#;a-a;r-R;m-m;o-o;n-n;i-i;e-#"],
  ["dysharmonies", "d-d;y-i;s-s;h-#;a-a;r-R;m-m;o-o;n-n;i-i;e-#;s-#"],
  ["dysharmonique", "d-d;y-i;s-s;h-#;a-a;r-R;m-m;o-o;n-n;i-i;qu-k;e-ç"],
  ["dysharmoniques", "d-d;y-i;s-s;h-#;a-a;r-R;m-m;o-o;n-n;i-i;qu-k;e-ç;s-#"],
  [
    "dyshidrosiforme",
    "d-d;y-i;s-s;h-#;i-i;d-d;r-R;o-o;s-z;i-i;f-f;o-o;r-R;m-m;e-ç",
  ],
  [
    "dyshidrosiformes",
    "d-d;y-i;s-s;h-#;i-i;d-d;r-R;o-o;s-z;i-i;f-f;o-o;r-R;m-m;e-ç;s-#",
  ],
  [
    "dyshydrosiforme",
    "d-d;y-i;s-s;h-#;y-i;d-d;r-R;o-o;s-z;i-i;f-f;o-o;r-R;m-m;e-ç",
  ],
  [
    "dyshydrosiformes",
    "d-d;y-i;s-s;h-#;y-i;d-d;r-R;o-o;s-z;i-i;f-f;o-o;r-R;m-m;e-ç;s-#",
  ],
  ["dyshydrose", "d-d;y-i;s-s;h-#;y-i;d-d;r-R;o-o;s-z;e-ç"],
  ["dyshydroses", "d-d;y-i;s-s;h-#;y-i;d-d;r-R;o-o;s-z;e-ç;s-#"],
  ["dumping", "d-d;u-2;m-m;p-p;i-i;ng-G"],
  ["dumpings", "d-d;u-2;m-m;p-p;i-i;ng-G;s-#"],
  ["échevéria", "é-e;ch-S;e-e;v-v;é-e;r-R;i-j;a-a"],
  ["échevérias", "é-e;ch-S;e-e;v-v;é-e;r-R;i-j;a-a;s-#"],
  ["eczéma", "e-E;c-g;z-z;é-e;m-m;a-a"],
  ["eczémas", "e-E;c-g;z-z;é-e;m-m;a-a;s-#"],
  ["eczémateuse", "e-E;c-g;z-z;é-e;m-m;a-a;t-t;eu-2;s-z;e-ç"],
  ["eczémateuses", "e-E;c-g;z-z;é-e;m-m;a-a;t-t;eu-2;s-z;e-ç;s-#"],
  ["eczémateux", "e-E;c-g;z-z;é-e;m-m;a-a;t-t;eu-2;x-#"],
  ["edelweiss", "e-e;d-d;e-E;l-l;w-v;e-a;i-j;ss-s"],
  ["édelweiss", "é-e;d-d;e-E;l-l;w-v;e-a;i-j;ss-s"],
  ["efendi", "e-e;f-f;en-5;d-d;i-i"],
  ["efendis", "e-e;f-f;en-5;d-d;i-i;s-#"],
  ["effendi", "e-e;ff-f;en-5;d-d;i-i"],
  ["effendis", "e-e;ff-f;en-5;d-d;i-i;s-#"],
  ["ego", "e-e;g-g;o-o"],
  ["egos", "e-e;g-g;o-o;s-#"],
  ["eidétique", "e-E;i-j;d-d;é-e;t-t;i-i;qu-k;e-ç"],
  ["eidétiques", "e-E;i-j;d-d;é-e;t-t;i-i;qu-k;e-ç;s-#"],
  ["élohim", "é-e;l-l;o-o;h-#;im"],
  ["élohims", "é-e;l-l;o-o;h-#;im;s-#"],
  [
    "enbonnetdecotonner",
    "en-@;b-b;o-o;nn-n;e-E;t-#;d-d;e-°;c-k;o-o;t-t;o-o;nn-n;er-e",
  ],
  ["eh", "e-e;h-#"],
  ["entrelacs", "en-@;t-t;r-R;e-°;l-l;a-a;cs-#"],
  ["éphod", "é-e;ph-f;o-o;d"],
  ["éphods", "é-e;ph-f;o-o;d-d;s-#"],
  ["fiord", "f-f;i-j;o-o;r-R;d-d"],
  ["fjord", "f-f;j-j;o-o;r-R;d-d"],
  ["fiords", "f-f;i-j;o-o;r-R;d-d;s-#"],
  ["fjords", "f-f;j-j;o-o;r-R;d-d;s-#"],
  ["hinterland", "h-#;in;t-t;e-E;r-R;l-l;an-@;d-d"],
  ["jazzband", "j-Z;a-a;zz-z;b-b;an-@;d-d"],
  ["jazzbands", "j-Z;a-a;zz-z;b-b;an-@;d-d;s-#"],
  ["kobold", "k-k;o-o;b-b;o-o;l-l;d-d"],
  ["lied", "l-l;i-i;e-#;d-d"],
  ["lieds", "l-l;i-i;e-#;d-d;s-#"],
  ["manifold", "m-m;a-a;n-n;i-i;f-f;o-o;l-l;d-d"],
  ["maryland", "m-m;a-a;r-R;y-i;l-l;an-@;d-d"],
  ["mound", "m-m;o-a;u-w;n-n;d-d"],
  ["nemrod", "n-n;e-E;m-m;r-R;o-o;d-d"],
  ["nemrods", "n-n;e-E;m-m;r-R;o-o;d-d;s-#"],
  ["oersted", "o;e-E;r-R;s-s;t-t;e-E;d-d"],
  ["oersteds", "o;e-E;r-R;s-s;t-t;e-E;d-d;s-#"],
  ["openfield", "o-o;p-p;e-E;n-n;f-f;i-i;e-#;l-l;d-d"],
  ["openfields", "o-o;p-p;e-E;n-n;f-f;i-i;e-#;l-l;d-d;s-#"],
  ["passegrand", "p-p;a-a;ss-s;e-°;g-g;r-R;an-@;d-#"],
  ["passegrands", "p-p;a-a;ss-s;e-°;g-g;r-R;an-@;ds-#"],
  ["picofarad", "p-p;i-i;c-k;o-o;f-f;a-a;r-R;a-a;d-d"],
  ["picofarads", "p-p;i-i;c-k;o-o;f-f;a-a;r-R;a-a;d-d;s-#"],
  ["portland", "p-p;o-o;r-R;t-t;l-l;an-@;d-d"],
  ["pound", "p-p;o-a;u-w;n-n;d-d"],
  ["pounds", "p-p;o-a;u-w;n-n;d-d;s-#"],
  ["raid", "r-R;ai-E;d-d"],
  ["raids", "r-R;ai-E;d-d;s-#"],
  ["round", "r-R;o-a;u-w;n-n;d-d"],
  ["rounds", "r-R;o-a;u-w;n-n;d-d;s-#"],
  ["shed", "sh-S;e-E;d-d"],
  ["sheds", "sh-S;e-E;d-d;s-#"],
  ["stand", "s-s;t-t;an-@;d-d"],
  ["stands", "s-s;t-t;an-@;d-d;s-#"],
  ["talmud", "t-t;a-a;l-l;m-m;u-y;d-d"],
  ["talmuds", "t-t;a-a;l-l;m-m;u-y;d-d;s-#"],
  ["trend", "t-t;r-R;e-E;n-n;d-d"],
  ["trends", "t-t;r-R;e-E;n-n;d-d;s-#"],
  ["tweed", "t-t;w-w;ee-i;d-d"],
  ["tweeds", "t-t;w-w;ee-i;d-d;s-#"],
  ["underground", "u-2;n-n;d-d;e-2;r-R;g-g;r-R;o-a;u-w;n-n;d-d"],
  ["undergrounds", "u-2;n-n;d-d;e-2;r-R;g-g;r-R;o-a;u-w;n-n;d-d;s-#"],
  ["veld", "v-v;e-E;l-l;d-t"],
  ["velds", "v-v;e-E;l-l;d-t;s-#"],
  ["epsilon", "e-E;p-p;s-s;i-i;l-l;on"],
  ["epsilons", "e-E;p-p;s-s;i-i;l-l;on;s-#"],
  ["equisetum", "e-e;q-k;u-y;i-i;s-s;e-e;t-t;u-o;m-m"],
  ["equisetums", "e-e;q-k;u-y;i-i;s-s;e-e;t-t;u-o;m-m;s-#"],
  ["équisetum", "é-e;q-k;u-y;i-i;s-s;e-e;t-t;u-o;m-m"],
  ["équisetums", "é-e;q-k;u-y;i-i;s-s;e-e;t-t;u-o;m-m;s-#"],
  ["eresse", "e-E;r-R;e-E;ss-s;e-ç"],
  ["eresses", "e-E;r-R;e-E;ss-s;e-ç;s-#"],
  ["ersatz", "e-E;r-R;s-z;a-a;t-t;z-s"],
  ["exact", "e-E;x-X;a-a;ct-#"],
  ["exacts", "e-E;x-X;a-a;ct-#;s-#"],
  ["exeat", "e-E;x-X;e-e;a-a;t-t"],
  ["exeats", "e-E;x-X;e-e;a-a;t-t;s-#"],
  ["exempt", "e-E;x-X;em-@;p-#;t-#"],
  ["exemptassent", "e-E;x-X;em-@;p-#;t-t;a-a;ss-s;e-ç;nt-#"],
  ["exempte", "e-E;x-X;em-@;p-#;t-t;e-ç"],
  ["exemptent", "e-E;x-X;em-@;p-#;t-t;e-ç;nt-#"],
  ["exempter", "e-E;x-X;em-@;p-#;t-t;er-e"],
  ["exemptes", "e-E;x-X;em-@;p-#;t-t;e-ç;s-#"],
  ["exempts", "e-E;x-X;em-@;p-#;t-#;s-#"],
  ["exequatur", "e-E;x-X;e-e;q-k;u-w;a-a;t-t;u-y;r-R"],
  ["exequaturs", "e-E;x-X;e-e;qu-k;a-a;t-t;u-y;r-R;s-#"],
  ["explicit", "e-E;x-x;p-p;l-l;i-i;c-s;i-i;t-t"],
  ["explicits", "e-E;x-x;p-p;l-l;i-i;c-s;i-i;t-t;s-#"],
  ["facies", "f-f;a-a;c-s;i-j;e-E;s-s"],
  ["fantômnal", "f-f;an-@;t-t;ô-o;m-m;n-#;a-a;l-l"],
  ["faon", "f-f;aon-@"],
  ["faons", "f-f;aon-@;s-#"],
  ["fashion", "f-f;a-a;sh-S;io-2;n-n"],
  ["fashions", "f-f;a-a;sh-S;io-2;n-n;s-#"],
  ["fashionable", "f-f;a-a;sh-S;i-2;o-o;n-n;a-E;b-b;l-l;e-ç"],
  ["fashionables", "f-f;a-a;sh-S;i-2;o-o;n-n;a-E;b-b;l-l;e-ç;s-#"],
  ["faulx", "f-f;au-o;lx-#"],
  ["fazenda", "f-f;a-a;z-z;e-E;n-n;d-d;a-a"],
  ["fazendas", "f-f;a-a;z-z;e-E;n-n;d-d;a-a;s-#"],
  ["feedback", "f-f;ee-i;d-d;b-b;a-a;ck-k"],
  ["feedbacks", "f-f;ee-i;d-d;b-b;a-a;ck-k;s-#"],
  ["feeder", "f-f;ee-i;d-d;e-2;r-R"],
  ["feeders", "f-f;ee-i;d-d;e-2;r-R;s-#"],
  ["feldgrau", "f-f;e-E;l-l;d-d;g-g;r-R;a-a;u-w"],
  ["feldgraux", "f-f;e-E;l-l;d-d;g-g;r-R;a-a;u-w;x-#"],
  ["feldwebel", "f-f;e-E;l-l;d-d;w-v;e-e;b-b;e-E;l-l"],
  ["feldwebels", "f-f;e-E;l-l;d-d;w-v;e-e;b-b;e-E;l-l;s-#"],
  ["feria", "f-f;e-e;r-R;i-j;a-a"],
  ["ferias", "f-f;e-e;r-R;i-j;a-a;s-#"],
  ["fez", "f-f;e-E;z-z"],
  ["fils", "f-f;i-i;l-#;s-s"],
  ["flamenca", "f-f;l-l;a-a;m-m;e-E;n-n;c-k;a-a"],
  ["flamencas", "f-f;l-l;a-a;m-m;e-E;n-n;c-k;a-a;s-#"],
  ["flamenco", "f-f;l-l;a-a;m-m;e-E;n-n;c-k;o-o"],
  ["flamencos", "f-f;l-l;a-a;m-m;e-E;n-n;c-k;o-o;s-#"],
  ["flippers", "f-f;l-l;i-i;pp-p;e-2;r-R;s-#"],
  // [ "flipper", "f-f;l-l;i-i;pp-p;e-2;r-R" ], --> on garde le verbe
  ["fliqueflaquer", "f-f;l-l;i-i;qu-k;e-°;f-f;l-l;a-a;qu-k;er-e"],
  ["football", "f-f;oo-u;t-t;b-b;a-o;ll-l"],
  ["footballs", "f-f;oo-u;t-t;b-b;a-o;ll-l;s-#"],
  ["footballeur", "f-f;oo-u;t-t;b-b;a-o;ll-l;eu-2;r-R"],
  ["footballeurs", "f-f;oo-u;t-t;b-b;a-o;ll-l;eu-2;r-R;s-#"],
  ["footballeuse", "f-f;oo-u;t-t;b-b;a-o;ll-l;eu-2;s-z;e-ç"],
  ["footballeuses", "f-f;oo-u;t-t;b-b;a-o;ll-l;eu-2;s-z;e-ç;s-#"],
  ["freesia", "f-f;r-R;ee-i;s-z;i-j;a-a"],
  ["freesias", "f-f;r-R;ee-i;s-z;i-j;a-a;s-#"],
  ["fuchsia", "f-f;u-y;ch-S;s-#;i-j;a-a"],
  ["fuchsias", "f-f;u-y;ch-S;s-#;i-j;a-a;s-#"],
  ["führer", "f-f;ü-y;h-#;r-R;e-2;r-R"],
  ["führers", "f-f;ü-y;h-#;r-R;e-2;r-R;s-#"],
  ["full", "f-f;u-u;ll-l"],
  ["fulls", "f-f;u-u;ll-l;s-#"],
  ["gageure", "g-g;a-a;g-Z;e-#;u-y;r-R;e-ç"],
  ["gageures", "g-g;a-a;g-Z;e-#;u-y;r-R;e-ç;s-#"],
  ["gasoil", "g-g;a-a;s-z;o;i-j;l-l"],
  ["gasoils", "g-g;a-a;s-z;o;i-j;l-l;s-#"],
  ["gauleiter", "g-g;a;u-w;l-l;e-a;i-j;t-t;e-2;r-R"],
  ["gauleiters", "g-g;a;u-w;l-l;e-a;i-j;t-t;e-2;r-R;s-#"],
  ["geisha", "g-g;ei-E;sh-S;a-a"],
  ["geishas", "g-g;ei-E;sh-S;a-a;s-#"],
  ["gemment", "g-Z;e;mm-m;e-ç;nt-#"],
  ["gentilhomie", "g-Z;en-@;t-t;i-i;l-j;h-#;o-o;m-m;i-i;e-#"],
  ["gentilhomme", "g-Z;en-@;t-t;i-i;l-j;h-#;o-o;mm-m;e-ç"],
  ["gentilhommerie", "g-Z;en-@;t-t;i-i;l-j;h-#;o-o;mm-m;e-°;r-R;i-i;e-#"],
  ["gentilhommeries", "g-Z;en-@;t-t;i-i;l-j;h-#;o-o;mm-m;e-°;r-R;i-i;e-#;s-#"],
  ["gentilhommes", "g-Z;en-@;t-t;i-i;l-j;h-#;o-o;mm-m;e-ç;s-#"],
  ["gentilhommier", "g-Z;en-@;t-t;i-i;l-j;h-#;o-o;mm-m;i-j;er-e"],
  ["gentilhommière", "g-Z;en-@;t-t;i-i;l-j;h-#;o-o;mm-m;i-j;è-E;r-R;e-ç"],
  ["gentilhommières", "g-Z;en-@;t-t;i-i;l-j;h-#;o-o;mm-m;i-j;è-E;r-R;e-ç;s-#"],
  ["gestalt", "g;e-E;s-s;t-t;a-a;l-l;t-t"],
  ["gestalts", "g;e-E;s-s;t-t;a-a;l-l;t-t;s-#"],
  ["gestapo", "g;e-E;s-s;t-t;a-a;p-p;o-o"],
  ["gestapos", "g;e-E;s-s;t-t;a-a;p-p;o-o;s-#"],
  ["gin", "g-Z;in"],
  ["gins", "g-Z;in;s-#"],
  ["ginkgo", "g-Z;in-5;kg-k;o-o"],
  ["ginkgos", "g-Z;in-5;kg-k;o-o;s-#"],
  ["ginseng", "g-Z;in;s-s;en-@;g-g"],
  ["ginsengs", "g-Z;in;s-s;en-@;g-g;s-#"],
  ["girl", "g;i-2;r-R;l-l"],
  ["girls", "g;i-2;r-R;l-l;s-#"],
  ["glockenspiel", "g-g;l-l;o-o;ck-k;e-2;n-n;s-S;p-p;i-i;e-#;l-l"],
  ["glockenspiels", "g-g;l-l;o-o;ck-k;e-2;n-n;s-S;p-p;i-i;e-#;l-l;s-#"],
  ["gluten", "g-g;l-l;u-y;t-t;e-E;n-n"],
  ["glutens", "g-g;l-l;u-y;t-t;e-E;n-n;s-#"],
  ["groschen", "g-g;r-R;o-o;sch-S;e-E;n-n"],
  ["groschens", "g-g;r-R;o-o;sch-S;e-E;n-n;s-#"],
  ["kraken", "k-k;r-R;a-a;k-k;e-E;n-n"],
  ["krakens", "k-k;r-R;a-a;k-k;e-E;n-n;s-#"],
  ["lichaven", "l-l;i-i;ch-S;a-a;v-v;e-E;n-n"],
  ["lichen", "l-l;i-i;ch-k;e-E;n-n"],
  ["loden", "l-l;o-o;d-d;e-E;n-n"],
  ["maghzen", "m-m;a-a;g-g;h-#;z-z;e-E;n-n"],
  ["makhzen", "m-m;a-a;k-k;h-#;z-z;e-E;n-n"],
  ["menhaden", "m-m;e-E;n-n;h-#;a-a;d-d;e-E;n-n"],
  ["open", "o-o;p-p;e-E;n-n"],
  ["pecten", "p-p;e-E;c-k;t-t;e-E;n-n"],
  ["peulven", "p-p;eu-2;l-l;v-v;e-E;n-n"],
  ["roentgen", "r-R;oe-2;n-n;t-t;g-g;e-2;n-n"],
  ["sclérolichen", "s-s;c-k;l-l;é-e;r-R;o-o;l-l;i-i;ch-S;e-E;n-n"],
  ["sen", "s-s;e-E;n-n"],
  ["shamisen", "sh-S;a-a;m-m;i-i;s-z;e-E;n-n"],
  ["solen", "s-s;o-o;l-l;e-E;n-n"],
  ["lichavens", "l-l;i-i;ch-S;a-a;v-v;e-E;n-n;s-#"],
  ["lichens", "l-l;i-i;ch-k;e-E;n-n;s-#"],
  ["lodens", "l-l;o-o;d-d;e-E;n-n;s-#"],
  ["maghzens", "m-m;a-a;g-g;h-#;z-z;e-E;n-n;s-#"],
  ["makhzens", "m-m;a-a;k-k;h-#;z-z;e-E;n-n;s-#"],
  ["menhadens", "m-m;e-E;n-n;h-#;a-a;d-d;e-E;n-n;s-#"],
  ["opens", "o-o;p-p;e-E;n-n;s-#"],
  ["pectens", "p-p;e-E;c-k;t-t;e-E;n-n;s-#"],
  ["peulvens", "p-p;eu-2;l-l;v-v;e-E;n-n;s-#"],
  ["roentgens", "r-R;oe-2;n-n;t-t;g-g;e-2;n-n;s-#"],
  ["sclérolichens", "s-s;c-k;l-l;é-e;r-R;o-o;l-l;i-i;ch-S;e-E;n-n;s-#"],
  ["shamisens", "sh-S;a-a;m-m;i-i;s-z;e-E;n-n;s-#"],
  ["solens", "s-s;o-o;l-l;e-E;n-n;s-#"],
  ["goal", "g-g;o-o;a-#;l-l"],
  ["goals", "g-g;o-o;a-#;l-l;s-#"],
  ["gopher", "g-g;o-o;ph-f;e-2;r-R"],
  ["gophers", "g-g;o-o;ph-f;e-2;r-R;s-#"],
  ["grazioso", "g-g;r-R;a-a;z-s;i-j;o-o;s-z;o-o"],
  ["graziosos", "g-g;r-R;a-a;z-s;i-j;o-o;s-z;o-o;s-#"],
  ["gringo", "g-g;r-R;in;g-g;o-o"],
  ["gringos", "g-g;r-R;in;g-g;o-o;s-#"],
  ["grizzli", "g-g;r-R;i-i;zz-z;l-l;i-i"],
  ["grizzlis", "g-g;r-R;i-i;zz-z;l-l;i-i;s-#"],
  ["guerillero", "gu-g;e;r-R;ill-/;e;r-R;o-o"],
  ["guerilleros", "gu-g;e;r-R;ill-/;e;r-R;o-o;s-#"],
  ["guérillero", "gu-g;é-e;r-R;ill-/;e;r-R;o-o"],
  ["guérilleros", "gu-g;é-e;r-R;ill-/;e;r-R;o-o;s-#"],
  ["guesdiste", "gu-g;e-E;s-#;d-d;i-i;s-s;t-t;e-ç"],
  ["guesdistes", "gu-g;e-E;s-#;d-d;i-i;s-s;t-t;e-ç;s-#"],
  ["guillemetassent", "gu-g;ill-/;e-°;m-m;e-e;t-t;a-a;ss-s;e-ç;nt-#"],
  ["guillemetions", "gu-g;ill-/;e-°;m-m;e-e;t-t;i-j;on-§;s-#"],
  ["habanera", "h-#;a-a;b-b;a-a;n-n;e-e;r-R;a-a"],
  ["habaneras", "h-#;a-a;b-b;a-a;n-n;e-e;r-R;a-a;s-#"],
  ["hacienda", "h-#;a-a;c-s;i-j;e-E;n-n;d-d;a-a"],
  ["haciendas", "h-#;a-a;c-s;i-j;e-E;n-n;d-d;a-a;s-#"],
  ["halecret", "h-#;a-a;l-l;e-°;c-k;r-R;et-E"],
  ["halecrets", "h-#;a-a;l-l;e-°;c-k;r-R;e-E;t-#;s-#"],
  ["hall", "h-#;a-o;ll-l"],
  ["halls", "h-#;a-o;ll-l;s-#"],
  ["hautbois", "h-#;au-o;t-#;b-b;oi-6;s-#"],
  ["havresac", "h-#;a-a;v-v;r-R;e-°;s-s;a-a;c-k"],
  ["havresacs", "h-#;a-a;v-v;r-R;e-°;s-s;a-a;c-k;s-#"],
  ["ovisac", "o-o;v-v;i-i;s-s;a-a;c-k"],
  ["ovisacs", "o-o;v-v;i-i;s-s;a-a;c-k;s-#"],
  ["hégélianisme", "h-#;é-e;g-g;é-e;l-l;i-j;a-a;n-n;i-i;s-s;m-m;e-ç"],
  ["hégélianismes", "h-#;é-e;g-g;é-e;l-l;i-j;a-a;n-n;i-i;s-s;m-m;e-ç;s-#"],
  ["hégélien", "h-#;é-e;g-g;é-e;l-l;i-j;en-5"],
  ["hégélienne", "h-#;é-e;g-g;é-e;l-l;i-j;e-E;nn-n;e-ç"],
  ["hégéliennes", "h-#;é-e;g-g;é-e;l-l;i-j;e-E;nn-n;e-ç;s-#"],
  ["hégéliens", "h-#;é-e;g-g;é-e;l-l;i-j;en-5;s-#"],
  ["hégélisme", "h-#;é-e;g-g;é-e;l-l;i-i;s-s;m-m;e-ç"],
  ["hegel", "h-#;e;g;e-E;l-l"],
  ["heimatlos", "h-#;e-a;i-j;m-m;a-a;t-t;l-l;o-o;s-s"],
  ["hemloc", "h-#;e-E;m-m;l-l;o-o;c-k"],
  ["hemlocs", "h-#;e-E;m-m;l-l;o-o;c-k;s-#"],
  ["hemlock", "h-#;e-E;m-m;l-l;o-o;ck-k"],
  ["hemlocks", "h-#;e-E;m-m;l-l;o-o;ck-k;s-#"],
  ["hep", "h-#;e-E;p-p"],
  [
    "hétéroinoculation",
    "h-#;é-e;t-t;é-e;r-R;o-o;i-i;n-n;o-o;c-k;u-y;l-l;a-a;t-s;i-j;on-§",
  ],
  [
    "hétéroinoculations",
    "h-#;é-e;t-t;é-e;r-R;o-o;i-i;n-n;o-o;c-k;u-y;l-l;a-a;t-s;i-j;on-§;s-#",
  ],
  ["highlandais", "h-#;i-a;g-j;h-#;l-l;an-@;d-d;ai-E;s-#"],
  ["highlander", "h-#;i-a;g-j;h-#;l-l;an-@;d-d;e-2;r-R"],
  ["highlanders", "h-#;i-a;g-j;h-#;l-l;an-@;d-d;e-2;r-R;s-#"],
  ["highlifer", "h-#;i-a;g-j;h-#;l-l;i-i;f-f;e-E;r-R"],
  ["highlifers", "h-#;i-a;g-j;h-#;l-l;i-i;f-f;e-E;r-R;s-#"],
  ["highlifeur", "h-#;i-a;g-j;h-#;l-l;i-i;f-f;eu-2;r-R"],
  ["highlifeurs", "h-#;i-a;g-j;h-#;l-l;i-i;f-f;eu-2;r-R;s-#"],
  ["hindi", "h-#;in;d-d;i-i"],
  ["hindis", "h-#;in;d-d;i-i;s-#"],
  ["hinterlands", "h-#;i-i;n-n;t-t;e-E;r-R;l-l;an-@;d-d;s-#"],
  ["homespun", "h-#;o-o;m-m;e-#;s-s;p-p;u-2;n-n"],
  ["homespuns", "h-#;o-o;m-m;e-#;s-s;p-p;u-2;n-n;s-#"],
  ["homoionique", "h-#;o-o;m-m;oi;o-o;n-n;i-i;qu-k;e-ç"],
  ["homoioniques", "h-#;o-o;m-m;oi;o-o;n-n;i-i;qu-k;e-ç;s-#"],
  ["hotus", "h-#;o-o;t-t;u-y;s-s"],
  ["huerta", "h-#;u-w;e-E;r-R;t-t;a-a"],
  ["huertas", "h-#;u-w;e-E;r-R;t-t;a-a;s-#"],
  ["inguinal", "in-5;g-g;u-y;i-i;n-n;a-a;l-l"],
  ["inguinale", "in-5;g-g;u-y;i-i;n-n;a-a;l-l;e-ç"],
  ["inguinales", "in-5;g-g;u-y;i-i;n-n;a-a;l-l;e-ç;s-#"],
  ["inguinaux", "in-5;g-g;u-y;i-i;n-n;au-o;x-#"],
  ["inre", "in;r-R;e"],
  ["inrem", "in;r-R;e-E;m-m"],
  ["inselberg", "in;s-s;e-E;l-l;b-b;e-E;r-R;g-g"],
  ["inselbergs", "in;s-s;e-E;l-l;b-b;e-E;r-R;g-g;s-#"],
  ["intemporalibus", "in;t-t;em-5;p-p;o-o;r-R;a-a;l-l;i-i;b-b;u-y;s-s"],
  ["kelvin", "k-k;e-E;l-l;v-v;in"],
  ["kelvins", "k-k;e-E;l-l;v-v;in;s-#"],
  ["khamsin", "kh-R;a-a;m-m;s-s;in"],
  ["khamsins", "kh-R;a-a;m-m;s-s;in;s-#"],
  [
    "kremlinologiste",
    "k-k;r-R;e-E;m;l-l;i-i;n-n;o-o;l-l;o-o;g-Z;i-i;s-s;t-t;e-ç",
  ],
  ["linsang", "l-l;in;s-s;an-@;g"],
  ["linsangs", "l-l;in;s-s;an-@;g-g;s-#"],
  ["mackintosh", "m-m;a-a;ck-k;in;t-t;o-o;sh-S"],
  ["mackintoshs", "m-m;a-a;ck-k;in;t-t;o-o;sh-S;s-#"],
  ["minnesinger", "m-m;i-i;nn-n;e-°;s-z;i;n-G;g;e-2;r-R"],
  ["minnesingers", "m-m;i-i;nn-n;e-°;s-z;i;n-G;g;e-2;r-R;s-#"],
  ["muffin", "m-m;u-2;ff-f;in"],
  ["muffins", "m-m;u-2;ff-f;in;s-#"],
  ["nundines", "n-n;un-§;d-d;i-i;n-n;e-ç;s-#"],
  ["parkinson", "p-p;a-a;r-R;k-k;i-i;n-n;s-s;on"],
  ["parkinsons", "p-p;a-a;r-R;k-k;i-i;n-n;s-s;on;s-#"],
  ["pippermint", "p-p;i-i;pp-p;e-E;r-R;m-m;in;t-t"],
  ["réserpine", "r-R;é-e;s-s;e-E;r-R;p-p;i-i;n-n;e-ç"],
  ["réserpines", "r-R;é-e;s-s;e-E;r-R;p-p;i-i;n-n;e-ç;s-#"],
  ["seringuero", "s-s;e-e;r-R;in;gu-g;e-e;r-R;o-o"],
  ["seringueros", "s-s;e-e;r-R;in;gu-g;e-e;r-R;o-o;s-#"],
  ["speakerine", "s-s;p-p;ea-i;k-k;e-°;r-R;i-i;n-n;e-ç"],
  ["speakerines", "s-s;p-p;ea-i;k-k;e-°;r-R;i-i;n-n;e-ç;s-#"],
  ["resachant", "r-R;e-°;s-s;a-a;ch-S;an-@;t-#"],
  ["resache", "r-R;e-°;s-s;a-a;ch-S;e-ç"],
  ["resachent", "r-R;e-°;s-s;a-a;ch-S;e-ç;nt-#"],
  ["resaches", "r-R;e-°;s-s;a-a;ch-S;e-ç;s-#"],
  ["resachez", "r-R;e-°;s-s;a-a;ch-S;ez-e"],
  ["resachons", "r-R;e-°;s-s;a-a;ch-S;on-§;s-#"],
  ["resais", "r-R;e-°;s-s;ai-E;s-#"],
  ["resait", "r-R;e-°;s-s;ai-E;t-#"],
  ["resaura", "r-R;e-°;s-s;au-o;r-R;a-a"],
  ["resaurai", "r-R;e-°;s-s;au-o;r-R;ai-e"],
  ["resauraient", "r-R;e-°;s-s;au-o;r-R;ai-E;ent-#"],
  ["resaurais", "r-R;e-°;s-s;au-o;r-R;ai-E;s-#"],
  ["resaurait", "r-R;e-°;s-s;au-o;r-R;ai-E;t-#"],
  ["resauras", "r-R;e-°;s-s;au-o;r-R;a-a;s-#"],
  ["resaurez", "r-R;e-°;s-s;au-o;r-R;ez-e"],
  ["resauriez", "r-R;e-°;s-s;au-o;r-R;i-j;ez-e"],
  ["resaurions", "r-R;e-°;s-s;au-o;r-R;i-j;on-§;s-#"],
  ["resaurons", "r-R;e-°;s-s;au-o;r-R;on-§;s-#"],
  ["resauront", "r-R;e-°;s-s;au-o;r-R;on-§;t-#"],
  ["resavaient", "r-R;e-°;s-s;a-a;v-v;ai-E;ent-#"],
  ["resavais", "r-R;e-°;s-s;a-a;v-v;ai-E;s-#"],
  ["resavait", "r-R;e-°;s-s;a-a;v-v;ai-E;t-#"],
  ["resavent", "r-R;e-°;s-s;a-a;v-v;e-ç;nt-#"],
  ["resavez", "r-R;e-°;s-s;a-a;v-v;ez-e"],
  ["resaviez", "r-R;e-°;s-s;a-a;v-v;i-j;ez-e"],
  ["resavions", "r-R;e-°;s-s;a-a;v-v;i-j;on-§;s-#"],
  ["resavoir", "r-R;e-°;s-s;a-a;v-v;oi-6;r-R"],
  ["resavons", "r-R;e-°;s-s;a-a;v-v;on-§;s-#"],
  ["resouffler", "r-R;e-°;s-s;ou-u;ff-f;l-l;er-e"],
  ["resu", "r-R;e-°;s-s;u-y"],
  ["resuça", "r-R;e-°;s-s;u-y;ç-s;a-a"],
  ["resuçai", "r-R;e-°;s-s;u-y;ç-s;ai-e"],
  ["resuçaient", "r-R;e-°;s-s;u-y;ç-s;ai-E;ent-#"],
  ["resuçais", "r-R;e-°;s-s;u-y;ç-s;ai-E;s-#"],
  ["resuçait", "r-R;e-°;s-s;u-y;ç-s;ai-E;t-#"],
  ["resuçâmes", "r-R;e-°;s-s;u-y;ç-s;â-a;m-m;e-ç;s-#"],
  ["resuçant", "r-R;e-°;s-s;u-y;ç-s;an-@;t-#"],
  ["resuças", "r-R;e-°;s-s;u-y;ç-s;a-a;s-#"],
  ["resuçasse", "r-R;e-°;s-s;u-y;ç-s;a-a;ss-s;e-ç"],
  ["resuçassent", "r-R;e-°;s-s;u-y;ç-s;a-a;ss-s;e-ç;nt-#"],
  ["resuçasses", "r-R;e-°;s-s;u-y;ç-s;a-a;ss-s;e-ç;s-#"],
  ["resuçassiez", "r-R;e-°;s-s;u-y;ç-s;a-a;ss-s;i-j;ez-e"],
  ["resuçassions", "r-R;e-°;s-s;u-y;ç-s;a-a;ss-s;i-j;on-§;s-#"],
  ["resuçât", "r-R;e-°;s-s;u-y;ç-s;â-a;t-#"],
  ["resuçâtes", "r-R;e-°;s-s;u-y;ç-s;â-a;t-t;e-ç;s-#"],
  ["resuce", "r-R;e-°;s-s;u-y;c-s;e-ç"],
  ["resucé", "r-R;e-°;s-s;u-y;c-s;é-e"],
  ["resucée", "r-R;e-°;s-s;u-y;c-s;é-e;e-#"],
  ["resucées", "r-R;e-°;s-s;u-y;c-s;é-e;e-#;s-#"],
  ["resucent", "r-R;e-°;s-s;u-y;c-s;e-ç;nt-#"],
  ["resucer", "r-R;e-°;s-s;u-y;c-s;er-e"],
  ["resucera", "r-R;e-°;s-s;u-y;c-s;e-°;r-R;a-a"],
  ["resucerai", "r-R;e-°;s-s;u-y;c-s;e-°;r-R;ai-e"],
  ["resuceraient", "r-R;e-°;s-s;u-y;c-s;e-°;r-R;ai-E;ent-#"],
  ["resucerais", "r-R;e-°;s-s;u-y;c-s;e-°;r-R;ai-E;s-#"],
  ["resucerait", "r-R;e-°;s-s;u-y;c-s;e-°;r-R;ai-E;t-#"],
  ["resuceras", "r-R;e-°;s-s;u-y;c-s;e-°;r-R;a-a;s-#"],
  ["resucèrent", "r-R;e-°;s-s;u-y;c-s;è-E;r-R;e-ç;nt-#"],
  ["resucerez", "r-R;e-°;s-s;u-y;c-s;e-°;r-R;ez-e"],
  ["resuceriez", "r-R;e-°;s-s;u-y;c-s;e-°;r-R;i-j;ez-e"],
  ["resucerions", "r-R;e-°;s-s;u-y;c-s;e-°;r-R;i-j;on-§;s-#"],
  ["resucerons", "r-R;e-°;s-s;u-y;c-s;e-°;r-R;on-§;s-#"],
  ["resuceront", "r-R;e-°;s-s;u-y;c-s;e-°;r-R;on-§;t-#"],
  ["resuces", "r-R;e-°;s-s;u-y;c-s;e-ç;s-#"],
  ["resucés", "r-R;e-°;s-s;u-y;c-s;é-e;s-#"],
  ["resucez", "r-R;e-°;s-s;u-y;c-s;ez-e"],
  ["resuciez", "r-R;e-°;s-s;u-y;c-s;i-j;ez-e"],
  ["resucions", "r-R;e-°;s-s;u-y;c-s;i-j;on-§;s-#"],
  ["resuçons", "r-R;e-°;s-s;u-y;ç-s;on-§;s-#"],
  ["resue", "r-R;e-°;s-s;u-y;e-#"],
  ["resues", "r-R;e-°;s-s;u-y;e-#;s-#"],
  ["resûmes", "r-R;e-°;s-s;û-y;m-m;e-ç;s-#"],
  ["resurent", "r-R;e-°;s-s;u-y;r-R;e-ç;nt-#"],
  ["resurgement", "r-R;e-°;s-s;u-y;r-R;g-Z;e-°;m-m;en-@;t-#"],
  ["resurgi", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i"],
  ["resurgie", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;e-#"],
  ["resurgies", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;e-#;s-#"],
  ["resurgîmes", "r-R;e-°;s-s;u-y;r-R;g-Z;î-i;m-m;e-ç;s-#"],
  ["resurgir", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;r-R"],
  ["resurgira", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;r-R;a-a"],
  ["resurgirai", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;r-R;ai-e"],
  ["resurgiraient", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;r-R;ai-E;ent-#"],
  ["resurgirais", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;r-R;ai-E;s-#"],
  ["resurgirait", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;r-R;ai-E;t-#"],
  ["resurgiras", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;r-R;a-a;s-#"],
  ["resurgirent", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;r-R;e-ç;nt-#"],
  ["resurgirez", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;r-R;ez-e"],
  ["resurgiriez", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;r-R;i-j;ez-e"],
  ["resurgirions", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;r-R;i-j;on-§;s-#"],
  ["resurgirons", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;r-R;on-§;s-#"],
  ["resurgiront", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;r-R;on-§;t-#"],
  ["resurgis", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;s-#"],
  ["resurgissaient", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;ss-s;ai-E;ent-#"],
  ["resurgissais", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;ss-s;ai-E;s-#"],
  ["resurgissait", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;ss-s;ai-E;t-#"],
  ["resurgissant", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;ss-s;an-@;t-#"],
  ["resurgisse", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;ss-s;e-ç"],
  ["resurgissent", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;ss-s;e-ç;nt-#"],
  ["resurgisses", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;ss-s;e-ç;s-#"],
  ["resurgissez", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;ss-s;ez-e"],
  ["resurgissiez", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;ss-s;i-j;ez-e"],
  ["resurgissions", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;ss-s;i-j;on-§;s-#"],
  ["resurgissons", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;ss-s;on-§;s-#"],
  ["resurgit", "r-R;e-°;s-s;u-y;r-R;g-Z;i-i;t-#"],
  ["resurgît", "r-R;e-°;s-s;u-y;r-R;g-Z;î-i;t-#"],
  ["resurgîtes", "r-R;e-°;s-s;u-y;r-R;g-Z;î-i;t-t;e-ç;s-#"],
  ["resus", "r-R;e-°;s-s;u-y;s-#"],
  ["resusse", "r-R;e-°;s-s;u-y;ss-s;e-ç"],
  ["resussent", "r-R;e-°;s-s;u-y;ss-s;e-ç;nt-#"],
  ["resusses", "r-R;e-°;s-s;u-y;ss-s;e-ç;s-#"],
  ["resussiez", "r-R;e-°;s-s;u-y;ss-s;i-j;ez-e"],
  ["resussions", "r-R;e-°;s-s;u-y;ss-s;i-j;on-§;s-#"],
  ["resut", "r-R;e-°;s-s;u-y;t-#"],
  ["resût", "r-R;e-°;s-s;û-y;t-#"],
  ["resûtes", "r-R;e-°;s-s;û-y;t-t;e-ç;s-#"],
  ["resynthèse", "r-R;e-°;s-s;yn-5;t-t;h-#;è-E;s-z;e-ç"],
  ["résection", "r-R;é-e;s-s;e-E;c-k;t-s;i-j;on-§"],
  ["résections", "r-R;é-e;s-s;e-E;c-k;t-s;i-j;on-§;s-#"],
  ["réséqua", "r-R;é-e;s-s;é-e;qu-k;a-a"],
  ["réséquai", "r-R;é-e;s-s;é-e;qu-k;ai-e"],
  ["réséquaient", "r-R;é-e;s-s;é-e;qu-k;ai-E;ent-#"],
  ["réséquais", "r-R;é-e;s-s;é-e;qu-k;ai-E;s-#"],
  ["réséquait", "r-R;é-e;s-s;é-e;qu-k;ai-E;t-#"],
  ["réséquâmes", "r-R;é-e;s-s;é-e;qu-k;â-a;m-m;e-ç;s-#"],
  ["réséquant", "r-R;é-e;s-s;é-e;qu-k;an-@;t-#"],
  ["réséquas", "r-R;é-e;s-s;é-e;qu-k;a-a;s-#"],
  ["réséquasse", "r-R;é-e;s-s;é-e;qu-k;a-a;ss-s;e-ç"],
  ["réséquassent", "r-R;é-e;s-s;é-e;qu-k;a-a;ss-s;e-ç;nt-#"],
  ["réséquasses", "r-R;é-e;s-s;é-e;qu-k;a-a;ss-s;e-ç;s-#"],
  ["réséquassiez", "r-R;é-e;s-s;é-e;qu-k;a-a;ss-s;i-j;ez-e"],
  ["réséquassions", "r-R;é-e;s-s;é-e;qu-k;a-a;ss-s;i-j;on-§;s-#"],
  ["réséquât", "r-R;é-e;s-s;é-e;qu-k;â-a;t-#"],
  ["réséquâtes", "r-R;é-e;s-s;é-e;qu-k;â-a;t-t;e-ç;s-#"],
  ["résèque", "r-R;é-e;s-s;è-E;qu-k;e-ç"],
  ["réséqué", "r-R;é-e;s-s;é-e;qu-k;é-e"],
  ["réséquée", "r-R;é-e;s-s;é-e;qu-k;é-e;e-#"],
  ["réséquées", "r-R;é-e;s-s;é-e;qu-k;é-e;e-#;s-#"],
  ["résèquent", "r-R;é-e;s-s;è-E;qu-k;e-ç;nt-#"],
  ["réséquer", "r-R;é-e;s-s;é-e;qu-k;er-e"],
  ["réséquera", "r-R;é-e;s-s;é-e;qu-k;e-°;r-R;a-a"],
  ["réséquerai", "r-R;é-e;s-s;é-e;qu-k;e-°;r-R;ai-e"],
  ["réséqueraient", "r-R;é-e;s-s;é-e;qu-k;e-°;r-R;ai-E;ent-#"],
  ["réséquerais", "r-R;é-e;s-s;é-e;qu-k;e-°;r-R;ai-E;s-#"],
  ["réséquerait", "r-R;é-e;s-s;é-e;qu-k;e-°;r-R;ai-E;t-#"],
  ["réséqueras", "r-R;é-e;s-s;é-e;qu-k;e-°;r-R;a-a;s-#"],
  ["réséquèrent", "r-R;é-e;s-s;é-e;qu-k;è-E;r-R;e-ç;nt-#"],
  ["réséquerez", "r-R;é-e;s-s;é-e;qu-k;e-°;r-R;ez-e"],
  ["réséqueriez", "r-R;é-e;s-s;é-e;qu-k;e-°;r-R;i-j;ez-e"],
  ["réséquerions", "r-R;é-e;s-s;é-e;qu-k;e-°;r-R;i-j;on-§;s-#"],
  ["réséquerons", "r-R;é-e;s-s;é-e;qu-k;e-°;r-R;on-§;s-#"],
  ["réséqueront", "r-R;é-e;s-s;é-e;qu-k;e-°;r-R;on-§;t-#"],
  ["résèques", "r-R;é-e;s-s;è-E;qu-k;e-ç;s-#"],
  ["réséqués", "r-R;é-e;s-s;é-e;qu-k;é-e;s-#"],
  ["réséquez", "r-R;é-e;s-s;é-e;qu-k;ez-e"],
  ["réséquiez", "r-R;é-e;s-s;é-e;qu-k;i-j;ez-e"],
  ["réséquions", "r-R;é-e;s-s;é-e;qu-k;i-j;on-§;s-#"],
  ["réséquons", "r-R;é-e;s-s;é-e;qu-k;on-§;s-#"],
  ["résipiscence", "r-R;é-e;s-s;i-i;p-p;i-i;s-s;c-s;en-@;c-s;e-ç"],
  ["résipiscences", "r-R;é-e;s-s;i-i;p-p;i-i;s-s;c-s;en-@;c-s;e-ç;s-#"],
  ["résipiscent", "r-R;é-e;s-s;i-i;p-p;i-i;s-s;c-s;en-@;t-#"],
  ["résipiscente", "r-R;é-e;s-s;i-i;p-p;i-i;s-s;c-s;en-@;t-t;e-ç"],
  ["solennel", "s-s;o-o;l-l;e-a;nn-n;e-E;l-l"],
  ["solennelle", "s-s;o-o;l-l;e-a;nn-n;e-E;ll-l;e-ç"],
  ["solennellement", "s-s;o-o;l-l;e-a;nn-n;e-E;ll-l;e-°;m-m;en-@;t-#"],
  ["solennelles", "s-s;o-o;l-l;e-a;nn-n;e-E;ll-l;e-ç;s-#"],
  ["solennels", "s-s;o-o;l-l;e-a;nn-n;e-E;l-l;s-#"],
  ["solennisa", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;a-a"],
  ["solennisai", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;ai-e"],
  ["solennisaient", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;ai-E;ent-#"],
  ["solennisais", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;ai-E;s-#"],
  ["solennisait", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;ai-E;t-#"],
  ["solennisâmes", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;â-a;m-m;e-ç;s-#"],
  ["solennisant", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;an-@;t-#"],
  ["solennisas", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;a-a;s-#"],
  ["solennisasse", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;a-a;ss-s;e-ç"],
  ["solennisassent", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;a-a;ss-s;e-ç;nt-#"],
  ["solennisasses", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;a-a;ss-s;e-ç;s-#"],
  ["solennisassiez", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;a-a;ss-s;i-j;ez-e"],
  ["solennisassions", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;a-a;ss-s;i-j;on-§;s-#"],
  ["solennisât", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;â-a;t-#"],
  ["solennisâtes", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;â-a;t-t;e-ç;s-#"],
  ["solennisation", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;a-a;t-s;i-j;on-§"],
  ["solennisations", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;a-a;t-s;i-j;on-§;s-#"],
  ["solennise", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;e-ç"],
  ["solennisé", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;é-e"],
  ["solennisée", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;é-e;e-#"],
  ["solennisées", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;é-e;e-#;s-#"],
  ["solennisent", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;e-ç;nt-#"],
  ["solenniser", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;er-e"],
  ["solennisera", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;e-°;r-R;a-a"],
  ["solenniserai", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;e-°;r-R;ai-e"],
  ["solenniseraient", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;e-°;r-R;ai-E;ent-#"],
  ["solenniserais", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;e-°;r-R;ai-E;s-#"],
  ["solenniserait", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;e-°;r-R;ai-E;t-#"],
  ["solenniseras", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;e-°;r-R;a-a;s-#"],
  ["solennisèrent", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;è-E;r-R;e-ç;nt-#"],
  ["solenniserez", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;e-°;r-R;ez-e"],
  ["solenniseriez", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;e-°;r-R;i-j;ez-e"],
  ["solenniserions", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;e-°;r-R;i-j;on-§;s-#"],
  ["solenniserons", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;e-°;r-R;on-§;s-#"],
  ["solenniseront", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;e-°;r-R;on-§;t-#"],
  ["solennises", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;e-ç;s-#"],
  ["solennisés", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;é-e;s-#"],
  ["solennisez", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;ez-e"],
  ["solennisiez", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;i-j;ez-e"],
  ["solennisions", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;i-j;on-§;s-#"],
  ["solennisons", "s-s;o-o;l-l;e-a;nn-n;i-i;s-z;on-§;s-#"],
  ["solennité", "s-s;o-o;l-l;e-a;nn-n;i-i;t-t;é-e"],
  ["solennités", "s-s;o-o;l-l;e-a;nn-n;i-i;t-t;é-e;s-#"],
  ["zoom", "z-z;oo-u;m-m"],
  ["zooms", "z-z;oo-u;m-m;s-#"],
  ["gymnosomes", "g-Z;y-i;m-m;n-n;o-o;s-z;o-o;m-m;e-ç;s-#"],
  ["présanction", "p-p;r-R;é-e;s-s;an-@;c-k;t-s;i-j;on-§"],
  ["préséance", "p-p;r-R;é-e;s-s;é-e;an-@;c-s;e-ç"],
  ["préséances", "p-p;r-R;é-e;s-s;é-e;an-@;c-s;e-ç;s-#"],
  ["préséchoir", "p-p;r-R;é-e;s-s;é-e;ch-S;oi-6;r-R"],
  ["présélecteur", "p-p;r-R;é-e;s-s;é-e;l-l;e-E;c-k;t-t;eu-2;r-R"],
  ["présélection", "p-p;r-R;é-e;s-s;é-e;l-l;e-E;c-k;t-s;i-j;on-§"],
  ["présélections", "p-p;r-R;é-e;s-s;é-e;l-l;e-E;c-k;t-s;i-j;on-§;s-#"],
  ["présélectrice", "p-p;r-R;é-e;s-s;é-e;l-l;e-E;c-k;t-t;r-R;i-i;c-s;e-ç"],
  ["présériel", "p-p;r-R;é-e;s-s;é-e;r-R;i-j;e-E;l-l"],
  ["présexuel", "p-p;r-R;é-e;s-s;e-E;x-x;u-y;e-E;l-l"],
  ["présupposa", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;a-a"],
  ["présupposai", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;ai-e"],
  ["présupposaient", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;ai-E;ent-#"],
  ["présupposais", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;ai-E;s-#"],
  ["présupposait", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;ai-E;t-#"],
  ["présupposâmes", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;â-a;m-m;e-ç;s-#"],
  ["présupposant", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;an-@;t-#"],
  ["présupposas", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;a-a;s-#"],
  ["présupposasse", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;a-a;ss-s;e-ç"],
  ["présupposassent", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;a-a;ss-s;e-ç;nt-#"],
  ["présupposasses", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;a-a;ss-s;e-ç;s-#"],
  ["présupposassiez", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;a-a;ss-s;i-j;ez-e"],
  [
    "présupposassions",
    "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;a-a;ss-s;i-j;on-§;s-#",
  ],
  ["présupposât", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;â-a;t-#"],
  ["présupposâtes", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;â-a;t-t;e-ç;s-#"],
  ["présuppose", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;e-ç"],
  ["présupposé", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;é-e"],
  ["présupposée", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;é-e;e-#"],
  ["présupposées", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;é-e;e-#;s-#"],
  ["présupposent", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;e-ç;nt-#"],
  ["présupposer", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;er-e"],
  ["présupposera", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;e-°;r-R;a-a"],
  ["présupposerai", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;e-°;r-R;ai-e"],
  ["présupposeraient", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;e-°;r-R;ai-E;ent-#"],
  ["présupposerais", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;e-°;r-R;ai-E;s-#"],
  ["présupposerait", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;e-°;r-R;ai-E;t-#"],
  ["présupposeras", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;e-°;r-R;a-a;s-#"],
  ["présupposèrent", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;è-E;r-R;e-ç;nt-#"],
  ["présupposerez", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;e-°;r-R;ez-e"],
  ["présupposeriez", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;e-°;r-R;i-j;ez-e"],
  ["présupposerions", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;e-°;r-R;i-j;on-§;s-#"],
  ["présupposerons", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;e-°;r-R;on-§;s-#"],
  ["présupposeront", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;e-°;r-R;on-§;t-#"],
  ["présupposes", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;e-ç;s-#"],
  ["présupposés", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;é-e;s-#"],
  ["présupposez", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;ez-e"],
  ["présupposiez", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;i-j;ez-e"],
  ["présupposions", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;i-j;on-§;s-#"],
  ["présupposition", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;i-i;t-s;i-j;on-§"],
  ["présuppositions", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;i-i;t-s;i-j;on-§;s-#"],
  ["présupposons", "p-p;r-R;é-e;s-s;u-y;pp-p;o-o;s-z;on-§;s-#"],
  ["présuppuratif", "p-p;r-R;é-e;s-s;u-y;pp-p;u-y;r-R;a-a;t-t;i-i;f-f"],
  ["présylvien", "p-p;r-R;é-e;s-s;y-i;l-l;v-v;i-j;en-5"],
  ["présyndical", "p-p;r-R;é-e;s-s;yn-5;d-d;i-i;c-k;a-a;l-l"],
  [
    "présyndicaliste",
    "p-p;r-R;é-e;s-s;yn-5;d-d;i-i;c-k;a-a;l-l;i-i;s-s;t-t;e-ç",
  ],
  ["psychose", "p-p;s-s;y-i;ch-k;o-o;s-z;e-ç"],
  ["psychoses", "p-p;s-s;y-i;ch-k;o-o;s-z;e-ç;s-#"],
  ["quadrisyllabe", "q-k;u-w;a-a;d-d;r-R;i-i;s-s;y-i;ll-l;a-a;b-b;e-ç"],
  ["quadrisyllabes", "q-k;u-w;a-a;d-d;r-R;i-i;s-s;y-i;ll-l;a-a;b-b;e-ç;s-#"],
  [
    "quadrisyllabique",
    "q-k;u-w;a-a;d-d;r-R;i-i;s-s;y-i;ll-l;a-a;b-b;i-i;qu-k;e-ç",
  ],
  [
    "quadrisyllabiques",
    "q-k;u-w;a-a;d-d;r-R;i-i;s-s;y-i;ll-l;a-a;b-b;i-i;qu-k;e-ç;s-#",
  ],
  ["hum", "h-#;u-2;m"],
  ["humble", "h-#;um-1;b-b;l-l;e-ç"],
  ["humblement", "h-#;um-1;b-b;l-l;e-°;m-m;en-@;t-#"],
  ["humbles", "h-#;um-1;b-b;l-l;e-ç;s-#"],
  ["humblesse", "h-#;um-1;b-b;l-l;e-E;ss-s;e-ç"],
  ["humboldtite", "h-#;um-1;b-b;o-o;l-l;d-#;t-t;i-i;t-t;e-ç"],
  ["rhumerie", "r-R;h-#;u-o;m-m;e-°;r-R;i-i;e-#"],
  ["rhumeries", "r-R;h-#;u-o;m-m;e-°;r-R;i-i;e-#;s-#"],
  ["hunter", "h-#;u-2;n-n;t-t;e-2;r-R"],
  ["hunters", "h-#;u-2;n-n;t-t;e-2;r-R;s-#"],
  ["hurrah", "h-#;u-u;rr-R;a-a;h-#"],
  ["hurrahs", "h-#;u-u;rr-R;a-a;h-#;s-#"],
  ["hustings", "h-#;u-2;s-s;t-t;i-i;ng-G;s-#"],
  ["hyperonyme", "h-#;y-i;p-p;e-e;r-R;o-o;n-n;y-i;m-m;e-ç"],
  ["hyperonymes", "h-#;y-i;p-p;e-e;r-R;o-o;n-n;y-i;m-m;e-ç;s-#"],
  [
    "hypoexcitabilité",
    "h-#;y-i;p-p;o-o;e-E;x-x;c-s;i-i;t-t;a-a;b-b;i-i;l-l;i-i;t-t;é-e",
  ],
  [
    "hypoexcitabilités",
    "h-#;y-i;p-p;o-o;e-E;x-x;c-s;i-i;t-t;a-a;b-b;i-i;l-l;i-i;t-t;é-e;s-#",
  ],
  ["hypoexcitable", "h-#;y-i;p-p;o-o;e-E;x-x;c-s;i-i;t-t;a-a;b-b;l-l;e-ç"],
  ["hypoexcitables", "h-#;y-i;p-p;o-o;e-E;x-x;c-s;i-i;t-t;a-a;b-b;l-l;e-ç;s-#"],
  ["hypolimnion", "h-#;y-i;p-p;o-o;l-l;im;n-n;i-j;on-§"],
  ["hypolimnions", "h-#;y-i;p-p;o-o;l-l;im;n-n;i-j;on-§;s-#"],
  ["hypolimnique", "h-#;y-i;p-p;o-o;l-l;im;n-n;i-i;qu-k;e-ç"],
  ["hypolimniques", "h-#;y-i;p-p;o-o;l-l;im;n-n;i-i;qu-k;e-ç;s-#"],
  ["immangeable", "im-5;m-m;an-@;g-Z;e-#;a-a;b-b;l-l;e-ç"],
  ["immangeables", "im-5;m-m;an-@;g-Z;e-#;a-a;b-b;l-l;e-ç;s-#"],
  ["immanquable", "im-5;m-m;an-@;qu-k;a-a;b-b;l-l;e-ç"],
  ["immanquablement", "im-5;m-m;an-@;qu-k;a-a;b-b;l-l;e-°;m-m;en-@;t-#"],
  ["immanquables", "im-5;m-m;an-@;qu-k;a-a;b-b;l-l;e-ç;s-#"],
  ["immariable", "im-5;m-m;a-a;r-R;i-j;a-a;b-b;l-l;e-ç"],
  ["immariables", "im-5;m-m;a-a;r-R;i-j;a-a;b-b;l-l;e-ç;s-#"],
  ["impedimenta", "im-5;p-p;e-e;d-d;i-i;m-m;en-5;t-t;a-a"],
  ["imperator", "im-5;p-p;e-e;r-R;a-a;t-t;o-o;r-R"],
  ["imperators", "im-5;p-p;e-e;r-R;a-a;t-t;o-o;r-R;s-#"],
  ["imperium", "im-5;p-p;e-e;r-R;i-j;u-o;m-m"],
  ["imperiums", "im-5;p-p;e-e;r-R;i-j;u-o;m-m;s-#"],
  ["impresario", "im-5;p-p;r-R;e-e;s-s;a-a;r-R;i-j;o-o"],
  ["impresarios", "im-5;p-p;r-R;e-e;s-s;a-a;r-R;i-j;o-o;s-#"],
  ["imprésario", "im-5;p-p;r-R;é-e;s-s;a-a;r-R;i-j;o-o"],
  ["imprésarios", "im-5;p-p;r-R;é-e;s-s;a-a;r-R;i-j;o-o;s-#"],
  ["inlandsis", "in;l-l;an-@;d-d;s-s;i-i;s-s"],
  ["inpraesentia", "in-5;p-p;r-R;ae-E;s-z;en-@;t-s;i-j;a-a"],
  ["intelligentsia", "in-5;t-t;e-E;ll-l;i-i;g-Z;e-E;n-n;t-#;s-s;i-j;a-a"],
  ["intelligentsias", "in-5;t-t;e-E;ll-l;i-i;g-Z;e-E;n-n;t-#;s-s;i-j;a-a;s-#"],
  ["intelligentzia", "in-5;t-t;e-E;ll-l;i-i;g-Z;e-E;n-n;t-#;z-s;i-j;a-a"],
  ["intelligentzias", "in-5;t-t;e-E;ll-l;i-i;g-Z;e-E;n-n;t-#;z-s;i-j;a-a;s-#"],
  ["insatiété", "in-5;s-s;a-a;t-s;i-j;é-e;t-t;é-e"],
  ["insatiétés", "in-5;s-s;a-a;t-s;i-j;é-e;t-t;é-e;s-#"],
  ["satiété", "s-s;a-a;t-s;i-j;é-e;t-t;é-e"],
  ["satiétés", "s-s;a-a;t-s;i-j;é-e;t-t;é-e;s-#"],
  ["intérim", "in-5;t-t;é-e;r-R;im"],
  ["intérims", "in-5;t-t;é-e;r-R;im;s-#"],
  ["interrompt", "in-5;t-t;e-E;rr-R;om-§;p-#;t-#"],
  ["intraindividuel", "in-5;t-t;r-R;a;in-5;d-d;i-i;v-v;i-i;d-d;u-y;e-E;l-l"],
  [
    "intraindividuels",
    "in-5;t-t;r-R;a;in-5;d-d;i-i;v-v;i-i;d-d;u-y;e-E;l-l;s-#",
  ],
  [
    "intraindividuelle",
    "in-5;t-t;r-R;a;in-5;d-d;i-i;v-v;i-i;d-d;u-y;e-E;ll-l;e-ç",
  ],
  [
    "intraindividuelles",
    "in-5;t-t;r-R;a;in-5;d-d;i-i;v-v;i-i;d-d;u-y;e-E;ll-l;e-ç;s-#",
  ],
  ["ischémie", "i-i;s;ch-k;é-e;m-m;i-i;e-#"],
  ["ischémies", "i-i;s;ch-k;é-e;m-m;i-i;e-#;s-#"],
  ["ischurie", "i-i;s;ch-k;u-y;r-R;i-i;e-#"],
  ["ischuries", "i-i;s;ch-k;u-y;r-R;i-i;e-#;s-#"],
  ["isosémie", "i-i;s-z;o-o;s-s;é-e;m-m;i-i;e-#"],
  ["isosémies", "i-i;s-z;o-o;s-s;é-e;m-m;i-i;e-#;s-#"],
  ["isthme", "i-i;s-s;th-#;m-m;e-ç"],
  ["isthmes", "i-i;s-s;th-#;m-m;e-ç;s-#"],
  ["isthmique", "i-i;s-s;th-#;m-m;i-i;qu-k;e-ç"],
  ["isthmiques", "i-i;s-s;th-#;m-m;i-i;qu-k;e-ç;s-#"],
  ["jazz", "j-Z;a-a;zz-z"],
  ["jazzmen", "j-Z;a-a;zz-z;m-m;e-E;n-n"],
  ["jeep", "j-Z;ee-i;p-p"],
  ["jeeps", "j-Z;ee-i;p-p;s-#"],
  ["jersey", "j-Z;e-E;r-R;s-z;ey-E"],
  ["jerseys", "j-Z;e-E;r-R;s-z;ey-E;s-#"],
  ["jerseyais", "j-Z;e-E;r-R;s-z;e-E;y-j;ai-E;s-#"],
  ["jerseyaise", "j-Z;e-E;r-R;s-z;e-E;y-j;ai-E;s-z;e-ç"],
  ["jerseyaises", "j-Z;e-E;r-R;s-z;e-E;y-j;ai-E;s-z;e-ç;s-#"],
  ["jersiais", "j-Z;e-E;r-R;s-z;i-j;ai-E;s-#"],
  ["jersiaise", "j-Z;e-E;r-R;s-z;i-j;ai-E;s-z;e-ç"],
  ["jersiaises", "j-Z;e-E;r-R;s-z;i-j;ai-E;s-z;e-ç;s-#"],
  ["jeun", "j-Z;e-#;un-1"],
  ["jigger", "j-Z;i-i;gg-g;e-2;r-R"],
  ["jiggers", "j-Z;i-i;gg-g;e-2;r-R;s-#"],
  ["jota", "j-R;o-o;t-t;a-a"],
  ["jotas", "j-R;o-o;t-t;a-a;s-#"],
  ["julep", "j-Z;u-y;l-l;e-E;p-p"],
  ["jumbo", "j-Z;u-2;m-m;b-b;o-o"],
  ["jumbos", "j-Z;u-2;m-m;b-b;o-o;s-#"],
  ["jumping", "j-Z;u-2;m-m;p-p;i-i;ng-G"],
  ["jumpings", "j-Z;u-2;m-m;p-p;i-i;ng-G;s-#"],
  ["junker", "j-j;u;n-G;k-k;e-E;r-R"],
  ["junkers", "j-j;u;n-G;k-k;e-E;r-R;s-#"],
  [
    "louisphilippement",
    "l-l;ou-u;i-i;s-#;ph-f;i-i;l-l;i-i;pp-p;e-°;m-m;en-@;t-#",
  ],
  [
    "louisquatorzesque",
    "l-l;ou-u;i-i;s-#;qu-k;a-a;t-t;o-o;r-R;z-z;e-E;s-s;qu-k;e-ç",
  ],
  [
    "louisquatorzesques",
    "l-l;ou-u;i-i;s-#;qu-k;a-a;t-t;o-o;r-R;z-z;e-E;s-s;qu-k;e-ç;s-#",
  ],
  ["paon", "p-p;aon-@"],
  ["paonna", "p-p;a-a;o-#;nn-n;a-a"],
  ["paonnai", "p-p;a-a;o-#;nn-n;ai-e"],
  ["paonnaient", "p-p;a-a;o-#;nn-n;ai-E;ent-#"],
  ["paonnais", "p-p;a-a;o-#;nn-n;ai-E;s-#"],
  ["paonnait", "p-p;a-a;o-#;nn-n;ai-E;t-#"],
  ["paonnâmes", "p-p;a-a;o-#;nn-n;â-a;m-m;e-ç;s-#"],
  ["paonnant", "p-p;a-a;o-#;nn-n;an-@;t-#"],
  ["paonnas", "p-p;a-a;o-#;nn-n;a-a;s-#"],
  ["paonnasse", "p-p;a-a;o-#;nn-n;a-a;ss-s;e-ç"],
  ["paonnassent", "p-p;a-a;o-#;nn-n;a-a;ss-s;e-ç;nt-#"],
  ["paonnasses", "p-p;a-a;o-#;nn-n;a-a;ss-s;e-ç;s-#"],
  ["paonnassiez", "p-p;a-a;o-#;nn-n;a-a;ss-s;i-j;ez-e"],
  ["paonnassions", "p-p;a-a;o-#;nn-n;a-a;ss-s;i-j;on-§;s-#"],
  ["paonnât", "p-p;a-a;o-#;nn-n;â-a;t-#"],
  ["paonnâtes", "p-p;a-a;o-#;nn-n;â-a;t-t;e-ç;s-#"],
  ["paonne", "p-p;a-a;o-#;nn-n;e-ç"],
  ["paonné", "p-p;a-a;o-#;nn-n;é-e"],
  ["paonneau", "p-p;a-a;o-#;nn-n;eau-o"],
  ["paonneaux", "p-p;a-a;o-#;nn-n;eau-o;x-#"],
  ["paonnée", "p-p;a-a;o-#;nn-n;é-e;e-#"],
  ["paonnées", "p-p;a-a;o-#;nn-n;é-e;e-#;s-#"],
  ["paonnent", "p-p;a-a;o-#;nn-n;e-ç;nt-#"],
  ["paonner", "p-p;a-a;o-#;nn-n;er-e"],
  ["paonnera", "p-p;a-a;o-#;nn-n;e-°;r-R;a-a"],
  ["paonnerai", "p-p;a-a;o-#;nn-n;e-°;r-R;ai-e"],
  ["paonneraient", "p-p;a-a;o-#;nn-n;e-°;r-R;ai-E;ent-#"],
  ["paonnerais", "p-p;a-a;o-#;nn-n;e-°;r-R;ai-E;s-#"],
  ["paonnerait", "p-p;a-a;o-#;nn-n;e-°;r-R;ai-E;t-#"],
  ["paonneras", "p-p;a-a;o-#;nn-n;e-°;r-R;a-a;s-#"],
  ["paonnèrent", "p-p;a-a;o-#;nn-n;è-E;r-R;e-ç;nt-#"],
  ["paonnerez", "p-p;a-a;o-#;nn-n;e-°;r-R;ez-e"],
  ["paonneriez", "p-p;a-a;o-#;nn-n;e-°;r-R;i-j;ez-e"],
  ["paonnerions", "p-p;a-a;o-#;nn-n;e-°;r-R;i-j;on-§;s-#"],
  ["paonnerons", "p-p;a-a;o-#;nn-n;e-°;r-R;on-§;s-#"],
  ["paonneront", "p-p;a-a;o-#;nn-n;e-°;r-R;on-§;t-#"],
  ["paonnes", "p-p;a-a;o-#;nn-n;e-ç;s-#"],
  ["paonnés", "p-p;a-a;o-#;nn-n;é-e;s-#"],
  ["paonnez", "p-p;a-a;o-#;nn-n;ez-e"],
  ["paonniez", "p-p;a-a;o-#;nn-n;i-j;ez-e"],
  ["paonnions", "p-p;a-a;o-#;nn-n;i-j;on-§;s-#"],
  ["paonnons", "p-p;a-a;o-#;nn-n;on-§;s-#"],
  ["paons", "p-p;aon-@;s-#"],
  ["squattassiez", "s-s;q-k;u-w;a-a;tt-t;a-a;ss-s;i-j;ez-e"],
  ["squattassions", "s-s;q-k;u-w;a-a;tt-t;a-a;ss-s;i-j;on-§;s-#"],
  ["squattât", "s-s;q-k;u-w;a-a;tt-t;â-a;t-#"],
  ["squattâtes", "s-s;q-k;u-w;a-a;tt-t;â-a;t-t;e-ç;s-#"],
  ["squatte", "s-s;q-k;u-w;a-a;tt-t;e-ç"],
  ["squatté", "s-s;q-k;u-w;a-a;tt-t;é-e"],
  ["squattée", "s-s;q-k;u-w;a-a;tt-t;é-e;e-#"],
  ["squattées", "s-s;q-k;u-w;a-a;tt-t;é-e;e-#;s-#"],
  ["squattent", "s-s;q-k;u-w;a-a;tt-t;e-ç;nt-#"],
  ["squatter", "s-s;q-k;u-w;a-a;tt-t;er-e"],
  ["squattera", "s-s;q-k;u-w;a-a;tt-t;e-°;r-R;a-a"],
  ["squatterai", "s-s;q-k;u-w;a-a;tt-t;e-°;r-R;ai-e"],
  ["squatteraient", "s-s;q-k;u-w;a-a;tt-t;e-°;r-R;ai-E;ent-#"],
  ["squatterais", "s-s;q-k;u-w;a-a;tt-t;e-°;r-R;ai-E;s-#"],
  ["squatterait", "s-s;q-k;u-w;a-a;tt-t;e-°;r-R;ai-E;t-#"],
  ["squatteras", "s-s;q-k;u-w;a-a;tt-t;e-°;r-R;a-a;s-#"],
  ["squattèrent", "s-s;q-k;u-w;a-a;tt-t;è-E;r-R;e-ç;nt-#"],
  ["squatterez", "s-s;q-k;u-w;a-a;tt-t;e-°;r-R;ez-e"],
  ["squatteriez", "s-s;q-k;u-w;a-a;tt-t;e-°;r-R;i-j;ez-e"],
  ["squatterions", "s-s;q-k;u-w;a-a;tt-t;e-°;r-R;i-j;on-§;s-#"],
  ["squattérisation", "s-s;q-k;u-w;a-a;tt-t;é-e;r-R;i-i;s-z;a-a;t-s;i-j;on-§"],
  ["squatterons", "s-s;q-k;u-w;a-a;tt-t;e-°;r-R;on-§;s-#"],
  ["squatteront", "s-s;q-k;u-w;a-a;tt-t;e-°;r-R;on-§;t-#"],
  ["squattes", "s-s;q-k;u-w;a-a;tt-t;e-ç;s-#"],
  ["squattés", "s-s;q-k;u-w;a-a;tt-t;é-e;s-#"],
  ["squattez", "s-s;q-k;u-w;a-a;tt-t;ez-e"],
  ["squattiez", "s-s;q-k;u-w;a-a;tt-t;i-j;ez-e"],
  ["squattions", "s-s;q-k;u-w;a-a;tt-t;i-j;on-§;s-#"],
  ["squattons", "s-s;q-k;u-w;a-a;tt-t;on-§;s-#"],
  ["infectiez", "in-5;f-f;e-E;c-k;t-t;i-j;ez-e"],
  ["artiodactyle", "a-a;r-R;t-t;i-j;o-o;d-d;a-a;c-k;t-t;y-i;l-l;e-ç"],
  ["artiodactyles", "a-a;r-R;t-t;i-j;o-o;d-d;a-a;c-k;t-t;y-i;l-l;e-ç;s-#"],
  ["kabuki", "k-k;a-a;b-b;u-u;k-k;i-i"],
  ["kabukis", "k-k;a-a;b-b;u-u;k-k;i-i;s-#"],
  ["karateka", "k-k;a-a;r-R;a-a;t-t;e-e;k-k;a-a"],
  ["karatekas", "k-k;a-a;r-R;a-a;t-t;e-e;k-k;a-a;s-#"],
  ["karmans", "k-k;a-a;r-R;m-m;an;s-#"],
  ["kentia", "k-k;e-E;n;t-t;i-j;a-a"],
  ["kentias", "k-k;e-E;n;t-t;i-j;a-a;s-#"],
  ["keuper", "k-k;eu-2;p-p;e-2;r-R"],
  ["kibbutzique", "k-k;i-i;bb-b;u-u;t-t;z-s;i-i;qu-k;e-ç"],
  ["kibbutziques", "k-k;i-i;bb-b;u-u;t-t;z-s;i-i;qu-k;e-ç;s-#"],
  ["kipper", "k-k;i-i;pp-p;e-2;r-R"],
  ["kippers", "k-k;i-i;pp-p;e-2;r-R;s-#"],
  ["kleps", "k-k;l-l;e-E;p-b;s-s"],
  ["kremlin", "k-k;r-R;e-E;m-m;l-l;in-5"],
  ["kremlins", "k-k;r-R;e-E;m-m;l-l;in-5;s-#"],
  ["kreutzer", "k-k;r-R;eu-2;t-t;z-s;e-2;r-R"],
  ["kreutzers", "k-k;r-R;eu-2;t-t;z-s;e-2;r-R;s-#"],
  ["kriegspiel", "k-k;r-R;i;e-#;g-k;s-s;p-p;i-i;e-#;l-l"],
  ["kriegspiels", "k-k;r-R;i;e-#;g-k;s-s;p-p;i-i;e-#;l-l;s-#"],
  ["kuffieh", "k-k;u-y;ff-f;i-j;e-E;h-#"],
  ["kufieh", "k-k;u-y;f-f;i-j;e-E;h-#"],
  ["kumquat", "k-k;u-u;m-m;q-k;u-w;a-a;t-t"],
  ["kumquats", "k-k;u-u;m-m;q-k;u-w;a-a;t-t;s-#"],
  ["kyrie", "k-k;y-i;r-R;i-i;e-e"],
  ["kyries", "k-k;y-i;r-R;i-i;e-e;s-#"],
  ["lady", "l-l;a-E;d-d;y-i"],
  ["ladys", "l-l;a-E;d-d;y-i;s-#"],
  ["lamento", "l-l;a-a;m-m;e-E;n-n;t-t;o-o"],
  ["lamentos", "l-l;a-a;m-m;e-E;n-n;t-t;o-o;s-#"],
  ["landsturm", "l-l;an-@;d-d;s-S;t-t;u-u;r-R;m-m"],
  ["landsturms", "l-l;an-@;d-d;s-S;t-t;u-u;r-R;m-m;s-#"],
  ["landwehr", "l-l;an-@;d-d;w-v;e-E;h-#;r-R"],
  ["landwehrs", "l-l;an-@;d-d;w-v;e-E;h-#;r-R;s-#"],
  ["lawsonia", "l-l;aw-o;s-s;o-o;n-n;i-j;a-a"],
  ["lawsonias", "l-l;aw-o;s-s;o-o;n-n;i-j;a-a;s-#"],
  ["lazarone", "l-l;a-a;z-z;a-a;r-R;o-o;n-n;e-E"],
  ["lazarones", "l-l;a-a;z-z;a-a;r-R;o-o;n-n;e-E;s-#"],
  ["lazzarone", "l-l;a-a;z-d;z-z;a-a;r-R;o-o;n-n;e-E"],
  ["lazzarones", "l-l;a-a;z-d;z-z;a-a;r-R;o-o;n-n;e-E-;s-#"],
  ["leader", "l-l;ea-i;d-d;e-2;r-R"],
  ["leaders", "l-l;ea-i;d-d;e-2;r-R;s-#"],
  ["leadership", "l-l;ea-i;d-d;e-2;r-R;sh-S;i-i;p-p"],
  ["leaderships", "l-l;ea-i;d-d;e-2;r-R;sh-S;i-i;p-p;s-#"],
  ["leasing", "l-l;ea-i;s-z;i-i;ng-G"],
  ["leasings", "l-l;ea-i;s-z;i-i;ng-G;s-#"],
  ["legato", "l-l;e-e;g-g;a-a;t-t;o-o"],
  ["legatos", "l-l;e-e;g-g;a-a;t-t;o-o;s-#"],
  ["leggins", "l-l;e-E;gg-g;i;n-G;s-s"],
  ["leitmotiv", "l-l;e-a;i-j;t-t;m-m;o-o;t-t;i-i;v-f"],
  ["leitmotivs", "l-l;e-a;i-j;t-t;m-m;o-o;t-t;i-i;v-f;s-#"],
  ["lento", "l-l;e-E;n-n;t-t;o-o"],
  ["lentos", "l-l;e-E;n-n;t-t;o-o;s-#"],
  ["leucophlegmatie", "l-l;eu-2;c-k;o-o;ph-f;l-l;e-E;g-g;m-m;a-a;t-s;i-i;e-#"],
  [
    "leucophlegmaties",
    "l-l;eu-2;c-k;o-o;ph-f;l-l;e-E;g-g;m-m;a-a;t-s;i-i;e-#;s-#",
  ],
  ["lev", "l-l;e-E;v-v"],
  ["libeccio", "l-l;i-i;b-b;e-E;cc-t;i-j;o-o"],
  ["libeccios", "l-l;i-i;b-b;e-E;cc-t;i-j;o-o;s-#"],
  ["lieders", "l-l;i;e-#;d-d;e-2;r-R;s-#"],
  ["lienterie", "l-l;i-j;en-@;t-t;e-°;r-R;i-i;e-#"],
  ["lienteries", "l-l;i-j;en-@;t-t;e-°;r-R;i-i;e-#;s-#"],
  ["lientérie", "l-l;i-j;en-@;t-t;é-e;r-R;i-i;e-#"],
  ["lientéries", "l-l;i-j;en-@;t-t;é-e;r-R;i-i;e-#;s-#"],
  ["lientérique", "l-l;i-j;en-@;t-t;é-e;r-R;i-i;qu-k;e-ç"],
  ["lientériques", "l-l;i-j;en-@;t-t;é-e;r-R;i-i;qu-k;e-ç;s-#"],
  ["limnée", "l-l;im;n-n;é-e;e-#"],
  ["limnées", "l-l;im;n-n;é-e;e-#;s-#"],
  ["loggia", "l-l;o-o;gg-Z;i-j;a-a"],
  ["loggias", "l-l;o-o;gg-Z;i-j;a-a;s-#"],
  ["mangeure", "m-m;an-@;g-Z;e-#;u-y;r-R;e-ç"],
  ["mangeures", "m-m;an-@;g-Z;e-#;u-y;r-R;e-ç;s-#"],
  ["marchantiales", "m-m;a-a;r-R;ch-S;an-@;t-s;i-j;a-a;l-l;e-ç;s-#"],
  ["maremmatique", "m-m;a-a;r-R;e-E;mm-m;a-a;t-t;i-i;qu-k;e-ç"],
  ["maremmatiques", "m-m;a-a;r-R;e-E;mm-m;a-a;t-t;i-i;qu-k;e-ç;s-#"],
  ["maremme", "m-m;a-a;r-R;e-E;mm-m;e-ç"],
  ["maremmes", "m-m;a-a;r-R;e-E;mm-m;e-ç;s-#"],
  ["maroilles", "m-m;a-a;r-R;oi-6;ll-l;e-ç;s-#"],
  ["marsault", "m-m;a-a;r-R;s-s;au-o;lt-#"],
  ["marsaults", "m-m;a-a;r-R;s-s;au-o;lt-#;s-#"],
  ["marylands", "m-m;a-a;r-R;y-i;l-l;an-@;d-d;s-#"],
  ["mathesis", "m-m;a-a;t-t;h-#;e-e;s-z;i-i;s-s"],
  ["meeting", "m-m;ee-i;t-t;i-i;ng-G"],
  ["meetings", "m-m;ee-i;t-t;i-i;ng-G;s-#"],
  ["memento", "m-m;e-e;m-m;en-5;t-t;o-o"],
  ["mementos", "m-m;e-e;m-m;en-5;t-t;o-o;s-#"],
  ["menchevick", "m-m;e-E;n-n;ch-S;e-°;v-v;i-i;ck-k"],
  ["menchevicks", "m-m;e-E;n-n;ch-S;e-°;v-v;i-i;ck-k;s-#"],
  ["menchevik", "m-m;e-E;n-n;ch-S;e-e;v-v;i-i;k-k"],
  ["mencheviks", "m-m;e-E;n-n;ch-S;e-e;v-v;i-i;k-k;s-#"],
  ["mencheviste", "m-m;e-E;n-n;ch-S;e-°;v-v;i-i;s-s;t-t;e-ç"],
  ["mendélien", "m-m;en-5;d-d;é-e;l-l;i-j;en-5"],
  ["mendélienne", "m-m;en-5;d-d;é-e;l-l;i-j;e-E;nn-n;e-ç"],
  ["mendéliennes", "m-m;en-5;d-d;é-e;l-l;i-j;e-E;nn-n;e-ç;s-#"],
  ["menhir", "m-m;en;h-#;i-i;r-R"],
  ["menhirs", "m-m;en;h-#;i-i;r-R;s-#"],
  ["merguez", "m-m;e-E;r-R;gu-g;e-E;z-z"],
  ["mesa", "m-m;e-e;s-z;a-a"],
  ["mesas", "m-m;e-e;s-z;a-a;s-#"],
  ["mesdames", "m-m;es-e;d-d;a-a;m-m;e-ç;s-#"],
  ["mesdemoiselles", "m-m;es-e;d-d;e-°;m-m;oi-6;s-z;e-E;ll-l;e-ç;s-#"],
  [
    "michelangelesque",
    "m-m;i-i;ch-k;e-E;l-l;an-@;g-Z;e-°;l-l;e-E;s-s;qu-k;e-ç",
  ],
  [
    "michelangelesques",
    "m-m;i-i;ch-k;e-E;l-l;an-@;g-Z;e-°;l-l;e-E;s-s;qu-k;e-ç;s-#",
  ],
  ["michelangesque", "m-m;i-i;ch-k;e-E;l-l;an-@;g-Z;e-E;s-s;qu-k;e-ç"],
  ["michelangesques", "m-m;i-i;ch-k;e-E;l-l;an-@;g-Z;e-E;s-s;qu-k;e-ç;s-#"],
  ["microorchidie", "m-m;i-i;c-k;r-R;o-o;o-o;r-R;ch-k;i-i;d-d;i-i;e-#"],
  ["microwatt", "m-m;i-i;c-k;r-R;o;w-w;a-a;tt-t"],
  ["milady", "m-m;i-i;l-l;a-E;d-d;y-i"],
  ["miladys", "m-m;i-i;l-l;a-E;d-d;y-i;s-#"],
  ["millade", "m-m;ill-%;a-a;d-d;e-ç"],
  ["millades", "m-m;ill-%;a-a;d-d;e-ç;s-#"],
  ["millas", "m-m;ill-%;a-a;s-#"],
  ["millasse", "m-m;ill-%;a-a;ss-s;e-ç"],
  ["millasses", "m-m;ill-%;a-a;ss-s;e-ç;s-#"],
  ["milliasse", "m-m;ill-%;i-#;a-a;ss-s;e-ç"],
  ["milliasses", "m-m;ill-%;i-#;a-a;ss-s;e-ç;s-#"],
  ["miserere", "m-m;i-i;s-z;e-e;r-R;e-e;r-R;e-e"],
  ["misereres", "m-m;i-i;s-z;e-e;r-R;e-e;r-R;e-e;s-#"],
  ["moderato", "m-m;o-o;d-d;e-e;r-R;a-a;t-t;o-o"],
  ["moderatos", "m-m;o-o;d-d;e-e;r-R;a-a;t-t;o-o;s-#"],
  ["monose", "m-m;o-o;n-n;o-o;s-z;e-ç"],
  ["monoses", "m-m;o-o;n-n;o-o;s-z;e-ç;s-#"],
  ["montgolfière", "m-m;on-§;t-#;g-g;o-o;l-l;f-f;i-j;è-E;r-R;e-ç"],
  ["montgolfières", "m-m;on-§;t-#;g-g;o-o;l-l;f-f;i-j;è-E;r-R;e-ç;s-#"],
  ["montjoie", "m-m;on-§;t-#;j-Z;oi-6;e-#"],
  ["montjoies", "m-m;on-§;t-#;j-Z;oi-6;e-#;s-#"],
  ["montmartrois", "m-m;on-§;t-#;m-m;a-a;r-R;t-t;r-R;oi-6;s-#"],
  ["montmartroise", "m-m;on-§;t-#;m-m;a-a;r-R;t-t;r-R;oi-6;s-z;e-ç"],
  ["montmartroises", "m-m;on-§;t-#;m-m;a-a;r-R;t-t;r-R;oi-6;s-z;e-ç;s-#"],
  ["montmorency", "m-m;on-§;t-#;m-m;o-o;r-R;en-@;c-s;y-i"],
  ["montmorencys", "m-m;on-§;t-#;m-m;o-o;r-R;en-@;c-s;y-i;s-#"],
  ["montparnassien", "m-m;on-§;t-#;p-p;a-a;r-R;n-n;a-a;ss-s;i-j;en-5"],
  [
    "montparnassienne",
    "m-m;on-§;t-#;p-p;a-a;r-R;n-n;a-a;ss-s;i-j;e-E;nn-n;e-ç",
  ],
  [
    "montparnassiennes",
    "m-m;on-§;t-#;p-p;a-a;r-R;n-n;a-a;ss-s;i-j;e-E;nn-n;e-ç;s-#",
  ],
  ["montparnassiens", "m-m;on-§;t-#;p-p;a-a;r-R;n-n;a-a;ss-s;i-j;en-5;s-#"],
  ["montparno", "m-m;on-§;t-#;p-p;a-a;r-R;n-n;o-o"],
  ["montrachet", "m-m;on-§;t-#;r-R;a-a;ch-S;et-E"],
  ["montrachets", "m-m;on-§;t-#;r-R;a-a;ch-S;e-E;t-#;s-#"],
  ["motoball", "m-m;o-o;t-t;o-o;b-b;a-o;ll-l"],
  ["motoclub", "m-m;o-o;t-t;o-o;c-k;l-l;u-2;b-b"],
  ["motoclubs", "m-m;o-o;t-t;o-o;c-k;l-l;u-2;b-b;s-#"],
  ["moucharabieh", "m-m;ou-u;ch-S;a-a;r-R;a-a;b-b;i-j;e-e;h-#"],
  [
    "mucopolysaccharide",
    "m-m;u-y;c-k;o-o;p-p;o-o;l-l;y-i;s-s;a-a;cc-k;h-#;a-a;r-R;i-i;d-d;e-ç",
  ],
  [
    "mucopolysaccharides",
    "m-m;u-y;c-k;o-o;p-p;o-o;l-l;y-i;s-s;a-a;cc-k;h-#;a-a;r-R;i-i;d-d;e-ç;s-#",
  ],
  ["muleta", "m-m;u-u;l-l;e-e;t-t;a-a"],
  ["muletas", "m-m;u-u;l-l;e-e;t-t;a-a;s-#"],
  ["néoberkeleyien", "n-n;é-e;o-o;b-b;e-E;r-R;k-k;e-°;l-l;e-E;y-j;i-i;en-5"],
  [
    "néoberkeleyiens",
    "n-n;é-e;o-o;b-b;e-E;r-R;k-k;e-°;l-l;e-E;y-j;i-i;en-5;s-#",
  ],
  ["moere", "m-m;o-w;e-E;r-R;e-ç"],
  ["moeres", "m-m;o-w;e-E;r-R;e-ç;s-#"],
  ["nietzschéen", "n-n;i-i;e-#;t-t;zsch-S;é-e;en-5"],
  ["nietzschéenne", "n-n;i-i;e-#;t-t;zsch-S;é-e;e-E;nn-n;e-ç"],
  ["nietzschéennes", "n-n;i-i;e-#;t-t;zsch-S;é-e;e-E;nn-n;e-ç;s-#"],
  ["nietzschéens", "n-n;i-i;e-#;t-t;zsch-S;é-e;en-5;s-#"],
  ["nietzschéisme", "n-n;i-i;e-#;t-t;zsch-S;é-e;i-i;s-s;m-m;e-ç"],
  ["nietzschéismes", "n-n;i-i;e-#;t-t;zsch-S;é-e;i-i;s-s;m-m;e-ç;s-#"],
  ["novillero", "n-n;o-o;v-v;i-i;ll-j;e-e;r-R;o-o"],
  ["novilleros", "n-n;o-o;v-v;i-i;ll-j;e-e;r-R;o-o;s-#"],
  ["nuraghe", "n-n;u-u;r-R;a-a;g-g;h-#;e-ç"],
  ["nuraghes", "n-n;u-u;r-R;a-a;g-g;h-#;e-ç;s-#"],
  ["nurse", "n-n;u-2;r-R;s-s;e-ç"],
  ["nursery", "n-n;u-2;r-R;s-s;e-°;r-R;y-i"],
  ["nurserys", "n-n;u-2;r-R;s-s;e-°;r-R;y-i;s-#"],
  ["nurses", "n-n;u-2;r-R;s-s;e-ç;s-#"],
  ["odontogenèse", "o-o;d-d;on-§;t-t;o-o;g-Z;e-e;n-n;è-E;s-z;e-ç"],
  ["oedématie", "oe-2;d-d;é-e;m-m;a-a;t-s;i-i;e-#"],
  ["oedématié", "oe-2;d-d;é-e;m-m;a-a;t-s;i-j;é-e"],
  ["oestral", "oe-E;s-s;t-t;r-R;a-a;l-l"],
  ["oestrale", "oe-E;s-s;t-t;r-R;a-a;l-l;e-ç"],
  ["oestrales", "oe-E;s-s;t-t;r-R;a-a;l-l;e-ç;s-#"],
  ["oestraux", "oe-E;s-s;t-t;r-R;au-o;x-#"],
  ["oestre", "oe-E;s-s;t-t;r-R;e-ç"],
  ["oestres", "oe-E;s-s;t-t;r-R;e-ç;s-#"],
  ["oestrus", "oe-E;s-s;t-t;r-R;u-y;s-s"],
  ["oille", "o-o;ill-j;e-ç"],
  ["oilles", "o-o;ill-j;e-ç;s-#"],
  ["oleum", "o-o;l-l;e;u-o;m-m"],
  ["oleums", "o-o;l-l;e;u-o;m-m;s-#"],
  ["olim", "o-o;l-l;im"],
  ["opisthocoele", "o-o;p-p;i-i;s-s;t-t;h-#;o-o;c-s;oe-E;l-l;e-ç"],
  ["opisthocoeles", "o-o;p-p;i-i;s-s;t-t;h-#;o-o;c-s;oe-E;l-l;e-ç;s-#"],
  ["optime", "o-o;p-p;t-t;i-i;m-m;e-ç"],
  ["orang", "o-o;r-R;an-@;g-#"],
  ["orangs", "o-o;r-R;an-@;gs-#"],
  ["ostéosynthèse", "o-o;s-s;t-t;é-e;o-o;s-s;yn-5;t-t;h-#;è-E;s-z;e-ç"],
  ["ostéosynthèses", "o-o;s-s;t-t;é-e;o-o;s-s;yn-5;t-t;h-#;è-E;s-z;e-ç;s-#"],
  ["ouations", "ou-u;a-a;t-s;i-j;on-§;s-#"],
  ["out", "o-a;u-u;t-t"],
  ["outlaw", "o-a;u-u;t-t;l-l;aw-o;"],
  ["outlaws", "o-a;u-u;t-t;l-l;aw-o;s-#"],
  ["output", "o-a;u-u;t-t;p-p;u;t-t"],
  ["outputs", "o-a;u-u;t-t;p-p;u;t-t;s-#"],
  ["outsider", "o-a;u-u;t-t;s-s;i-i;d-d;e-E;r-R"],
  ["outsiders", "o-a;u-u;t-t;s-s;i-i;d-d;e-E;r-R;s-#"],
  ["ouzbek", "ou-u;z-z;b-b;e-E;k-k"],
  ["oxford", "o-o;x-x;f-f;o-o;r-R;d-d"],
  ["oxfords", "o-o;x-x;f-f;o-o;r-R;d-d;s-#"],
  ["pacemaker", "p-p;a-E;c-s;e-#;m-m;a-E;k-k;e-2;r-R"],
  ["pacemakers", "p-p;a-E;c-s;e-#;m-m;a-E;k-k;e-2;r-R;s-#"],
  ["pacfung", "p-p;a-a;c-k;f-f;un-§;g-#"],
  ["pacfungs", "p-p;a-a;c-k;f-f;un-§;gs-#"],
  ["palmers", "p-p;a-a;l-l;m-m;e-E;r-R;s-#"],
  ["pancake", "p-p;an;c-k;a-E;k-k;e-ç"],
  ["pancakes", "p-p;an;c-k;a-E;k-k;e-ç;s-#"],
  ["parfums", "p-p;a-a;r-R;f-f;um-1;s-#"],
  ["parisyllabe", "p-p;a-a;r-R;i-i;s-s;y-i;ll-l;a-a;b-b;e-ç"],
  ["parisyllabes", "p-p;a-a;r-R;i-i;s-s;y-i;ll-l;a-a;b-b;e-ç;s-#"],
  ["parisyllabique", "p-p;a-a;r-R;i-i;s-s;y-i;ll-l;a-a;b-b;i-i;qu-k;e-ç"],
  ["parisyllabiques", "p-p;a-a;r-R;i-i;s-s;y-i;ll-l;a-a;b-b;i-i;qu-k;e-ç;s-#"],
  ["parpaing", "p-p;a-a;r-R;p-p;ain-5;g-#"],
  ["parpaings", "p-p;a-a;r-R;p-p;ain-5;gs-#"],
  ["parturiente", "p-p;a-a;r-R;t-t;u-y;r-R;i-j;en-@;t-t;e-ç"],
  ["parturientes", "p-p;a-a;r-R;t-t;u-y;r-R;i-j;en-@;t-t;e-ç;s-#"],
  ["paseo", "p-p;a-a;s-s;e-e;o-o"],
  ["paseos", "p-p;a-a;s-s;e-e;o-o;s-#"],
  ["pasionnaria", "p-p;a-a;s-s;i-j;o-o;nn-n;a-a;r-R;i-j;a-a"],
  ["passegrande", "p-p;a-a;ss-s;e-°;g-g;r-R;an-@;d-d;e-ç"],
  ["passim", "p-p;a-a;ss-s;im"],
  ["patchwork", "p-p;a-a;t-t;ch-S;w-w;o-2;r-R;k-k"],
  ["patchworks", "p-p;a-a;t-t;ch-S;w-w;o-2;r-R;k-k;s-#"],
  ["patiemment", "p-p;a-a;t-s;i-j;e-a;mm-m;en-@;t-#"],
  ["pechère", "p-p;e-°;ch-S;è-E;r-R;e-ç"],
  ["pechurane", "p-p;e-°;ch-S;u-y;r-R;a-a;n-n;e-ç"],
  ["pedestrian", "p-p;e-E;d-d;e-E;s-s;t-t;r-R;i-/;an-@"],
  ["pedestrianisme", "p-p;e-E;d-d;e-E;s-s;t-t;r-R;i-/;a-a;n-n;i-i;s-s;m-m;e-ç"],
  [
    "pedestrianismes",
    "p-p;e-E;d-d;e-E;s-s;t-t;r-R;i-/;a-a;n-n;i-i;s-s;m-m;e-ç;s-#",
  ],
  ["pedestrians", "p-p;e-E;d-d;e-E;s-s;t-t;r-R;i-/;an-@;s-#"],
  ["pedigree", "p-p;e-e;d-d;i-i;g-g;r-R;ee-e"],
  ["pedigrees", "p-p;e-e;d-d;i-i;g-g;r-R;ee-e;s-#"],
  ["pedum", "p-p;e-E;d-d;u-o;m-m"],
  ["pedums", "p-p;e-E;d-d;u-o;m-m;s-#"],
  ["peeling", "p-p;ee-i;l-l;i-i;ng-G"],
  ["peelings", "p-p;ee-i;l-l;i-i;ng-G;s-#"],
  ["pélasgien", "p-p;é-e;l-l;a-a;s-#;g-Z;i-j;en-5"],
  ["pélasgienne", "p-p;é-e;l-l;a-a;s-#;g-Z;i-j;e-E;nn-n;e-ç"],
  ["pélasgiennes", "p-p;é-e;l-l;a-a;s-#;g-Z;i-j;e-E;nn-n;e-ç;s-#"],
  ["pélasgiens", "p-p;é-e;l-l;a-a;s-#;g-Z;i-j;en-5;s-#"],
  ["pélasgique", "p-p;é-e;l-l;a-a;s-#;g-Z;i-i;qu-k;e-ç"],
  ["pélasgiques", "p-p;é-e;l-l;a-a;s-#;g-Z;i-i;qu-k;e-ç;s-#"],
  ["pence", "p-p;e-E;n-n;c-s;e-ç"],
  ["pences", "p-p;e-E;n-n;c-s;e-ç;s-#"],
  ["pendjabi", "p-p;e-E;n-n;d-d;j-Z;a-a;b-b;i-i"],
  ["pendjabis", "p-p;e-E;n-n;d-d;j-Z;a-a;b-b;i-i;s-#"],
  ["pentathlien", "p-p;en-5;t-t;a-a;t-t;h-#;l-l;i-j;en-5"],
  ["pentathlienne", "p-p;en-5;t-t;a-a;t-t;h-#;l-l;i-j;e-E;nn-n;e-ç"],
  ["pep", "p-p;e-E;p-p"],
  ["péréquations", "p-p;é-e;r-R;é-e;q-k;u-w;a-a;t-s;i-j;on-§;s-#"],
  ["peroxyde", "p-p;e-E;r-R;o-o;x-x;y-i;d-d;e-ç"],
  ["peroxydes", "p-p;e-E;r-R;o-o;x-x;y-i;d-d;e-ç;s-#"],
  ["peroxygéné", "p-p;e-E;r-R;o-o;x-x;y-i;g-Z;é-e;n-n;é-e"],
  ["persil", "p-p;e-E;r-R;s-s;i-i;l-l"],
  ["persils", "p-p;e-E;r-R;s-s;i-i;l-l;s-#"],
  ["peseta", "p-p;e-e;s-z;e-e;t-t;a-a"],
  ["pesetas", "p-p;e-e;s-z;e-e;t-t;a-a;s-#"],
  ["peso", "p-p;e-e;s-z;o-o"],
  ["pesos", "p-p;e-e;s-z;o-o;s-#"],
  ["philibeg", "ph-f;i-i;l-l;i-i;b-b;e-E;g-g"],
  ["philosémitisme", "ph-f;i-i;l-l;o-o;s-s;é-e;m-m;i-i;t-t;i-i;s-s;m-m;e-ç"],
  ["phoenicure", "ph-f;oe-e;n-n;i-i;c-k;u-y;r-R;e-ç"],
  ["phoenicures", "ph-f;oe-e;n-n;i-i;c-k;u-y;r-R;e-ç;s-#"],
  ["phytocoenose", "ph-f;y-i;t-t;o-o;c-s;oe-e;n-n;o-o;s-z;e-ç"],
  ["pier", "p-p;i-i;e-#;r-R"],
  ["piers", "p-p;i-i;e-#;r-R;s-#"],
  ["pietà", "p-p;i-j;e-e;t-t;à-a"],
  ["pietàs", "p-p;i-j;e-e;t-t;à-a;s-#"],
  ["pipits", "p-p;i-i;p-p;i-i;t-t;s-#"],
  ["pirojki", "p-p;i-i;r-R;o-o;j-S;k-k;i-i"],
  ["pistolero", "p-p;i-i;s-s;t-t;o-o;l-l;e-e;r-R;o-o"],
  ["piu", "p-p;i-j;u-u"],
  ["pizzeria", "p-p;i-i;z-d;z-z;e-e;r-R;i-j;a-a"],
  ["pizzerias", "p-p;i-i;z-d;z-z;e-e;r-R;i-j;a-a;s-#"],
  ["placebo", "p-p;l-l;a-a;c-s;e-e;b-b;o-o"],
  ["placebos", "p-p;l-l;a-a;c-s;e-e;b-b;o-o;s-#"],
  ["plastisol", "p-p;l-l;a-a;s-s;t-t;i-i;s-s;o-o;l-l"],
  ["plastisols", "p-p;l-l;a-a;s-s;t-t;i-i;s-s;o-o;l-l;s-#"],
  ["plenum", "p-p;l-l;e-e;n-n;u-o;m-m"],
  ["plenums", "p-p;l-l;e-e;n-n;u-o;m-m;s-#"],
  ["poecile", "p-p;oe-e;c-s;i-i;l-l;e-ç"],
  ["poeciles", "p-p;oe-e;c-s;i-i;l-l;e-ç;s-#"],
  ["poecilocytose", "p-p;oe-e;c-s;i-i;l-l;o-o;c-s;y-i;t-t;o-o;s-z;e-ç"],
  ["poecilogonie", "p-p;oe-e;c-s;i-i;l-l;o-o;g-g;o-o;n-n;i-i;e-#"],
  ["pogrom", "p-p;o-o;g-g;r-R;om"],
  ["pogroms", "p-p;o-o;g-g;r-R;om;s-#"],
  ["polenta", "p-p;o-o;l-l;e-E;n-n;t-t;a-a"],
  ["polentas", "p-p;o-o;l-l;e-E;n-n;t-t;a-a;s-#"],
  ["policemans", "p-p;o-o;l-l;i-i;c-s;e-#3;m-m;an;s-#"],
  ["poljé", "p-p;o-o;l-l;j-j;é-e"],
  ["poljés", "p-p;o-o;l-l;j-j;é-e;s-#"],
  ["pomoerium", "p-p;o-o;m-m;oe-e;r-R;i-j;u-o;m-m"],
  ["pomoeriums", "p-p;o-o;m-m;oe-e;r-R;i-j;u-o;m-m;s-#"],
  ["porters", "p-p;o-o;r-R;t-t;e-E;r-R;s-#"],
  ["portlands", "p-p;o-o;r-R;t-t;l-l;an-@;d-d;s-#"],
  ["pouls", "p-p;ou-u;l-#;s-#"],
  ["presbytie", "p-p;r-R;e-E;s-s;b-b;y-i;t-s;i-i;e-#"],
  ["presbyties", "p-p;r-R;e-E;s-s;b-b;y-i;t-s;i-i;e-#;s-#"],
  ["prétentieuse", "p-p;r-R;é-e;t-t;en-@;t-s;i-j;eu-2;s-z;e-ç"],
  [
    "prétentieusement",
    "p-p;r-R;é-e;t-t;en-@;t-s;i-j;eu-2;s-z;e-°;m-m;en-@;t-#",
  ],
  ["prétentieuses", "p-p;r-R;é-e;t-t;en-@;t-s;i-j;eu-2;s-z;e-ç;s-#"],
  ["prétentieux", "p-p;r-R;é-e;t-t;en-@;t-s;i-j;eu-2;x-#"],
  ["primatie", "p-p;r-R;i-i;m-m;a-a;t-s;i-i;e-#"],
  ["primaties", "p-p;r-R;i-i;m-m;a-a;t-s;i-i;e-#;s-#"],
  ["procuratie", "p-p;r-R;o-o;c-k;u-y;r-R;a-a;t-s;i-i;e-#"],
  ["procuraties", "p-p;r-R;o-o;c-k;u-y;r-R;a-a;t-s;i-i;e-#;s-#"],
  ["proembryon", "p-p;r-R;o;em-@;b-b;r-R;y-j;on-§"],
  ["proenzyme", "p-p;r-R;o;en-@;z-z;y-i;m-m;e-ç"],
  ["prompt", "p-p;r-R;om-§;pt-#"],
  ["promptitude", "p-p;r-R;om-§;p-#;t-t;i-i;t-t;u-y;d-d;e-ç"],
  ["promptitudes", "p-p;r-R;om-§;p-#;t-t;i-i;t-t;u-y;d-d;e-ç;s-#"],
  ["prompto", "p-p;r-R;om-§;p-#;t-t;o-o"],
  ["prompts", "p-p;r-R;om-§;p-#;t-#;s-#"],
  ["pronuclei", "p-p;r-R;o-o;n-n;u-y;c-k;l-l;e-E;i"],
  ["pronunciamento", "p-p;r-R;o-o;n-n;un;c-s;i-j;a-a;m-m;e-E;n;t-t;o-o"],
  ["pronunciamentos", "p-p;r-R;o-o;n-n;un;c-s;i-j;a-a;m-m;e-E;n;t-t;o-o;s-#"],
  ["pronunciamiento", "p-p;r-R;o-o;n-n;un;c-s;i-j;a-a;m-m;i-j;e-E;n;t-t;o-o"],
  [
    "pronunciamientos",
    "p-p;r-R;o-o;n-n;un;c-s;i-j;a-a;m-m;i-j;e-E;n;t-t;o-o;s-#",
  ],
  ["proscenium", "p-p;r-R;o-o;sc-s;e-e;n-n;i-j;u-o;m-m"],
  ["prosceniums", "p-p;r-R;o-o;sc-s;e-e;n-n;i-j;u-o;m-m;s-#"],
  ["prosémite", "p-p;r-R;o-o;s-s;é-e;m-m;i-i;t-t;e-ç"],
  ["proteus", "p-p;r-R;o-o;t-t;e;u-y;s-s"],
  ["protoiodure", "p-p;r-R;o-o;t-t;o;i-j;o-o;d-d;u-y;r-R;e-ç"],
  ["protoionique", "p-p;r-R;o-o;t-t;o;i-j;o-o;n-n;i-i;qu-k;e-ç"],
  ["protopterus", "p-p;r-R;o-o;t-t;o-o;p-p;t-t;e-e;r-R;u-y;s-s"],
  ["pudding", "p-p;u-u;dd-d;i-i;ng-G"],
  ["puddings", "p-p;u-u;dd-d;i-i;ng-G;s-#"],
  ["pugnace", "p-p;u-y;gn;a-a;c-s;e-ç"],
  ["pugnaces", "p-p;u-y;gn;a-a;c-s;e-ç;s-#"],
  ["pugnacité", "p-p;u-y;gn;a-a;c-s;i-i;t-t;é-e"],
  ["pugnacités", "p-p;u-y;gn;a-a;c-s;i-i;t-t;é-e;s-#"],
  ["pullmans", "p-p;u-u;ll-l;m-m;an;s-#"],
  ["punch", "p-p;u-2;n;ch-S"],
  ["puncheur", "p-p;u-2;n;ch-S;eu-2;r-R"],
  ["puncheurs", "p-p;u-2;n;ch-S;eu-2;r-R;s-#"],
  ["punchs", "p-p;u-2;n;ch-S;s-#"],
  ["pupazzo", "p-p;u-u;p-p;a-a;z-d;z-z;o-o"],
  ["pupazzos", "p-p;u-u;p-p;a-a;z-d;z-z;o-o;s-#"],
  ["putamens", "p-p;u-u;t-t;a-a;m-m;e-E;n-n;s-#"],
  ["putiet", "p-p;u-u;t-t;i-j;et-E"],
  ["putiets", "p-p;u-u;t-t;i-j;e-E;t-#;s-#"],
  ["putsch", "p-p;u-u;t-t;sch-S"],
  ["putschiste", "p-p;u-u;t-t;sch-S;i-i;s-s;t-t;e-ç"],
  ["putschistes", "p-p;u-u;t-t;sch-S;i-i;s-s;t-t;e-ç;s-#"],
  ["putschs", "p-p;u-u;t-t;sch-S;s-#"],
  ["puzzle", "p-p;u-2;zz-z;l-l;e-ç"],
  ["puzzles", "p-p;u-2;zz-z;l-l;e-ç;s-#"],
  ["vaquero", "v-v;a-a;qu-k;e-e;r-R;o-o"],
  ["vaqueros", "v-v;a-a;qu-k;e-e;r-R;o-o;s-#"],
  ["velarium", "v-v;e-e;l-l;a-a;r-R;i-j;u-o;m-m"],
  ["velariums", "v-v;e-e;l-l;a-a;r-R;i-j;u-o;m-m;s-#"],
  ["velum", "v-v;e-e;l-l;u-o;m-m"],
  ["velums", "v-v;e-e;l-l;u-o;m-m;s-#"],
  ["veniat", "v-v;e-e;n-n;i-j;a-a;t-t"],
  ["vergeure", "v-v;e-E;r-R;g-Z;e-#;u-y;r-R;e-ç"],
  ["vergeures", "v-v;e-E;r-R;g-Z;e-#;u-y;r-R;e-ç;s-#"],
  ["ukase", "u-u;k-k;a-a;s-z;e-ç"],
  ["ukases", "u-u;k-k;a-a;s-z;e-ç;s-#"],
  ["ukulele", "u-y;k-k;u-y;l-l;e-e;l-l;e-e"],
  ["ukulelé", "u-y;k-k;u-y;l-l;e-e;l-l;é-e"],
  ["ukuleles", "u-y;k-k;u-y;l-l;e-e;l-l;e-e;s-#"],
  ["ukulelés", "u-y;k-k;u-y;l-l;e-e;l-l;é-e;s-#"],
  ["ulster", "u-2;l-l;s-s;t-t;e-E;r-R"],
  ["ulsters", "u-2;l-l;s-s;t-t;e-E;r-R;s-#"],
  ["unguéal", "un-§;g-g;u-y;é-e;a-a;l-l"],
  ["unguéale", "un-§;g-g;u-y;é-e;a-a;l-l;e-ç"],
  ["unguéales", "un-§;g-g;u-y;é-e;a-a;l-l;e-ç;s-#"],
  ["unguéaux", "un-§;g-g;u-y;é-e;au-o;x-#"],
  ["unguis", "un-§;g-g;u-y;i-i;s-#"],
  ["upsilon", "u-y;p-p;s-s;i-i;l-l;on"],
  ["upsilons", "u-y;p-p;s-s;i-i;l-l;on;s-#"],
  ["quaker", "q-k;u-w;a-E;k-k;e-2;r-R"],
  ["quakereresse", "q-k;u-w;a-E;k-k;e-°;r-R;e-E;r-R;e-E;ss-s;e-ç"],
  ["quakereresses", "q-k;u-w;a-E;k-k;e-°;r-R;e-E;r-R;e-E;ss-s;e-ç;s-#"],
  ["quakerien", "q-k;u-w;a-E;k-k;e-°;r-R;i-j;en-5"],
  ["quakerisme", "q-k;u-w;a-E;k-k;e-°;r-R;i-i;s-s;m-m;e-ç"],
  ["quakerismes", "q-k;u-w;a-E;k-k;e-°;r-R;i-i;s-s;m-m;e-ç;s-#"],
  ["quakeriste", "q-k;u-w;a-E;k-k;e-°;r-R;i-i;s-s;t-t;e-ç"],
  ["quakers", "q-k;u-w;a-E;k-k;e-2;r-R;s-#"],
  [
    "quattrocentiste",
    "q-k;u-w;a-a;tt-t;r-R;o-o;c-s;e-E;n-n;t-t;i-i;s-s;t-t;e-ç",
  ],
  [
    "quattrocentistes",
    "q-k;u-w;a-a;tt-t;r-R;o-o;c-s;e-E;n-n;t-t;i-i;s-s;t-t;e-ç;s-#",
  ],
  ["quattrocento", "q-k;u-w;a-a;tt-t;r-R;o-o;c-s;e-E;n-n;t-t;o-o"],
  ["quattrocentos", "q-k;u-w;a-a;tt-t;r-R;o-o;c-s;e-E;n-n;t-t;o-o;s-#"],
  ["quia", "q-k;u-y;i-j;a-a"],
  ["quiescent", "q-k;u-y;i-j;e-E;s-s;c-s;en-@;t-#"],
  ["quiescente", "q-k;u-y;i-j;e-E;s-s;c-s;en-@;t-t;e-ç"],
  ["quiescentes", "q-k;u-y;i-j;e-E;s-s;c-s;en-@;t-t;e-ç;s-#"],
  ["quiescents", "q-k;u-y;i-j;e-E;s-s;c-s;en-@;t-#;s-#"],
  ["quinquagone", "q-k;u-y;in-5;qu-k;a-a;g-g;o-o;n-n;e-ç"],
  ["quotientmètre", "qu-k;o-o;t-s;i-j;en-@;t-#;m-m;è-E;t-t;r-R;e-ç"],
  ["racers", "r-R;a-E;c-s;e-2;r-R;s-#"],
  ["racket", "r-R;a-a;ck-k;e-E;t-t"],
  ["radioisotope", "r-R;a-a;d-d;i-j;o;i-i;s-z;o-o;t-t;o-o;p-p;e-ç"],
  ["radioreporters", "r-R;a-a;d-d;i-j;o-o;r-R;e-°;p-p;o-o;r-R;t-t;e-E;r-R;s-#"],
  ["radoub", "r-R;a-a;d-d;ou-u;b-#"],
  ["radoubs", "r-R;a-a;d-d;ou-u;bs-#"],
  ["rafflesia", "r-R;a-a;ff-f;l-l;e-e;s-z;i-j;a-a"],
  ["rafflesias", "r-R;a-a;ff-f;l-l;e-e;s-z;i-j;a-a;s-#"],
  ["railroute", "r-R;a;il-j;r-R;ou-u;t-t;e-ç"],
  ["rallentando", "r-R;a-a;ll-l;e-E;n-n;t-t;an;d-d;o-o"],
  ["realia", "r-R;e-e;a-a;l-l;i-j;a-a"],
  ["recognitif", "r-R;e-°;c-k;o-o;g-g;n-n;i-i;t-t;i-i;f-f"],
  ["recognitifs", "r-R;e-°;c-k;o-o;g-g;n-n;i-i;t-t;i-i;f-f;s-#"],
  ["recognition", "r-R;e-°;c-k;o-o;g-g;n-n;i-i;t-s;i-j;on-§"],
  ["recognitions", "r-R;e-°;c-k;o-o;g-g;n-n;i-i;t-s;i-j;on-§;s-#"],
  ["recognitive", "r-R;e-°;c-k;o-o;g-g;n-n;i-i;t-t;i-i;v-v;e-ç"],
  ["recognitives", "r-R;e-°;c-k;o-o;g-g;n-n;i-i;t-t;i-i;v-v;e-ç;s-#"],
  ["recordmans", "r-R;e-°;c-k;o-o;r-R;d-d;m-m;an;s-#"],
  ["recordwomans", "r-R;e-°;c-k;o-o;r-R;d-d;w-w;o-o;m-m;an;s-#"],
  ["redorment", "r-R;e-°;d-d;o-o;r-R;m-m;e-ç;nt-#"],
  ["reg", "r-R;e-E;g-g"],
  ["regency", "r-R;e-e;g-Z;e-E;n-n;c-s;y-i"],
  ["reichstag", "r-R;e-a;i-j;ch-S;s-s;t-t;a-a;g-g"],
  ["reichstags", "r-R;e-a;i-j;ch-S;s-s;t-t;a-a;g-g;s-#"],
  ["reître", "r-R;eî-E;t-t;r-R;e-ç"],
  ["reîtres", "r-R;eî-E;t-t;r-R;e-ç;s-#"],
  ["remake", "r-R;e-i;m-m;a-E;k-k;e-ç"],
  ["remakes", "r-R;e-i;m-m;a-E;k-k;e-ç;s-#"],
  ["retriever", "r-R;e-°;t-t;r-R;i-i;e-°;v-v;e-2;r-R"],
  ["retrievers", "r-R;e-°;t-t;r-R;i-i;e-°;v-v;e-2;r-R;s-#"],
  ["revolver", "r-R;e-e;v-v;o-o;l-l;v-v;e-E;r-R"],
  ["revolverien", "r-R;e-e;v-v;o-o;l-l;v-v;e-E;r-R;i-j;en-5"],
  ["revolverienne", "r-R;e-e;v-v;o-o;l-l;v-v;e-E;r-R;i-j;e-E;nn-n;e-ç"],
  ["revolvers", "r-R;e-e;v-v;o-o;l-l;v-v;e-E;r-R;s-#"],
  ["rient", "r-R;i-i;e-ç;nt-#"],
  ["riesling", "r-R;i-i;e-#;s-s;l-l;i-i;ng-G"],
  ["rieslings", "r-R;i-i;e-#;s-s;l-l;i-i;ng-G;s-#"],
  ["ripieno", "r-R;i-i;p-p;i-j;e-e;n-n;o-o"],
  ["ripienos", "r-R;i-i;p-p;i-j;e-e;n-n;o-o;s-#"],
  ["riviera", "r-R;i-i;v-v;i-i;e-e;r-R;a-a"],
  ["roadster", "r-R;o-o;a-#;d-d;s-s;t-t;e-E;r-R"],
  ["roadsters", "r-R;o-o;a-#;d-d;s-s;t-t;e-E;r-R;s-#"],
  ["roentgenien", "r-R;oe-2;n-n;t-t;g-g;e-°;n-n;i-j;en-5"],
  ["rompt", "r-R;om-§;pt-#"],
  ["romsteck", "r-R;om;s-s;t-t;e-E;ck-k"],
  ["romstecks", "r-R;om;s-s;t-t;e-E;ck-k;s-#"],
  ["rouenne", "r-R;ou-u;e-a;nn-n;e-ç"],
  ["rouennerie", "r-R;ou-u;e-a;nn-n;e-°;r-R;i-i;e-#"],
  ["rouenneries", "r-R;ou-u;e-a;nn-n;e-°;r-R;i-i;e-#;s-#"],
  ["royalties", "r-R;oy-6;a-a;l-l;t-t;i-i;e-#;s-z"],
  ["rubato", "r-R;u-u;b-b;a-a;t-t;o-o"],
  ["rubatos", "r-R;u-u;b-b;a-a;t-t;o-o;s-#"],
  ["rucksack", "r-R;u-u;ck-k;s-s;a-a;ck-k"],
  ["rucksacks", "r-R;u-u;ck-k;s-s;a-a;ck-k;s-#"],
  ["rufien", "r-R;u-y;f-f;i-j;en-5"],
  ["rugbymans", "r-R;u-y;g-g;b-b;y-i;m-m;an;s-#"],
  ["rumba", "r-R;u-u;m-m;b-b;a-a"],
  ["rumbas", "r-R;u-u;m-m;b-b;a-a;s-#"],
  ["runabout", "r-R;u-2;n-n;a-a;b-b;ou-u;t-t"],
  ["runabouts", "r-R;u-2;n-n;a-a;b-b;ou-u;t-t;s-#"],
  ["ruolz", "r-R;u-y;o-o;l-l;z-s"],
  ["rush", "r-R;u-2;sh-S"],
  ["rushs", "r-R;u-2;sh-S;s-#"],
  ["millet", "m-m;ill-%;et-E"],
  ["millets", "m-m;ill-%;e-E;t-#;s-#"],
  ["tachysystolique", "t-t;a-a;ch-k;y-i;s-s;y-i;s-s;t-t;o-o;l-l;i-i;qu-k;e-ç"],
  ["tanker", "t-t;an-@;k-k;e-2;r-R"],
  ["tankers", "t-t;an-@;k-k;e-2;r-R;s-#"],
  ["taon", "t-t;aon-@"],
  ["taonner", "t-t;a-a;o-#;nn-n;er-e"],
  ["taons", "t-t;aon-@;s-#"],
  ["tchernozeme", "t-t;ch-S;e-E;r-R;n-n;o-o;z-z;e-E;m-m;e-ç"],
  ["tchernoziom", "t-t;ch-S;e-E;r-R;n-n;o-o;z-z;i-j;om"],
  ["tchernozioms", "t-t;ch-S;e-E;r-R;n-n;o-o;z-z;i-j;om;s-#"],
  ["technétium", "t-t;e-E;ch-k;n-n;é-e;t-s;i-j;u-o;m-m"],
  ["technétiums", "t-t;e-E;ch-k;n-n;é-e;t-s;i-j;u-o;m-m;s-#"],
  ["tee", "t-t;ee-i"],
  ["tees", "t-t;ee-i;s-#"],
  ["teflon", "t-t;e-e;f-f;l-l;on-§"],
  ["tek", "t-t;e-E;k-k"],
  ["télésiège", "t-t;é-e;l-l;é-e;s-s;i-j;è-E;g-Z;e-ç"],
  ["télésièges", "t-t;é-e;l-l;é-e;s-s;i-j;è-E;g-Z;e-ç;s-#"],
  ["tempo", "t-t;e-E;m-m;p-p;o-o"],
  ["tempos", "t-t;e-E;m-m;p-p;o-o;s-#"],
  ["tequila", "t-t;e-e;qu-k;i-i;l-l;a-a"],
  ["teraphim", "t-t;e-e;r-R;a-a;ph-f;im"],
  ["téraphim", "t-t;é-e;r-R;a-a;ph-f;im"],
  ["tétrasomique", "t-t;é-e;t-t;r-R;a-a;s-z;o-o;m-m;i-i;qu-k;e-ç"],
  ["thalweg", "t-t;h-#;a-a;l-l;w-v;e-E;g-g"],
  ["thalwegs", "t-t;h-#;a-a;l-l;w-v;e-E;g-g;s-#"],
  ["thermoionique", "t-t;h-#;e-E;r-R;m-m;o;i-j;o-o;n-n;i-i;qu-k;e-ç"],
  ["thesaurus", "t-t;h-#;e-e;s-z;au-o;r-R;u-y;s-s"],
  ["thriller", "th-s;r-R;i-i;ll-l;e-2;r-R"],
  ["thrillers", "th-s;r-R;i-i;ll-l;e-2;r-R;s-#"],
  ["thromboembolie", "t-t;h-#;r-R;om-§;b-b;o;em-@;b-b;o-o;l-l;i-i;e-#"],
  ["thyms", "t-t;h-#;ym-5;s-#"],
  ["toechodonte", "t-t;oe-e;ch-S;o-o;d-d;on-§;t-t;e-ç"],
  ["tomahawk", "t-t;o-o;m-m;a-a;h-#;aw-o;k-k"],
  ["tomahawks", "t-t;o-o;m-m;a-a;h-#;aw-o;k-k;s-#"],
  ["tommies", "t-t;o-o;mm-m;i-i;e-#;s-z"],
  ["toreador", "t-t;o-o;r-R;e-e;a-a;d-d;o-o;r-R"],
  ["toreo", "t-t;o-o;r-R;e-e;o-o"],
  ["torero", "t-t;o-o;r-R;e-e;r-R;o-o"],
  ["toreros", "t-t;o-o;r-R;e-e;r-R;o-o;s-#"],
  ["touareg", "t-t;ou-u;a-a;r-R;e-E;g-g"],
  ["tournebride", "t-t;ou-u;r-R;n-n;e-°;b-b;r-R;i-i;d-d;e-ç"],
  ["tournebrides", "t-t;ou-u;r-R;n-n;e-°;b-b;r-R;i-i;d-d;e-ç;s-#"],
  ["tournebroche", "t-t;ou-u;r-R;n-n;e-°;b-b;r-R;o-o;ch-S;e-ç"],
  ["tournebroches", "t-t;ou-u;r-R;n-n;e-°;b-b;r-R;o-o;ch-S;e-ç;s-#"],
  ["transitions", "t-t;r-R;an-@;s-z;i-i;t-s;i-j;on-§;s-#"],
  ["travelling", "t-t;r-R;a-a;v-v;e-#;ll-l;i-i;ng-G"],
  ["travellings", "t-t;r-R;a-a;v-v;e-#;ll-l;i-i;ng-G;s-#"],
  ["trecento", "t-t;r-R;e-e;c-S;e-E;n-n;t-t;o-o"],
  ["triceratops", "t-t;r-R;i-i;c-s;e-e;r-R;a-a;t-t;o-o;p-p;s-s"],
  ["trimmers", "t-t;r-R;i-i;mm-m;e-2;r-R;s-#"],
  ["tripang", "t-t;r-R;i-i;p-p;an-@;g-#"],
  ["tripangs", "t-t;r-R;i-i;p-p;an-@;g-#;s-#"],
  ["triumvir", "t-t;r-R;i-/;u-o;m-m;v-v;i-i;r-R"],
  ["triumviral", "t-t;r-R;i-/;u-o;m-m;v-v;i-i;r-R;a-a;l-l"],
  ["triumvirale", "t-t;r-R;i-/;u-o;m-m;v-v;i-i;r-R;a-a;l-l;e-ç"],
  ["triumvirales", "t-t;r-R;i-/;u-o;m-m;v-v;i-i;r-R;a-a;l-l;e-ç;s-#"],
  ["triumvirat", "t-t;r-R;i-/;u-o;m-m;v-v;i-i;r-R;a-a;t-#"],
  ["triumvirats", "t-t;r-R;i-/;u-o;m-m;v-v;i-i;r-R;a-a;t-#;s-#"],
  ["triumviraux", "t-t;r-R;i-/;u-o;m-m;v-v;i-i;r-R;au-o;x-#"],
  ["triumvirs", "t-t;r-R;i-/;u-o;m-m;v-v;i-i;r-R;s-#"],
  ["tsunami", "t-t;s-s;u-u;n-n;a-a;m-m;i-i"],
  ["tsunamis", "t-t;s-s;u-u;n-n;a-a;m-m;i-i;s-#"],
  ["tub", "t-t;u-2;b-b"],
  ["tubs", "t-t;u-2;b-b;s-#"],
  ["turbeh", "t-t;u-y;r-R;b-b;e-E;h-#"],
  ["turbodiesel", "t-t;u-y;r-R;b-b;o-o;d-d;i-j;e-e;s-z;e-E;l-l"],
  ["turf", "t-t;u-2;r-R;f-f"],
  ["turfiste", "t-t;u-2;r-R;f-f;i-i;s-s;t-t;e-ç"],
  ["turfistes", "t-t;u-2;r-R;f-f;i-i;s-s;t-t;e-ç;s-#"],
  ["turfs", "t-t;u-2;r-R;f-f;s-#"],
  ["turnep", "t-t;u-y;r-R;n-n;e-E;p-p"],
  ["tutti", "t-t;u-u;tt-t;i-i"],
  ["typhlosolis", "t-t;y-i;ph-f;l-l;o-o;s-s;o-o;l-l;i-i;s-s"],
  ["saintpaulia", "s-s;ain-5;t-#;p-p;au-o;l-l;i-j;a-a"],
  ["saintpaulias", "s-s;ain-5;t-#;p-p;au-o;l-l;i-j;a-a;s-#"],
  ["sakieh", "s-s;a-a;k-k;i-j;e-e;h-#"],
  ["saladero", "s-s;a-a;l-l;a-a;d-d;e-e;r-R;o-o"],
  ["saladeros", "s-s;a-a;l-l;a-a;d-d;e-e;r-R;o-o;s-#"],
  ["salep", "s-s;a-a;l-l;e-E;p-p"],
  ["sangsue", "s-s;an-@;g-#;s-s;u-y;e-#"],
  ["sangsues", "s-s;an-@;g-#;s-s;u-y;e-#;s-#"],
  ["saoul", "s-s;a-#;ou-u;l-#"],
  ["saouls", "s-s;a-#;ou-u;l-#;s-#"],
  ["sarracenia", "s-s;a-a;rr-R;a-a;c-s;e-e;n-n;i-j;a-a"],
  ["sarracenias", "s-s;a-a;rr-R;a-a;c-s;e-e;n-n;i-j;a-a;s-#"],
  ["satisfecit", "s-s;a-a;t-t;i-i;s-s;f-f;e-e;c-s;i-i;t-t"],
  ["scandix", "s-s;c-k;an-@;d-d;i-i;x-x"],
  ["scherzando", "s;ch-k;e-E;r-R;z-z;an;d-d;o-o"],
  ["schiedam", "s;ch-k;i-i;e-#;d-d;a-a;m-m"],
  ["schiedams", "s;ch-k;i-i;e-#;d-d;a-a;m-m;s-#"],
  ["schola", "s;ch-k;o-o;l-l;a-a"],
  ["scholas", "s;ch-k;o-o;l-l;a-a;s-#"],
  ["schupo", "sch-S;u-u;p-p;o-o"],
  ["schupos", "sch-S;u-u;p-p;o-o;s-#"],
  ["schuss", "sch-S;u-u;ss-s"],
  ["sclérœdème", "s-s;c-k;l-l;é-e;r-R;œ-e;d-d;è-E;m-m;e-ç"],
  ["sclérosarcome", "s-s;c-k;l-l;é-e;r-R;o-o;s-s;a-a;r-R;c-k;o-o;m-m;e-ç"],
  ["scramasaxe", "s-s;c-k;r-R;a-a;m-m;a-a;s-s;a-a;x-x;e-ç"],
  ["scramasaxes", "s-s;c-k;r-R;a-a;m-m;a-a;s-s;a-a;x-x;e-ç;s-#"],
  ["scrub", "s-s;c-k;r-R;u-2;b-b"],
  ["scrubber", "s-s;c-k;r-R;u-2;bb-b;e-2;r-R"],
  ["scrubbers", "s-s;c-k;r-R;u-2;bb-b;e-2;r-R;s-#"],
  ["scrubs", "s-s;c-k;r-R;u-2;b-b;s-#"],
  ["scull", "s-s;c-k;u-2;ll-l"],
  ["sculls", "s-s;c-k;u-2;ll-l;s-#"],
  ["secundo", "s-s;e-°;c-g;un-§;d-d;o-o"],
  ["séditieuse", "s-s;é-e;d-d;i-i;t-s;i-j;eu-2;s-z;e-ç"],
  ["séditieuses", "s-s;é-e;d-d;i-i;t-s;i-j;eu-2;s-z;e-ç;s-#"],
  ["séditieux", "s-s;é-e;d-d;i-i;t-s;i-j;eu-2;x-#"],
  ["seephirot", "s-s;ee-e;ph-f;i-i;r-R;o-o;t-t"],
  ["séephirot", "s-s;ée-e;ph-f;i-i;r-R;o-o;t-t"],
  ["seephiroth", "s-s;ee-e;ph-f;i-i;r-R;o-o;t-t;h-#"],
  ["séephiroth", "s-s;ée-e;ph-f;i-i;r-R;o-o;t-t;h-#"],
  ["sefiroth", "s-s;e-e;f-f;i-i;r-R;o-o;t-t;h-#"],
  [
    "séléniohyposulfureux",
    "s-s;é-e;l-l;é-e;n-n;i-j;o-o;h-#;y-i;p-p;o-o;s-s;u-y;l-l;f-f;u-y;r-R;eu-2;x-#",
  ],
  ["séléniosulfate", "s-s;é-e;l-l;é-e;n-n;i-j;o-o;s-s;u-y;l-l;f-f;a-a;t-t;e-ç"],
  [
    "séléniosulfurique",
    "s-s;é-e;l-l;é-e;n-n;i-j;o-o;s-s;u-y;l-l;f-f;u-y;r-R;i-i;qu-k;e-ç",
  ],
  [
    "sélénosulfurique",
    "s-s;é-e;l-l;é-e;n-n;o-o;s-s;u-y;l-l;f-f;u-y;r-R;i-i;qu-k;e-ç",
  ],
  ["senior", "s-s;e-e;n-n;i-j;o-o;r-R"],
  ["seniors", "s-s;e-e;n-n;i-j;o-o;r-R;s-#"],
  ["senor", "s-s;e-e;n-N;o-o;r-R"],
  ["señor", "s-s;e-e;ñ-N;o-o;r-R"],
  ["senora", "s-s;e-e;n-N;o-o;r-R;a-a"],
  ["señora", "s-s;e-e;ñ-N;o-o;r-R;a-a"],
  ["senoras", "s-s;e-e;n-N;o-o;r-R;a-a;s-#"],
  ["senorita", "s-s;e-e;n-N;o-o;r-R;i-i;t-t;a-a"],
  ["senoritas", "s-s;e-e;n-N;o-o;r-R;i-i;t-t;a-a;s-#"],
  ["senors", "s-s;e-e;n-N;o-o;r-R;s-#"],
  ["sep", "s-s;e-E;p-p"],
  ["sephirot", "s-s;e-e;ph-f;i-i;r-R;o-o;t-t"],
  ["sephiroth", "s-s;e-e;ph-f;i-i;r-R;o-o;t-t;h-#"],
  ["sephirothique", "s-s;e-e;ph-f;i-i;r-R;o-o;t-t;h-#;i-i;qu-k;e-ç"],
  ["sephirotique", "s-s;e-e;ph-f;i-i;r-R;o-o;t-t;i-i;qu-k;e-ç"],
  ["septième", "s-s;e-E;p-#;t-t;i-j;è-E;m-m;e-ç"],
  ["septièmement", "s-s;e-E;p-#;t-t;i-j;è-E;m-m;e-°;m-m;en-@;t-#"],
  ["septièmes", "s-s;e-E;p-#;t-t;i-j;è-E;m-m;e-ç;s-#"],
  ["séquoia", "s-s;é-e;qu-k;o;i-j;a-a"],
  ["séquoias", "s-s;é-e;qu-k;o;i-j;a-a;s-#"],
  ["sérapeum", "s-s;é-e;r-R;a-a;p-p;e;u-o;m-m"],
  ["sérapeums", "s-s;é-e;r-R;a-a;p-p;e;u-o;m-m;s-#"],
  ["sétim", "s-s;é-e;t-t;im"],
  ["sétims", "s-s;é-e;t-t;im;s-#"],
  ["sfumato", "s-s;f-f;u-u;m-m;a-a;t-t;o-o"],
  ["sfumatos", "s-s;f-f;u-u;m-m;a-a;t-t;o-o;s-#"],
  ["shaker", "sh-S;a-E;k-k;e-2;r-R"],
  ["shakers", "sh-S;a-E;k-k;e-2;r-R;s-#"],
  ["shakespearien", "sh-S;a-E;k-k;e-#;s-s;p-p;ea-i;r-R;i-j;en-5"],
  ["shakespearienne", "sh-S;a-E;k-k;e-#;s-s;p-p;ea-i;r-R;i-j;e-E;nn-n;e-ç"],
  [
    "shakespeariennes",
    "sh-S;a-E;k-k;e-#;s-s;p-p;ea-i;r-R;i-j;e-E;nn-n;e-ç;s-#",
  ],
  ["shakespeariens", "sh-S;a-E;k-k;e-#;s-s;p-p;ea-i;r-R;i-j;en-5;s-#"],
  ["shantung", "sh-S;an-@;t-t;u;ng-G"],
  ["shantungs", "sh-S;an-@;t-t;u;ng-G;s-#"],
  ["shirting", "sh-S;i-2;r-R;t-t;i-i;ng-G"],
  ["shirtings", "sh-S;i-2;r-R;t-t;i-i;ng-G;s-#"],
  ["shogun", "sh-S;o-o;g-g;un"],
  ["shogunal", "sh-S;o-o;g-g;u-u;n-n;a-a;l-l"],
  ["shogunale", "sh-S;o-o;g-g;u-u;n-n;a-a;l-l;e-ç"],
  ["shogunales", "sh-S;o-o;g-g;u-u;n-n;a-a;l-l;e-ç;s-#"],
  ["shogunat", "sh-S;o-o;g-g;u-u;n-n;a-a;t-#"],
  ["shogunats", "sh-S;o-o;g-g;u-u;n-n;a-a;t-#;s-#"],
  ["shogunaux", "sh-S;o-o;g-g;u-u;n-n;au-o;x-#"],
  ["shoguns", "sh-S;o-o;g-g;un;s-#"],
  ["similisymétrie", "s-s;i-i;m-m;i-i;l-l;i-i;s-s;y-i;m-m;é-e;t-t;r-R;i-/;e-#"],
  ["simultaneum", "s-s;i-i;m-m;u-y;l-l;t-t;a-a;n-n;e;u-o;m-m"],
  ["sixain", "s-s;i-i;x-z;ain-5"],
  ["sixains", "s-s;i-i;x-z;ain-5;s-#"],
  ["skating", "s-s;k-k;a-E;t-t;i-i;ng-G"],
  ["skatings", "s-s;k-k;a-E;t-t;i-i;ng-G;s-#"],
  ["skeet", "s-s;k-k;ee-i;t-t"],
  ["skeets", "s-s;k-k;ee-i;t-t;s-#"],
  ["skipper", "s-s;k-k;i-i;pp-p;e-2;r-R"],
  ["skippers", "s-s;k-k;i-i;pp-p;e-2;r-R;s-#"],
  ["skungs", "s-s;k-k;un-§;g-k;s-s"],
  ["skunks", "s-s;k-k;un-§;k-k;s-s"],
  ["slalom", "s-s;l-l;a-a;l-l;om"],
  ["slaloms", "s-s;l-l;a-a;l-l;om;s-#"],
  ["rom", "r-r;om"],
  ["roms", "r-r;om;s-#"],
  ["sleeping", "s-s;l-l;ee-i;p-p;i-i;ng-G"],
  ["sleepings", "s-s;l-l;ee-i;p-p;i-i;ng-G;s-#"],
  ["smashes", "s-s;m-m;a-a;sh-S;e-ç;s-z"],
  ["software", "s-s;o-o;f-f;t-t;w-w;a-E;r-R;e-ç"],
  ["softwares", "s-s;o-o;f-f;t-t;w-w;a-E;r-R;e-ç;s-#"],
  ["soixantaine", "s-s;oi-6;x-s;an-@;t-t;ai-E;n-n;e-ç"],
  ["soixantaines", "s-s;oi-6;x-s;an-@;t-t;ai-E;n-n;e-ç;s-#"],
  ["soixante", "s-s;oi-6;x-s;an-@;t-t;e-ç"],
  ["soixanter", "s-s;oi-6;x-s;an-@;t-t;er-e"],
  ["soixantième", "s-s;oi-6;x-s;an-@;t-t;i-j;è-E;m-m;e-ç"],
  ["soixantièmes", "s-s;oi-6;x-s;an-@;t-t;i-j;è-E;m-m;e-ç;s-#"],
  ["sonosensible", "s-s;o-o;n-n;o-o;s-s;en-@;s-s;i-i;b-b;l-l;e-ç"],
  ["sostenuto", "s-s;o-o;s-s;t-t;e-e;n-n;u-u;t-t;o-o"],
  ["souchong", "s-s;ou-u;ch-S;on-§;g-#"],
  ["souchongs", "s-s;ou-u;ch-S;on-§;g-#;s-#"],
  ["soûl", "s-s;oû-u;l-#"],
  ["soûls", "s-s;oû-u;ls-#"],
  ["sourcil", "s-s;ou-u;r-R;c-s;i-i;l-l"],
  ["sourcils", "s-s;ou-u;r-R;c-s;i-i;l-l;s-#"],
  ["sourient", "s-s;ou-u;r-R;i-j;ent-#"],
  ["sovkhoz", "s-s;o-o;v-f;k-k;h-#;o-o;z-z"],
  ["sovkhoze", "s-s;o-o;v-f;k-k;h-#;o-o;z-z;e-ç"],
  ["sovkhozes", "s-s;o-o;v-f;k-k;h-#;o-o;z-z;e-ç;s-#"],
  ["sovkhozien", "s-s;o-o;v-f;k-k;h-#;o-o;z-z;i-j;en-5"],
  ["sovkhozienne", "s-s;o-o;v-f;k-k;h-#;o-o;z-z;i-j;e-E;nn-n;e-ç"],
  ["sovkhozs", "s-s;o-o;v-f;k-k;h-#;o-o;z-z;s-#"],
  ["speaker", "s-s;p-p;ea-i;k-k;e-2;r-R"],
  ["speakers", "s-s;p-p;ea-i;k-k;e-2;r-R;s-#"],
  ["speech", "s-s;p-p;ee-i;ch-S"],
  ["speechs", "s-s;p-p;ee-i;ch-S;s-#"],
  ["speiss", "s-s;p-p;e-a;i-j;ss-s"],
  ["spencer", "s-s;p-p;e-E;n-n;c-s;e-E;r-R"],
  ["spencers", "s-s;p-p;e-E;n-n;c-s;e-E;r-R;s-#"],
  ["spermaceti", "s-s;p-p;e-E;r-R;m-m;a-a;c-s;e-e;t-t;i-i"],
  ["spermacetis", "s-s;p-p;e-E;r-R;m-m;a-a;c-s;e-e;t-t;i-i;s-#"],
  ["spiegel", "s-s;p-p;i-i;e-#;g-g;e-2;l-l"],
  ["spiegels", "s-s;p-p;i-i;e-#;g-g;e-2;l-l;s-#"],
  ["spleen", "s-s;p-p;l-l;ee-i;n-n"],
  ["spleenétique", "s-s;p-p;l-l;ee-i;n-n;é-e;t-t;i-i;qu-k;e-ç"],
  ["spleenétiques", "s-s;p-p;l-l;ee-i;n-n;é-e;t-t;i-i;qu-k;e-ç;s-#"],
  ["spleenique", "s-s;p-p;l-l;ee-i;n-n;i-i;qu-k;e-ç"],
  ["spleenitique", "s-s;p-p;l-l;ee-i;n-n;i-i;t-t;i-i;qu-k;e-ç"],
  ["spleens", "s-s;p-p;l-l;ee-i;n-n;s-#"],
  ["spleenuosité", "s-s;p-p;l-l;ee-i;n-n;u-y;o-o;s-z;i-i;t-t;é-e"],
  ["sponsor", "s-s;p-p;on;s-s;o-o;r-R"],
  ["sponsoring", "s-s;p-p;on;s-s;o-o;r-R;i-i;ng-G"],
  ["sponsorings", "s-s;p-p;on;s-s;o-o;r-R;i-i;ng-G;s-#"],
  ["sponsors", "s-s;p-p;on;s-s;o-o;r-R;s-#"],
  ["sportsmans", "s-s;p-p;o-o;r-R;t-t;s-s;m-m;an;s-#"],
  ["sportswear", "s-s;p-p;o-o;r-R;t-t;s-s;w-w;ea-E;r-R"],
  ["sportwear", "s-s;p-p;o-o;r-R;t-t;w-w;ea-E;r-R"],
  ["springbok", "s-s;p-p;r-R;i-i;ng-G;b-b;o-o;k-k"],
  ["springboks", "s-s;p-p;r-R;i-i;ng-G;b-b;o-o;k-k;s-#"],
  ["squatters", "s-s;q-k;u-w;a-a;tt-t;e-2;r-R;s-#"],
  ["squaw", "s-s;q-k;u-w;aw-o"],
  ["squaws", "s-s;q-k;u-w;aw-o;s-#"],
  ["statolimnimètre", "s-s;t-t;a-a;t-t;o-o;l-l;im;n-n;i-i;m-m;è-E;t-t;r-R;e-ç"],
  ["stawug", "s-s;t-t;a-a;w-v;u-y;g-g"],
  ["stawugs", "s-s;t-t;a-a;w-v;u-y;g-g;s-#"],
  ["steak", "s-s;t-t;ea-E;k-k"],
  ["steaks", "s-s;t-t;ea-E;k-k;s-#"],
  ["steamboat", "s-s;t-t;ea-i;m-m;b-b;o-o;a-w;t-t"],
  ["steamer", "s-s;t-t;ea-i;m-m;e-E;r-R"],
  ["steamers", "s-s;t-t;ea-i;m-m;e-E;r-R;s-#"],
  ["steenbock", "s-s;t-t;ee-i;n-n;b-b;o-o;ck-k"],
  ["steeple", "s-s;t-t;ee-i;p-p;l-l;e-ç"],
  ["steeples", "s-s;t-t;ee-i;p-p;l-l;e-ç;s-#"],
  ["stegosaurus", "s-s;t-t;e-e;g-g;o-o;s-z;au-o;r-R;u-y;s-s"],
  ["steinbock", "s-s;t-t;ei-E;n-n;b-b;o-o;ck-k"],
  ["steinbocks", "s-s;t-t;ei-E;n-n;b-b;o-o;ck-k;s-#"],
  ["steinbok", "s-s;t-t;ei-E;n-n;b-b;o-o;k-k"],
  ["stencil", "s-s;t-t;e-E;n-n;c-s;i-i;l-l"],
  ["stenciliste", "s-s;t-t;e-E;n-n;c-s;i-i;l-l;i-i;s-s;t-t;e-ç"],
  ["stencilistes", "s-s;t-t;e-E;n-n;c-s;i-i;l-l;i-i;s-s;t-t;e-ç;s-#"],
  ["stencils", "s-s;t-t;e-E;n-n;c-s;i-i;l-l;s-#"],
  ["stomodaeum", "s-s;t-t;o-o;m-m;o-o;d-d;ae-E;u-o;m"],
  ["stomodeum", "s-s;t-t;o-o;m-m;o-o;d-d;e-E;u-o;m-m"],
  ["stomodœum", "s-s;t-t;o-o;m-m;o-o;d-d;œ-E;u-o;m-m"],
  ["stout", "s-s;t-t;o-a;u-w;t-t"],
  ["stouts", "s-s;t-t;o-a;u-w;t-t;s-#"],
  ["succincte", "s-s;u-y;c-k;c-s;in-5;c-#;t-t;e-ç"],
  ["succinctement", "s-s;u-y;c-k;c-s;in-5;c-#;t-t;e-°;m-m;en-@;t-#"],
  ["succinctes", "s-s;u-y;c-k;c-s;in-5;c-#;t-t;e-ç;s-#"],
  ["suggère", "s-s;u-y;g-g;g-Z;è-E;r-R;e-ç"],
  ["suggèrent", "s-s;u-y;g-g;g-Z;è-E;r-R;e-ç;nt-#"],
  ["suggères", "s-s;u-y;g-g;g-Z;è-E;r-R;e-ç;s-#"],
  [
    "sulfosalicylique",
    "s-s;u-y;l-l;f-f;o-o;s-s;a-a;l-l;i-i;c-s;y-i;l-l;i-i;qu-k;e-ç",
  ],
  ["summum", "s-s;u-o;mm-m;u-o;m-m"],
  ["summums", "s-s;u-o;mm-m;u-o;m-m;s-#"],
  [
    "supercoquentieuse",
    "s-s;u-y;p-p;e-E;r-R;c-k;o-o;qu-k;en-@;t-s;i-j;eu-2;s-z;e-ç",
  ],
  [
    "supercoquentieuses",
    "s-s;u-y;p-p;e-E;r-R;c-k;o-o;qu-k;en-@;t-s;i-j;eu-2;s-z;e-ç;s-#",
  ],
  [
    "supercoquentieux",
    "s-s;u-y;p-p;e-E;r-R;c-k;o-o;qu-k;en-@;t-s;i-j;eu-2;x-#",
  ],
  ["superstitieuse", "s-s;u-y;p-p;e-E;r-R;s-s;t-t;i-i;t-s;i-j;eu-2;s-z;e-ç"],
  [
    "superstitieusement",
    "s-s;u-y;p-p;e-E;r-R;s-s;t-t;i-i;t-s;i-j;eu-2;s-z;e-°;m-m;en-@;t-#",
  ],
  [
    "superstitieuses",
    "s-s;u-y;p-p;e-E;r-R;s-s;t-t;i-i;t-s;i-j;eu-2;s-z;e-ç;s-#",
  ],
  ["superstitieux", "s-s;u-y;p-p;e-E;r-R;s-s;t-t;i-i;t-s;i-j;eu-2;x-#"],
  ["suprématie", "s-s;u-y;p-p;r-R;é-e;m-m;a-a;t-s;i-i;e-#"],
  ["suprématies", "s-s;u-y;p-p;r-R;é-e;m-m;a-a;t-s;i-i;e-#;s-#"],
  ["surexhaussement", "s-s;u-y;r-R;e-E;x-X;h-#;au-o;ss-s;e-°;m-m;en-@;t-#"],
  ["surf", "s-s;u-2;r-R;f-f"],
  ["surfer", "s-s;u-2;r-R;f-f;er-e"],
  ["surfing", "s-s;u-2;r-R;f-f;i-i;ng-G"],
  ["surfs", "s-s;u-2;r-R;f-f;s-#"],
  ["suspense", "s-s;u-y;s-s;p-p;e-E;n-n;s-s;e-ç"],
  ["suspenses", "s-s;u-y;s-s;p-p;e-E;n-n;s-s;e-ç;s-#"],
  ["swastika", "s-s;w-v;a-a;s-s;t-t;i-i;k-k;a-a"],
  ["swastikas", "s-s;w-v;a-a;s-s;t-t;i-i;k-k;a-a;s-#"],
  ["sweater", "s-s;w-w;ea-E;t-t;e-2;r-R"],
  ["sweaters", "s-s;w-w;ea-E;t-t;e-2;r-R;s-#"],
  ["sweepstake", "s-s;w-w;ee-i;p-p;s-s;t-t;a-E;k-k;e-ç"],
  ["sweepstakes", "s-s;w-w;ee-i;p-p;s-s;t-t;a-E;k-k;e-ç;s-#"],
  [
    "synaposématique",
    "s-s;y-i;n-n;a-a;p-p;o-o;s-s;é-e;m-m;a-a;t-t;i-i;qu-k;e-ç",
  ],
  [
    "synaposématisme",
    "s-s;y-i;n-n;a-a;p-p;o-o;s-s;é-e;m-m;a-a;t-t;i-i;s-s;m-m;e-ç",
  ],
  ["synnemmenon", "s-s;y-i;nn-n;e-E;mm-m;e-°;n-n;on-§"],
  ["synnemménon", "s-s;y-i;nn-n;e-E;mm-m;é-e;n-n;on-§"],
  ["synœkie", "s-s;y-i;n-n;œ-e;k-k;i-i;e-#"],
  ["synœque", "s-s;y-i;n-n;œ-e;qu-k;e-ç"],
  ["syssitie", "s-s;y-i;ss-s;i-i;t-s;i-i;e-#"],
  ["metz", "m-m;e-E;t-#;z-s"],
  ["acupunctrice", "a-a;c-k;u-y;p-p;un-§;c-k;t-t;r-R;i-i;c-s;e-ç"],
  ["zeus", "z-z;eu-2;s-s"],
  ["arès", "a-a;r-R;è-E;s-s"],
  ["artémis", "a-a;r-R;t-t;é-e;m-m;i-i;s-s"],
  ["cronos", "c-k;r-R;o-o;n-n;o-o;s-s"],
  ["hadès", "h-#;a-a;d-d;è-E;s-s"],
  ["déméter", "d-d;é-e;m-m;é-e;t-t;e-E;r-R"],
  ["dionysos", "d-d;i-j;o-o;n-n;y-i;s-z;o-o;s-s"],
  ["héphaïstos", "h-#;é-e;ph-f;a-a;ï-i;s-s;t-t;o-o;s-s"],
  ["ouranos", "ou-u;r-R;a-a;n-n;o-o;s-s"],
  ["héraclès", "h-#;é-e;r-R;a-a;c-k;l-l;è-E;s-s"],
  ["midas", "m-m;i-i;d-d;a-a;s-s"],
  ["francis", "f-f;r-R;an-@;c-s;i-i;s-s"],
  ["pétiole", "p-p;é-e;t-s;i-j;o-o;l-l;e-ç"],
  ["pétioles", "p-p;é-e;t-s;i-j;o-o;l-l;e-ç;s-#"],
  ["pétiolé", "p-p;é-e;t-s;i-j;o-o;l-l;é-e"],
  ["pétiolés", "p-p;é-e;t-s;i-j;o-o;l-l;é-e;s-#"],
  ["pétiolée", "p-p;é-e;t-s;i-j;o-o;l-l;é-e;e-#"],
  ["pétiolées", "p-p;é-e;t-s;i-j;o-o;l-l;é-e;e-#;s-#"],
]);
