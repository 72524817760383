import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SettingsState {
  maxResults: number;
  helpLevel: 0 | 1 | 2;
  selectedVoice?: string;
  voices?: string[];
}

const initialState: SettingsState = {
  maxResults: 10,
  helpLevel: 2,
  selectedVoice: undefined,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    changeMaxResults: (state, action: PayloadAction<number>) => {
      state.maxResults =
        action.payload <= 10 ? (action.payload > 0 ? action.payload : 1) : 10;
    },
    setHelpLevel: (
      state,
      action: PayloadAction<typeof initialState.helpLevel>
    ) => {
      state.helpLevel = action.payload;
    },
    selectVoice: (state, action: PayloadAction<string>) => {
      state.selectedVoice = action.payload;
    },
    initVoices: (state, action: PayloadAction<string[]>) => {
      state.voices = action.payload;
    },
  },
});

export const { changeMaxResults, setHelpLevel, selectVoice, initVoices } =
  settingsSlice.actions;

export default settingsSlice.reducer;
