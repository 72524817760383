import { DocumentData } from "firebase/firestore";
import { WordExportType, WordType } from "../types";

export const mapFromDocumentData = (documentData: DocumentData): WordType => {
  return {
    frequency: documentData.frequency,
    word: documentData.word,
    genre: documentData.genre,
    article: documentData.article,
    nature:
      typeof documentData.nature === "string"
        ? documentData.nature.split(",")
        : documentData.nature,
    nombre: documentData.nombre,
    lemme: documentData.lemme,
    phonetic: documentData.phonetic,
    simple: documentData.simple,
    definitions: documentData.definitions,
    synonyms: documentData.synonyms,
    images: documentData.images,
  };
};


export const mapFromDocumentDataToExportWord = (documentData: DocumentData): WordExportType => {
  let definitions = documentData.definitions;
  if (!definitions) {
    definitions = [];
  }
  if (definitions.length === 0) {
    definitions.push("");
  }
  if (definitions.length === 1) {
    definitions.push("");
  }
  if (definitions.length === 2) {
    definitions.push("");
  }
  return {
    frequency: documentData.frequency,
    word: documentData.word,
    genre: documentData.genre?.toLowerCase(),
    article: documentData.article,
    nombre: documentData.nombre?.toLowerCase(),
    lemme: documentData.lemme,
    phonetic: documentData.phonetic,
    simple: documentData.simple,
    nature: typeof documentData.nature === "string" ? documentData.nature.split(",") : documentData.nature,
    definition1: definitions[0].replace(/"/g, "&quot;"),
    definition2: definitions[1].replace(/"/g, "&quot;"),
    definition3: definitions[2].replace(/"/g, "&quot;"),
    synonyms: typeof documentData.synonyms === "string" ? documentData.synonyms.split(",") : documentData.synonyms,
    images: typeof documentData.images === "string" ? documentData.images.split(",") : documentData.images,
  };
};
