import { WordType } from "../types";
import { wordsCollection } from "../../../lib/firebase/firestore";
import { mapFromDocumentData } from "../mapper";
import { getDocs, query, where, limit } from "firebase/firestore";
import { getNextString } from "../utils";
import { SettingsState } from "../../settings/settingsSlice";
import { logResult, logSearch } from "../../analytics";
import PhonWord from "../../../ColorizationPart/Core/PhonWord";

export const searchWords = async (
  phonWord: PhonWord,
  settings: SettingsState
): Promise<WordType[]> => {
  const word = phonWord.GetWord();
  let words: WordType[] = [];
  const searchType =
    settings.helpLevel === 0
      ? "word"
      : settings.helpLevel === 1
      ? "phonetic"
      : "simple";

  let phonetic: string = "";
  if (searchType !== "word") {
    phonetic = phonWord.ToPhonString(searchType);
    let qE;
    if (phonetic.endsWith("ç")) {
      // search without ending 'e' (ç sound)
      qE = query(
        wordsCollection,
        where(searchType, "==", phonetic.substring(0, phonetic.length - 1)),
        limit(settings.maxResults)
      );
    } else {
      // search with adding end 'e' (ç sound)
      qE = query(
        wordsCollection,
        where(searchType, "==", phonetic + "ç"),
        limit(settings.maxResults)
      );
    }
    const querySnapshotE = await getDocs(qE);
    querySnapshotE.forEach((doc) =>
      words.push(mapFromDocumentData(doc.data()))
    );

    console.log("Search with type: " + searchType);
    console.log(
      "Search words for word = " + word + " and phonetic = " + phonetic
    );

    logSearch(word);
    const endSearch = getNextString(phonetic);
    const q = query(
      wordsCollection,
      where(searchType, ">=", phonetic),
      where(searchType, "<", endSearch),
      limit(Math.max(settings.maxResults - words.length, 1))
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      if (doc.exists()) {
        words.push(mapFromDocumentData(doc.data()));
      }
    });

    words.sort((w1, w2) =>
      w1.frequency > w2.frequency ? -1 : w1.frequency < w2.frequency ? 1 : 0
    );
  }

  let remainingResults = settings.maxResults - words.length;
  // this ensure we at least get the exact word if it exists
  if (remainingResults <= 0) {
    remainingResults = 1;
  }
  const qWord = query(
    wordsCollection,
    where("word", ">=", word),
    where("word", "<", getNextString(word)),
    limit(remainingResults)
  );
  const querySnapshotWord = await getDocs(qWord);
  querySnapshotWord.forEach((doc) => {
    if (doc.exists()) {
      words.push(mapFromDocumentData(doc.data()));
    }
  });

  // remove duplicates
  words = words.filter(
    (v, i, a) => a.findIndex((v2) => v2.word === v.word) === i
  );

  console.log(words);

  // if we have exact word, put it first
  let exactWord: WordType | null = null;
  for (const w of words) {
    if (w.word === word) {
      exactWord = w;
      break;
    }
  }

  // Move words with phonetic match to the top
  let phoneticMatch: WordType[] = [];
  if (searchType !== "word") {
    phoneticMatch = words.filter((w) => w[searchType] === phonetic && w.word !== word);
  }

  words = [
    ...phoneticMatch,
    ...words.filter((w) => w.word !== word && w[searchType] !== phonetic),
  ];
  if (exactWord !== null) {
    words = [exactWord, ...words];
  }

  // limit the number of results
  words = words.slice(0, settings.maxResults);

  // add statistics of returned words
  words.forEach((w) => {
    logResult(w.word);
  });
  return words;
};
